import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { helperTextColor } from '../constants';

const useStyles = makeStyles(() => ({
  detailSection: {
    marginTop: '2rem',
    fontWeight: 'bold',
  },
  helperText: {
    color: helperTextColor,
    fontSize: '12px',
    paddingLeft: '14px',
    paddingTop: '4px',
  },
}));

export function Title({ children }) {
  const classes = useStyles();

  return (
    <Typography className={ classes.detailSection } component="h1" align="center" variant="body1">
      { children }
    </Typography>
  )
}

Title.propTypes = {
  children: PropTypes.string,
};

export function HelperText({ children }) {
  const classes = useStyles();

  return (
    <Typography className={ classes.helperText }>
      { children }
    </Typography>
  )
}

export function InstructionTitle({ children, part }) {
  const classes = useStyles();

  return (
    <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
      Part { part }: { children }
    </Typography>
  )
}

InstructionTitle.propTypes = {
  children: PropTypes.string,
  part: PropTypes.number,
};