import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function ExitButton({ history, path, confirmation, ...props }) {
  if (!confirmation) {
    confirmation = callback => callback();
  }
  return (
    <Grid { ...props } item xs={ 1 }>
      <IconButton aria-label="close" onClick={ () => confirmation(path ? () => history.push(path) : history.goBack) }>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
}

ExitButton.propTypes = {
  confirmation: PropTypes.func,
  history: PropTypes.object,
};