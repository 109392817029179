import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import VerifiedUserSharp from '@material-ui/icons/VerifiedUserSharp';
import Check from '@material-ui/icons/Check';
import FilledCircle from '@material-ui/icons/Brightness1';
import Info from '@material-ui/icons/InfoOutlined';
import DownArrow from '@material-ui/icons/ArrowDropDownOutlined';
import SvgIcon from '@material-ui/core/SvgIcon';
import ReactToolTip from 'react-tooltip';
import SingleChallengeReflections from './SingleChallengeReflections';
import { progressBackgroundColor, tooltipIconColor } from '../../../../constants';
import { toolTipContent } from '../../../../services/helpers';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../../utilities/localisation/translationKeys';


const NotAttemptedGoal = (props) => (
  <SvgIcon { ...props }>
    <path
      d="M12.1578947,1 L3.03947368,5 L3.03947368,11 C3.03947368,16.55 6.93,21.74 12.1578947,23 C17.3857895,21.74 21.2763158,16.55 21.2763158,11 L21.2763158,5 L12.1578947,1 L12.1578947,1 Z"
      id="Shape" fill="#929292" />
  </SvgIcon>
);

const InProgressGoal = (props) => (
  <SvgIcon { ...props }>
    <path
      d="M12.1558442,1 L3.03896104,5 L3.03896104,11 C3.03896104,16.55 6.92883117,21.74 12.1558442,23 C17.3828571,21.74 21.2727273,16.55 21.2727273,11 L21.2727273,5 L12.1558442,1 L12.1558442,1 Z"
      id="Shape" fill="none" stroke="#368800" strokeWidth="3px" />
  </SvgIcon>
);

const findActiveStep = (stepArray) => {
  for (let i = 1; i < stepArray.length; i++) {
    if (!stepArray[i].complete && stepArray[i].attempted && stepArray[i - 1].complete) return i;
  }
  if (stepArray[0] && stepArray[0].attempted) return 0;
  return undefined;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={ value !== index }
      id={ `vertical-tabpanel-${ index }` }
      aria-labelledby={ `vertical-tab-${ index }` }
      { ...other }
    >
      <Box p={ 1 } paddingLeft='12px'>{ children }</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${ index }`,
    'aria-controls': `vertical-tabpanel-${ index }`,
  };
}

const TabComponent = React.forwardRef((props, ref) => {
  let IconComponent = <NotAttemptedGoal htmlColor='grey' />;
  if (props.status === 'complete') {
    IconComponent = <VerifiedUserSharp htmlColor='#589F1C' />;
  } else if (props.status === 'started') {
    IconComponent = <InProgressGoal />;
  }
  const {
    inGame,
    outOfGame,
    goalName,
    ...restProps
  } = props;
  return (
    <div ref={ ref } { ...restProps } style={ { display: 'flex', height: '48px' } }>
      <div style={ { display: 'flex', margin: 'auto' } }>
        { IconComponent }<Typography component='span' style={ {
        paddingRight: '4px',
        paddingLeft: '4px',
        textAlign: 'center',
      } }>{ props.goalName }</Typography>
      </div>
    </div>
  );
});

const ChallengeIcon = (props) => {
  const { complete, attempted, selected } = props;
  const Icon = complete ? Check : FilledCircle;
  const borderColor = selected ? '#333' : complete || attempted ? '#666' : '#bbb';
  const backgroundColor = complete ? '#589F1C' : '#ddd';
  const htmlColor = complete ? 'white' : '#ddd';
  return (
    <div style={ {
      marginTop: '-2px',
      borderRadius: '50%',
      border: `3px solid ${ borderColor }`,
      backgroundColor: backgroundColor,
      position: 'relative',
    } }>
      { Icon ?
        <Icon htmlColor={ htmlColor } style={ { width: '1em', height: '0.8em', paddingTop: '0.1em' } } /> : null }
      { selected &&
      <div style={ { position: 'absolute', left: 0, bottom: -18 } }><DownArrow htmlColor={ borderColor } /></div> }
    </div>
  );
};

const connectorStyles = makeStyles(() => ({
  lineHorizontal: {
    borderTopWidth: '3px',
    borderColor: '#666',
  },
  root: {
    flex: '1 1 auto',
  },
  /* Styles applied to the root element if `alternativeLabel={true}`. */
  /* Nasty default styles copied from the StepConnector Material UI component. */
  alternativeLabel: {
    position: 'absolute',
    top: 8 + 4,
    left: 'calc(-50% + 0.8em)',
    right: 'calc(50% + 0.5em)',
  },
  line: {
    display: 'block',
  },
}));

const ChallengeConnector = (props) => {
  const classes = connectorStyles();
  return (
    <StepConnector classes={ classes } { ...props } />
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 500,
  },
  centre: {
    margin: 'auto',
  },
  tabPanel: {
    backgroundColor: progressBackgroundColor,
    width: '80%',
    marginBottom: -10,
  },
  stepper: {
    backgroundColor: progressBackgroundColor,
    padding: '0px',
  },
}));

const GameProgress = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedChallenge, setSelectedChallenge] = React.useState({});
  const stepSorting = (a, b) => (a['step'] - b['step']) * 10 + b['attempted'] - a['attempted'];
  const { t } = useTranslation();

  useEffect(() => {
    if (props.progress) {
      const progress = props.progress[value];

      const inGame = (progress && progress.inGame.sort(stepSorting));
      const outOfGame = (progress && progress.outOfGame.sort(stepSorting));
      const inGameActive = findActiveStep(inGame);
      const outOfGameActive = findActiveStep(outOfGame);
      if (inGameActive !== undefined) {
        setSelectedChallenge({ ...inGame[inGameActive], type: 'i', index: inGameActive });
      } else if (outOfGameActive !== undefined) {
        setSelectedChallenge({ ...outOfGame[outOfGameActive], type: 'o', index: outOfGameActive });
      } else {
        setSelectedChallenge({});
      }
    }
  }, [value, props.progress]);

  if (!props.progress) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const validXS = function (size) {
    return Math.max(1, Math.min(size, 12));
  };

  return (
    <div className={ classes.root }>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={ value }
        onChange={ handleChange }
        scrollButtons="on"
        aria-label="Vertical tabs"
        TabIndicatorProps={ { style: { width: '100%', backgroundColor: '#000', opacity: 0.035294117647058822 } } }
      >
        { props.progress.map((goal, index) => <Tab component={ TabComponent }
                                                   goalName={ `Goal ${ index + 1 }` } { ...goal } { ...a11yProps(index) }
                                                   key={ index } />) }
      </Tabs>
      { props.progress.map((goal, index) => {
          const inGame = goal.inGame.sort(stepSorting);
          const outOfGame = goal.outOfGame.sort(stepSorting);
          const inGameXS = validXS(inGame.length);
          const outOfGameXS = validXS(outOfGame.length);
          const rightPad = 10 - (inGameXS + outOfGameXS);
          const rightPadXS = rightPad > 0 ? rightPad : undefined;
          return (
            <TabPanel value={ value } index={ index } className={ classes.tabPanel } key={ index }>
              <Grid container>
                <Grid item xs={ 11 }>{ goal.title }</Grid>
                <Grid item xs={ 1 }>
                  <div
                    data-tip={t(translationKey.LabelGameProgressInfo)}
                    data-for='game-progress'
                    style={ { textAlign: 'right' } }>
                    <Info htmlColor={ tooltipIconColor } />
                    <ReactToolTip
                      id='game-progress'
                      getContent={ toolTipContent }
                      type='light'
                      multiline
                    />
                  </div>
                </Grid>
                <Grid item xs={ 2 } />
                <Grid item xs={ inGameXS } style={ { borderRight: '1px solid #ccc' } }>
                  <Typography color='textSecondary' align='center'>{t(translationKey.LabelInGame)}</Typography>
                </Grid>
                <Grid item xs={ outOfGameXS }>
                  <Typography color='textSecondary' align='center'>{t(translationKey.LabelOutOfGame)}</Typography>
                </Grid>
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 2 } />
                <Grid item xs={ inGameXS } style={ { marginTop: '12px' } }>
                  <Stepper nonLinear alternativeLabel className={ classes.stepper } connector={ <ChallengeConnector /> }>
                    { inGame.map((challenge, index) => (
                      <Step key={ challenge.name }>
                        <StepButton
                          completed={ challenge.complete }
                          onClick={ () => setSelectedChallenge({ ...challenge, type: 'i', index }) }
                          icon={ <ChallengeIcon { ...challenge }
                                                selected={ selectedChallenge.type === 'i' && selectedChallenge.index === index } /> } />
                      </Step>
                    )) }
                  </Stepper>
                </Grid>
                <Grid item xs={ outOfGameXS } style={ { marginTop: '12px' } }>
                  <Stepper nonLinear alternativeLabel className={ classes.stepper } connector={ <ChallengeConnector /> }>
                    { outOfGame.map((challenge, index) => (
                      <Step key={ challenge.name }>
                        <StepButton
                          completed={ challenge.complete }
                          onClick={ () => setSelectedChallenge({ ...challenge, type: 'o', index }) }
                          icon={ <ChallengeIcon { ...challenge }
                                                selected={ selectedChallenge.type === 'o' && selectedChallenge.index === index } /> } />
                      </Step>
                    )) }
                  </Stepper>
                </Grid>
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 2 } />
                <Grid item xs={ 10 }>{ selectedChallenge.name }</Grid>
                <Grid item xs={ 2 }>
                  <Typography color='textSecondary' align='center'>{t(translationKey.LabelAttempted)}</Typography>
                </Grid>
                { inGame.map((challenge, i) => {
                  const fontWeight = (selectedChallenge.type === 'i' && selectedChallenge.index === i) ? 600 : undefined;
                  return (
                    <Grid item xs={ 1 } style={ { textAlign: 'center', fontWeight } }
                          key={ i }>{ challenge.attempted ? challenge.attempted : null }</Grid>
                  );
                }) }
                { outOfGame.map((challenge, i) => {
                  const fontWeight = (selectedChallenge.type === 'o' && selectedChallenge.index === i) ? 600 : undefined;
                  return (
                    <Grid item xs={ 1 } style={ { textAlign: 'center', fontWeight } }
                          key={ i }>{ challenge.attempted ? challenge.attempted : null }</Grid>
                  );
                }) }
                { inGame.length === 0 && <Grid item xs={ 1 } /> }
                { outOfGame.length === 0 && <Grid item xs={ 1 } /> }
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 2 } />
                <Grid item xs={ validXS(inGameXS + outOfGameXS) }>
                  <hr />
                </Grid>
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 2 }>
                  <Typography color='textSecondary' align='center'>{t(translationKey.LabelCompleted)}</Typography>
                </Grid>
                { inGame.map((challenge, i) => {
                  const fontWeight = (selectedChallenge.type === 'i' && selectedChallenge.index === i) ? 600 : undefined;
                  return (
                    <Grid item xs={ 1 } style={ { textAlign: 'center', fontWeight } }
                          key={ i }>{ challenge.repeated ? challenge.repeated : null }</Grid>
                  );
                }) }
                { outOfGame.map((challenge, i) => {
                  const fontWeight = (selectedChallenge.type === 'o' && selectedChallenge.index === i) ? 600 : undefined;
                  return (
                    <Grid item xs={ 1 } style={ { textAlign: 'center', fontWeight } }
                          key={ i }>{ challenge.repeated ? challenge.repeated : null }</Grid>
                  );
                }) }
                { inGame.length === 0 && <Grid item xs={ 1 } /> }
                { outOfGame.length === 0 && <Grid item xs={ 1 } /> }
                { rightPadXS && <Grid item xs={ rightPadXS } /> }
                <Grid item xs={ 12 }>
                  <SingleChallengeReflections reflections={ selectedChallenge.reflections } />
                </Grid>
              </Grid>
            </TabPanel>
          );
        },
      ) }
    </div>
  );
};

export default GameProgress;
