import React, { useState } from 'react';
import { IconButton, Link, Tab, Tabs } from '@material-ui/core';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../themes';
import { Delete, RestoreFromTrash } from '@material-ui/icons';
import ChevronRight from '@material-ui/core/SvgIcon/SvgIcon';
import * as PropTypes from 'prop-types';
import { activeColor, deactivatedColor, invitedColor, listBackgroundColor } from '../../Styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

export default function DistributorList({ distributors, invitees, deleteDistributor, deleteInvitee, restoreDistributor, clickDistributor }) {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  const deletedDistributors = distributors.filter(x => x.deleted_at !== null);
  const liveDistributors = distributors.filter(x => x.deleted_at === null);

  return (
    <div>
      <Tabs
        value={ tab }
        onChange={ (event, value) => setTab(value) }>
        <Tab label={t(translationKey.TabActive)} value={ 0 } />
        <Tab label={t(translationKey.TabInvited)} value={ 1 } />
        <Tab label={t(translationKey.TabDeactivated)} value={ 2 } />
      </Tabs>
      <ThemeProvider theme={ tableThemer(((i) => [liveDistributors, invitees, deletedDistributors][i])(tab)) }>
        { tab === 0 &&
        <MaterialTable
          isLoading={ !distributors }
          columns={ [
            {
              title: '#',
              field: 'id',
              cellStyle: {
                borderLeft: `20px solid ${ activeColor }`,
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.LabelDistributorName),
              field: 'name',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.LabelDistributorEmail),
              field: 'email',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleCreatedAt),
              field: 'created_at',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          actions={ [
            {
              icon: () => <Delete />,
              onClick: (event, rowData) => deleteDistributor(rowData),
            },
          ] }
          components={ {
            Action: props => <IconButton color='inherit' onClick={ (event) => {
              props.action.onClick(event, props.data);
              event.stopPropagation();
            }
            }>{ props.action.icon() }</IconButton>,
          } }
          data={ liveDistributors }
          onRowClick={ clickDistributor }
          options={ {
            actionsColumnIndex: 4,
            detailPanelColumnAlignment: 'right',
            detailPanelType: 'single',
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
        />
        }
        { tab === 1 &&
        <MaterialTable
          isLoading={ !invitees }
          columns={ [
            {
              title: '#',
              field: 'id',
              cellStyle: {
                borderLeft: `20px solid ${ invitedColor }`,
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleInviteeName),
              cellStyle: {
                borderRight: 'none',
              },
              render: item => `${ item.first_name } ${ item.last_name }`,
            },
            {
              title: t(translationKey.TitleInviteeEmail),
              field: 'email',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleInviteeLink),
              render: item =>
                <Link href={ item.unique_link }>
                  { item.unique_link }
                </Link>,
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleInvitedAt),
              field: 'created_at',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          data={ invitees }
          options={ {
            actionsColumnIndex: 5,
            detailPanelColumnAlignment: 'right',
            detailPanelType: 'single',
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
          actions={ [
            {
              icon: () => <Delete />,
              onClick: (event, rowData) => deleteInvitee(rowData),
            },
          ] }
        />
        }
        { tab === 2 &&
        <MaterialTable
          isLoading={ !distributors }
          columns={ [
            {
              title: '#',
              field: 'id',
              cellStyle: {
                borderLeft: `20px solid ${ deactivatedColor }`,
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.LabelDistributorName),
              field: 'name',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.LabelDistributorEmail),
              field: 'email',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleDeactivatedOn),
              field: 'deleted_at',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          actions={ [
            {
              icon: () => <RestoreFromTrash />,
              onClick: (event, rowData) => restoreDistributor(rowData),
            },
          ] }
          data={ deletedDistributors }
          options={ {
            actionsColumnIndex: 5,
            detailPanelColumnAlignment: 'right',
            detailPanelType: 'single',
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
        />
        }
      </ThemeProvider>
    </div>);
}

DistributorList.propTypes = {
  distributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  invitees: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteDistributor: PropTypes.func.isRequired,
  deleteInvitee: PropTypes.func.isRequired,
  clickDistributor: PropTypes.func.isRequired,
  restoreDistributor: PropTypes.func,
};
