import React from 'react';
import { axios, catchAxios } from '../../services/networkRequest';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { genericAlert, getChain } from '../../services/helpers';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { helperTextColor } from '../../constants';
import Card from '@material-ui/core/Card';
import ExitButton from '../ExitButton';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useViewport } from '../../utilities/viewport';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
    marginTop: '16px',
  },
  selectText: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
  detailSection: {
    marginTop: '45px',
    fontWeight: 'normal',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  bottomBorder: {
    borderBottom: '1px solid #ccc',
  },
  topBorder: {
    borderTop: '1px solid #ccc',
    marginTop: '75px',
    paddingTop: '20px',
  },
  disabilitySpace: {
    marginTop: '10px',
  },
  card: {
    minWidth: 275,
  },
  helperText: {
    color: helperTextColor,
    fontSize: '12px',
    paddingLeft: '14px',
    paddingTop: '4px',
  },
  image: {
    height: '5rem',
    objectFit: 'cover',
    borderRadius: '4px',
    marginLeft: '0.5rem',
    padding: '0.5rem',
  },
}));

function OrganisationSettings({ history }) {
  const organisation = history.location.state.organisation;
  const { t } = useTranslation();

  const confirmation = callback => {
    genericAlert(t(translationKey.AlertTitleCancelOrganisationForm), t(translationKey.AlertBodyCancelOrganisationForm), callback);
  };

  return (
    <Card>
      <ExitButton style={ { float: 'right', padding: '20px' } } history={ history } confirmation={ confirmation } />
      <Container component="main" maxWidth="xs" style={ { padding: '20px' } }>
        <CssBaseline />
        <Typography component="h1" variant="h5">
          {t(translationKey.LabelOrganisationSettings,
            { organisation_name: organisation.name })}
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ {
            odsCode: getChain(organisation, 'ods_code'),
            orgIdComm: getChain(organisation, 'org_id_comm'),
            includeForSubmission: getChain(organisation, 'include_for_submission_mhsds') ?? false,
            enableDownload: getChain(organisation, 'enable_mhsds_download') ?? false,
          } }
          onSubmit={ (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            const submitFunction = () => {
              axios['put'](`/organisation/${organisation.id}/settings`, values)
                .then(() => {
                  history.replace('/');
                })
                .catch(err => {
                  catchAxios(setFieldError)(err);
                  setSubmitting(false);
                });
            };
            submitFunction();
          } }
        >
          { ({
               errors,
               values,
               handleChange,
               handleBlur,
               handleSubmit,
             }) => (
            <form onSubmit={ handleSubmit }>
              <Error message={ errors['network'] } />

              <CheckboxFieldWithTitle title={ t(translationKey.LabelOrganisationEnableMHSDSForAdminDownload) }
                                      formLabelValue="includeForSubmission"
                                      name="includeForSubmission"
                                      value={ values.includeForSubmission }
                                      checked={ values.includeForSubmission }
                                      onChange={ handleChange }
              />

              <CheckboxFieldWithTitle title={ t(translationKey.LabelOrganisationEnableMHSDS) }
                                      formLabelValue="enableDownload"
                                      name="enableDownload"
                                      value={ values.enableDownload }
                                      checked={ values.enableDownload }
                                      onChange={ handleChange }
              />

              <TextField
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus
                label={ t(translationKey.LabelOrganisationODSCode) }
                value={ values.odsCode }
                name="odsCode"
                onChange={ handleChange }
                onBlur={ handleBlur }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <TextField
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                value={ values.orgIdComm }
                label={ t(translationKey.LabelOrganisationOrgIdComm) }
                name="orgIdComm"
                onChange={ handleChange }
                onBlur={ handleBlur }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary">{t(translationKey.ButtonSubmit)}</Button>
            </form>) }
        </Formik>
      </Container>
    </Card>
  );
}

function CheckboxFieldWithTitle({ leftColumnWidth, rightColumnWidth, title, formLabelValue, ...props }) {
  const classes = useStyles();

  return (
    <Row>
      <Grid item xs={ leftColumnWidth }>
        <Typography className={ classes.label } component="h2" align="left" variant="body1">
          { title }
        </Typography>
      </Grid>
      <Grid item xs={ rightColumnWidth }>
        <FormControlLabel
          className={ classes.formControl }
          value={ formLabelValue }
          control={
            <Checkbox
              color="primary"
              inputProps={ { 'aria-label': 'secondary checkbox' } }
              { ...props }
            />
          }
          label="" />
      </Grid>
    </Row>
  );
}

function Row({ children }) {
  const { views: { mobile } } = useViewport();

  return (
    <Grid container direction={ mobile ? 'column' : 'row' }
          justify="space-between" alignItems={ mobile ? '' : 'center' } style={ { margin: '0.5rem 0' } }>
      { children }
    </Grid>
  )
}

export default OrganisationSettings;
