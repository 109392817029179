import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Collapse,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Footer from '../content/Footer';
import { Trans, useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 275,
    marginBottom: '16px',
  },
  title: {
    padding: '20px',
    paddingTop: '50px',
  },
  heading: {
    marginBottom: '12px',
  },
  listItem: {
    width: '100%',
  },
}));

function QuestionItem(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Card className={ classes.card }>
      <CardContent>
        <Grid container direction='column' alignItems='flex-start' justify='center'>
          <Grid item xs={ 12 } className={ classes.listItem }>
            <ListItem button onClick={ handleClick } className={ classes.listItem }>
              <ListItemText
                primary={ props.question }
                primaryTypographyProps={ { style: { fontWeight: 800 } } }
              />
              { open ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>
            <Collapse in={ open } timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button>
                  <ListItemText primary={ typeof props.answer === 'function' ? props.answer() : props.answer } />
                </ListItem>

              </List>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}


export default function FAQs() {
  const classes = useStyles();
  const { t } = useTranslation();
  const components = { ul: <ul />, li: <li />, br: <br />, span: <span />, lnk: <Link />, }
  const sections = [
    {
      title: t(translationKey.TitleFaqTraining),
      faqs: [
        {
          question: t(translationKey.FaqQuestionMissedTraining),
          answer: () => (
            <div>
                <Trans i18nKey={translationKey.FaqAnswerMissedTrainingLn} components={components} />
            </div>
          ),
        },
        {
          question: t(translationKey.FaqQuestionReviewTraining),
          answer: () => <span> <Trans i18nKey={translationKey.FaqAnswerReviewTrainingLn} components={components} /> </span>,
        },
      ],
    },
    {
      title: t(translationKey.TitleFaqChoosingGamePlayers),
      faqs: [
        {
          question: t(translationKey.FaqQuestionHowDoIChooseGamePlayers),
          answer: () => (
            <div>
              <Trans i18nKey={translationKey.FaqAnswerHowDoIChooseGamePlayersLn} components={components} />
            </div>
          ),
        },
        {
          question: t(translationKey.FaqQuestionSignsOfAnxiety),
          answer: () => (
            <div>
              <Trans i18nKey={translationKey.FaqAnswerSignsOfAnxiety} components={components} />
            </div>
          ),
        },
        {
          question: t(translationKey.FaqQuestionWhatIsAnxiety),
          answer: () => (
            <div>
              <Trans i18nKey={translationKey.FaqAnswerWhatIsAnxiety} components={components} />
            </div>
          ),
        },

      ],
    },
    {
      title: t(translationKey.TitleFaqSupportingGamePlayersAndGuardians),
      faqs: [
        {
          question: t(translationKey.FaqQuestionHowToIntroduceAGuardian),
          answer: () => (
            <div>
              <Trans i18nKey={translationKey.FaqAnswerHowToIntroduceAGuardian} components={components} />
            </div>
          ),
        },
        {
          question: t(translationKey.FaqQuestionIntroduceGameToAYoungPerson),
          answer: () => (
            <div>
              <Trans i18nKey={translationKey.FaqAnswerIntroduceGameToAYoungPersonLn} components={components} />
            </div>
          ),
        },
        {
          question: t(translationKey.FaqQuestionSendInformationToGuardians),
          answer: () => (
            <div>
              <Trans i18nKey={translationKey.FaqAnswerSendInformationToGuardians} components={components} />
            </div>
          ),
        },
        {
          question: t(translationKey.FaqQuestionOngoingSupport),
          answer: () => (
            <div>
              <Trans i18nKey={translationKey.FaqAnswerOngoingSupport} components={components} />
            </div>
          ),
        },
      ],
    },
    {
      title: t(translationKey.TitleFaqGameMaintenance),
      faqs: [
        {
          question: t(translationKey.FaqQuestionRunOutOfLicenses),
          answer: t(translationKey.FaqAnswerRunOutOfLicenses),
        },
        {
          question: t(translationKey.FaqQuestionIfGuardianForgetsGameKey),
          answer: t(translationKey.FaqAnswerIfGuardianForgetsGameKey),
        },
        {
          question: t(translationKey.FaqQuestionWhenCanISeeResults),
          answer: t(translationKey.FaqAnswerWhenCanISeeResults),
        },
      ],
    },
    {
      title: t(translationKey.TitleFaqCannotFindWhatYouAreLookingFor),
      faqs: [
        {
          question: t(translationKey.FaqQuestionContact),
          answer: t(translationKey.FaqAnswerContact),
        },
      ],
    },
  ];

  return (
    <Container component="main" maxWidth="md">
      <Card className={ classes.card }>
        <CardContent>
          <Grid container direction='column' alignItems='flex-start' justify='center'>
            <Grid item xs={ 12 } className={ classes.heading }>
              <Typography variant="h4" align="left">
                {t(translationKey.TitleFaq)}
              </Typography>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography component="h1" align="left" variant="body1">
                {t(translationKey.LabelFaqFindEverythingYouNeedLn)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      { sections.map((title, index) => (
        <Grid key={ index } container direction="column">
          <Typography className={ classes.title } align='left' key={ index } color='textSecondary'>
            { title.title }
          </Typography>
          { title.faqs.map((question, index) => (
              <QuestionItem key={ index } question={ question.question } answer={ question.answer } />
            ),
          ) }

        </Grid>
      )) }
      <Footer />
    </Container>
  );
}
