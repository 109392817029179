import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Wc from '@material-ui/icons/Wc';
import Event from '@material-ui/icons/Event';
import ContactPhone from '@material-ui/icons/ContactPhone';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';


const contactFontColor = '#4C4C71';
const iconColor = '#C2C3C4';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 120,
  },
}));

const ContactDetails = (props) => {
  const classes = useStyles();
  const dateString = new Date(props.dob).toLocaleDateString();
  const { t } = useTranslation();
  return (
    <div className={ classes.root }>
      <Grid container spacing={ 1 } style={ { marginTop: '8px' } }>
        <Grid item xs={ 4 } style={ { borderRight: '1px solid #aaa' } }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 3 }><Event htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{ `${ props.first_name } D.O.B.` }</Typography>
              <Typography style={ { color: contactFontColor } }>{ dateString }</Typography>
            </Grid>
            <Grid item xs={ 3 }><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{t(translationKey.LabelServiceUserReference)}</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.service_user_reference }</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={ 4 } style={ { borderRight: '1px solid #aaa' } }>
          <Grid container spacing={ 1 }>
          <Grid item xs={ 3 }><Wc htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{t(translationKey.LabelGender)}</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.gender }</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={ 4 }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 3 }><ContactPhone htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{t(translationKey.LabelMobile)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ props.telephone || t(translationKey.ErrorMissingPhoneNumber) }</Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
};

export default ContactDetails;
