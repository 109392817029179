import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { axios } from './networkRequest';
import { challengeReflectionsColorScale } from '../Styles';
import { FormControl, Grid, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';

export function getChain() {
  let object = arguments[0];
  for (let i = 1; object && i < arguments.length; i++) {
    object = object[arguments[i]];
  }
  return object;
}

export const ROLES = {
  admin: 'admin',
  distributor: 'distributor',
  point_person: 'point_person',
};

export const GAMESHORTNAMES = {
  lumiNova: 'ln',
  championsOfTheShengha: 'cots'
}

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const identity = val => val;

function pad(number) {
  return String(number).padStart(2, '0');
}

export function formatDate(date) {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const internalDate = date || new Date();
  return `${ internalDate.getFullYear() }-${ pad(internalDate.getMonth() + 1) }-${ pad(internalDate.getDate()) }`;
}

export function printDateString(date) {
  if (date) {
    const parsed = new Date(date);
    return `${ pad(parsed.getDate()) }/${ pad(parsed.getMonth() + 1) }/${ parsed.getFullYear() }`;
  }
}

export function deleteAlert(name, path, callback, message) {
  genericAlert({
    title: `Confirm to delete ${ name }`,
    message: message || 'Are you sure you want to do this?',
    positiveTitle: 'Delete',
    positiveCallback: () => axios.delete(path).then(callback),
    negativeTitle: 'Cancel',
  });
}

export function deleteDistributorAlert(id, name, path, callback, playing, notStarted, inactive, message) {
  genericAlert({
    title: `Confirm to delete ${ name }`,
    message: message || 'Are you sure you want to do this?',
    positiveTitle: 'Delete',
    positiveCallback: () => axios.delete(path)
      .then(callback)
      .catch((err) => {
        if(err.response.status === 409) {
          reassignYoungPeople(
            id,
            name,
            path,
            callback,
            playing,
            notStarted,
            inactive,
            err.response.data
          )
        }
      }),
    negativeTitle: 'Cancel',
  });
}

function handleCloseAlert() {
  const target = document.getElementById('react-confirm-alert');
  target.parentNode.removeChild(target);
  document.body.classList.remove('react-confirm-alert-body-element');
}

export function reassignYoungPeople(id, name, path, callback, playing, notStarted, inactive, data) {
  const otherDistributors = data['distributors'];
  let currentDist = otherDistributors[0];
  confirmAlert({
    customUI:() => {
      return (
        <Grid container justify="center" id="react-confirm-alert">
          <Grid item xs={8} className="react-confirm-alert-body-element">
            <div style={{border: "1px solid black", paddingTop: 20, paddingBottom: 20, paddingLeft: 15, paddingRight: 15, backgroundColor: "white"}}>
              <p style={{fontWeight: "bold", fontSize: "20px"}}>Confirm Delete Distributor: {name}</p>

                 { data['is_self_signup_dist'] && <p>You must choose a new self sign up distributor before deleting this distributor account.</p> }

                 { data['has_young_people'] &&
                   <div>
                    <p>You must reassign all young people before deleting this distributor account:</p>
                     <div>
                     <text style={ { fontWeight: "bold" } }>Playing the game: </text><text>{ playing }</text>
                     </div>
                     <div>
                     <text style={ { fontWeight: "bold" } }>Not yet started: </text><text>{ notStarted }</text>
                     </div>
                     <div>
                     <text style={ { fontWeight: "bold" } }>Inactive: </text><text>{ inactive }</text>
                     </div>
                     <p>Reassign young people to:</p>
                   </div>
                 }

                <FormControl>
                  <Select
                    defaultValue={otherDistributors[0]}
                    onChange={e => {currentDist = e.target.value}}
                  >
                    {
                      otherDistributors.map(function (d) {
                        return <MenuItem key={ d.id } value={ d }>{ d.name }</MenuItem>
                        },
                      )
                    }
                  </Select>
                </FormControl>

              <div className="react-confirm-alert-button-group">
                <button onClick={() => axios.delete(`${ path }/${ currentDist.id }`).then(callback).then(handleCloseAlert).then(function () {
                  let confirmationMessage;

                  if(data['is_self_signup_dist'] && data['has_young_people']) {
                    confirmationMessage = "Young people and self signup distributor have been reassigned to: "
                  } else if(data['has_young_people']) {
                    confirmationMessage = "Young people have been reassigned to: "
                  } else if(data['is_self_signup_dist']) {
                    confirmationMessage = "Self signup distributor has been updated to: "
                  }

                  reassignYoungPeopleConfirmation(currentDist, confirmationMessage);
                })}>
                  Delete
                </button>

                <button onClick={() => handleCloseAlert()}>
                  Cancel
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }
  })
}

function reassignYoungPeopleConfirmation(d, confirmationMessage) {
  confirmAlert({
    customUI:() => {
      return (
        <Grid container justify="center" id="react-confirm-alert">
          <Grid item xs={8} className="react-confirm-alert-body-element">
            <div style={{border: "1px solid black", paddingTop: 20, paddingBottom: 20, paddingLeft: 15, paddingRight: 15, backgroundColor: "white"}}>
              <p style={{fontWeight: "bold", fontSize: "20px"}}>Distributor Deleted.</p>

              {confirmationMessage
                ? <p>{confirmationMessage}</p>
                : <p>All young people have been reassigned to:</p>
              }

              <p>{d.name}</p>
              <p>{d.email}</p>

              <div className="react-confirm-alert-button-group">
                <button onClick={() => handleCloseAlert()}>
                  Close
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }
  })
}

export function genericAlert(title, message, callback) {
  let ttl = title;
  let msg = message;
  let posClbk = callback;
  let posTtl = 'Yes';
  let negTtl = 'No';
  let negClbk;
  let childrenElement;
  if (typeof ttl === 'object') {
    const { title, message, positiveTitle, positiveCallback, negativeTitle, negativeCallback, component } = ttl;
    negClbk = negativeCallback;
    negTtl = negativeTitle;
    posTtl = positiveTitle;
    posClbk = positiveCallback;
    childrenElement = component;
    msg = message;
    ttl = title;
  } else {
    ttl = title;
  }

  const buttons = [];
  if (posClbk) {
    buttons.push({
      label: posTtl,
      onClick: posClbk,
    });
  }
  if (negClbk || negTtl) {
    buttons.push({
      label: negTtl,
      onClick: negClbk,
    });
  }
  if (buttons.length === 0) {
    buttons.push({ label: 'Ok' });
  }

  confirmAlert({
    title: ttl,
    message: msg,
    buttons,
    childrenElement,
  });
}

export function restoreAlert(name, path, callback) {
  confirmAlert({
    title: `Confirm to restore ${ name }`,
    message: 'Are you sure to do this?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          axios.post(path)
            .then(callback);
        },
      },
      {
        label: 'No',
        onClick: () => {
        },
      },
    ],
  });
}

export const subtractMonth = (date) => {
  const thisMonth = date.getMonth();
  // set the month index of the date by subtracting nofMonths from the current month index
  date.setMonth(thisMonth - 1);
  // When trying to add or subtract months from a Javascript Date() Object which is any end date of a month,
  // JS automatically advances your Date object to next month's first date if the resulting date does not exist in its month.
  //if the result of subtraction is non negative, subtract a month manually to check.
  if ((thisMonth - 1 >= 0) && (date.getMonth() !== thisMonth - 1)) {
    date.setDate(0);
  }
  return date;
};

export const toolTipContent = (dataTip) => {
  switch (dataTip) {
    case 'reflections info':
    case 'game progress info':
      return (
        <div style={ { textAlign: 'left' } }>
          <h3>Young people playing the game will be asked to<br /> reflect before and after each exposure challenge</h3>
          <p><i>Before challenge: select one response</i></p>
          <div style={ { marginBottom: '6px' } }>
            <div style={ {
              backgroundColor: challengeReflectionsColorScale[0],
              width: '8px',
              height: '8px',
              marginTop: '10px',
              marginRight: '6px',
              display: 'inline-block',
            } } />
            <span><b>How worried do you feel about the challenge you're about to take on?</b></span>
          </div>
          <img src='/ReflectionsScale.png' alt='worry scale' width='380' style={ { marginTop: '8px' } } />
          <br />
          <p><i>During challenge: select one response for each</i></p>
          <div style={ { marginBottom: '6px' } }>
            <div style={ {
              backgroundColor: challengeReflectionsColorScale[1],
              width: '8px',
              height: '8px',
              marginTop: '10px',
              marginRight: '6px',
              display: 'inline-block',
            } } />
            <span><b>How worried did you feel during the challenge?</b></span>
          </div>
          <img src='/ReflectionsScale.png' alt='worry scale' width='380' style={ { marginTop: '8px' } } />
          <div style={ { marginBottom: '6px', marginTop: '14px' } }>
            <div style={ {
              backgroundColor: challengeReflectionsColorScale[2],
              width: '8px',
              height: '8px',
              marginTop: '10px',
              marginRight: '6px',
              display: 'inline-block',
            } } />
            <span><b>How worried would you feel if you had to do it again?</b></span>
          </div>
          <img src='/ReflectionsScale.png' alt='worry scale' width='380' style={ { marginTop: '8px' } } />
        </div>
      );
    default:
      return 'This is the default tooltip content, you should not see this.';
  }
};

export function tickSteps(userValues = [1], startTick= 0, round = val => Math.round(val)) {
  const maxValue = round(Math.max(...userValues));
  let tickValues = [startTick];
  let step;

  if (maxValue >= 600) {
    step = 100;
  } else if (maxValue > 400) {
    step = 50;
  } else if (maxValue > 200) {
    step = 25;
  } else if (maxValue > 120) {
    step = 20;
  } else if (maxValue > 40) {
    step = 10;
  } else if (maxValue > 10) {
    step = 5;
  } else if (maxValue >= 5) {
    step = 2;
  } else if (maxValue > 1) {
    step = 1;
  } else {
    step = 0.05;
  } 

  const maxAxis = round((Math.floor(maxValue/step) + 1) * step);
  for (let i = startTick; i <= maxValue; i += step) {
    tickValues.push(round(i));
  }
  tickValues.push(maxAxis);
  tickValues.sort(function(a, b){return a-b});
  return tickValues;
}

export function redirectFactory(props, pickGame) {
  return (game) => {
    pickGame(game.shortName)
    props.history.push('/');
  };
}

export function disabilityParser(bool) {
  switch (bool) {
    case true:
      return 'true';
    case false:
      return 'false';
    case null:
      return 'n/a';
    case undefined:
    default:
      return '';
  }
}

export function disabilityUnparser(string) {
  switch(string) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'n/a':
    default:
      return null;
  }
}

export function genderParser(string) {
    return string;

}

export function genderUnparser(string) {
   
    return string;

}