import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../../themes';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ChevronRight from '@material-ui/icons/ChevronRight';
import MaterialTable from 'material-table';
import { axios, CancelToken } from '../../../services/networkRequest';
import GameProgress from '../LN/YoungPersonComponents/GameProgress';
import ContactDetails from './ContactDetails';
import Warning from '@material-ui/icons/Warning';
import Edit from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import style, {
  activeColor,
  dropdownTextColor,
  greyBorderColor,
  listBackgroundColor,
  notActiveColor,
  numberTextColor,
  tableItemBorderColor,
} from '../../../Styles';
import { genericAlert, getChain, identity } from '../../../services/helpers';
import * as PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import GameAsset from '@material-ui/icons/VideogameAssetOutlined';
import Heart from '@material-ui/icons/FavoriteBorderOutlined'
import Timer from '@material-ui/icons/Timer';
import Footer from '../../content/Footer';
import CotsYoungPersonPerformanceChart from '../../CotsYoungPersonPerformanceChart';
import UsageGraph from '../UsageGraph';
import { TypographyAverageTime, TypographyHoursAndMinutes } from '../../Typography/TypographyNumberSpan';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginBottom: '4px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  table: {
    marginTop: '20px',
  },
  pos: {
    marginBottom: 12,
  },
  center: {
    display: 'block',
    margin: '0 auto',
  },
  left: {
    display: 'inline-block',
    textAlign: 'left',
    float: 'left',
    marginTop: '8px',
  },
  button: {
    display: 'inline-block',
    textAlign: 'right',
    float: 'right',
    marginBottom: '16px',
    '&:hover': {
      backgroundColor: '#0079C6',
    },
    '&:active': {
      backgroundColor: 'white',
      borderColor: 'white',
      color: '#0079C6',
    },
  },
  block: {
    display: 'block',
    width: '100%',
  },
  tableItem: {
    border: `1px solid ${ tableItemBorderColor }`,
    marginTop: '10px',
  },
  greenBorder: {
    borderLeft: `10px solid ${ activeColor }`,
  },
  paddedItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  text: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingTop: '50px',
  },
  textSpace: {
    paddingTop: '20px',
  },
  buttonSpace: {
    paddingTop: '40px',
  },
  deactivateButton: {
    marginLeft: 'auto',
    color: '#3B3B3B',
    textDecoration: 'underline',
  },
  youngPersonActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  select: {
    color: dropdownTextColor,
    border: `1px solid ${ dropdownTextColor }`,
    fontSize: '14px',
    padding: '2px 10px',
    minWidth: '160px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: `1px solid ${ dropdownTextColor }`,
      outlineStyle: 'none'
    }
  }
});

const order = new Proxy({
  'complete': 1,
  'started': 2,
  'incomplete': 3,
}, {
  get: (target, key) => target[key] || 4,
});

export const setter = (list, updater) => (rowData) => {
  const index = list.findIndex(yp => yp.id === rowData.id);
  if (index > -1) {
    // shallow copy is need for react to know we changed the state or a re-render doesn't trigger
    const newList = [...list];
    newList[index] = rowData;
    updater(newList);
  }
};

export default function YoungPeoplePage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [youngPersons, setYoungPersons] = useState(undefined);
  const [notStartedYoungPersons, setNotStartedYoungPersons] = useState(undefined);
  const [optedOutYoungPersons, setOptedOutYoungPersons] = useState(undefined);
  const [gameKeys, setGameKeys] = useState({ alert: false, totalKeys: 0, usedKeys: 0 });
  const [allPeople, setAllPeople] = useState(undefined);
  const [dataState, setDataState] = useState(false);

  const reloadData = () => {
    setDataState(!dataState);
    setAllPeople(undefined);
    setYoungPersons(undefined);
    setNotStartedYoungPersons(undefined);
    setOptedOutYoungPersons(undefined);
  };

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });

    axios.get('/cots/young_persons', {
      cancelToken,
    }).then(({ data }) => {
      setAllPeople(data.young_persons.active.length + data.young_persons.not_yet_active.length + data.young_persons.inactive.length);
      setYoungPersons(data.young_persons.active);
      setNotStartedYoungPersons(data.young_persons.not_yet_active);
      setOptedOutYoungPersons(data.young_persons.inactive);
      setGameKeys({ alert: data.alert, totalKeys: data.total_keys, usedKeys: data.used_keys });
    }).catch(identity);

    return () => {
      cancel();
    };
  }, [dataState]);

  const renderZeroState = () => {
    return (
      <Container component="main" maxWidth="lg">
        <Card className={ classes.card }>
          <CardContent>
            <Grid container className={ classes.text } direction='column' alignItems='center' justify='center'>
              <Grid item xs={ 6 }>
                <Typography component="h5" align="center" variant="h5" style={ { fontWeight: 800 } }>
                  {t(translationKey.LabelInviteYoungPerson)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={ 2 } justify='center' className={ classes.buttonSpace }>
              <div>
                <Button variant="contained" color="primary"
                        className={ classes.button }
                        onClick={ () => {
                          props.history.push('/cots/young_person/create');
                        } }>
                  {t(translationKey.ButtonInviteYoungPerson)}
                </Button>
              </div>
            </Grid>
            <div style={ { margin: '25px -50px', borderTop: `${ numberTextColor } solid 4px` } } />
            <CardMedia image="/GettingStartedCots.jpg" title="" style={ { height: 700 } } />
          </CardContent>
        </Card>
        <Footer />
      </Container>
    );
  };

  const RenderDetailFactory = (borderColour, setter) => (rowData) => <RenderDetails
    setYoungPersonData={ setter }
    borderColour={ borderColour }
    reloadData={ reloadData }
    rowData={ rowData } { ...props } />;

  const renderList = props => {
    return (
      <Container component="main" maxWidth="lg" style={ { marginTop: '10px' } }>
        <Card className={ classes.card }>
          <CardContent>
            <div className={ classes.block }>
              <Typography className={ classes.left } color="textSecondary">
                <strong>{t(translationKey.TitleLicensesUsed)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{ gameKeys.alert ?
                <Warning htmlColor='black' style={ {
                  height: '0.7em',
                  marginBottom: '-0.12em',
                } } /> : null }{t(translationKey.LabelLicensesUsed,{ used: gameKeys.usedKeys, total: gameKeys.totalKeys})}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={ classes.button }
                onClick={ () => props.history.push('/cots/young_person/create') }>
                {t(translationKey.ButtonInviteYoungPerson)}
              </Button>
            </div>
          </CardContent>
        </Card>
        <ThemeProvider theme={ tableThemer(youngPersons) }>
          <MaterialTable
            isLoading={ !youngPersons }
            columns={ [
              {
                title: t(translationKey.TitlePlayingTheGame),
                field: 'first_name',
                cellStyle: {
                  borderLeft: `20px solid ${ activeColor }`,
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
              },
              {
                title: t(translationKey.TitleInvited),
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
              },
              {
                title: t(translationKey.TitleStartedGame),
                field: 'first_play',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
              },
              {
                title: t(translationKey.TitleSessionsPlayed),
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ getChain(rowData, 'session_count') || 0 }</Typography>,
              },
              {
                title: t(translationKey.TitleGameKey),
                field: 'game_key',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
              },
            ] }
            data={ youngPersons }
            detailPanel={ RenderDetailFactory(activeColor, setter(youngPersons, setYoungPersons)) }
            onRowClick={ (event, rowData, togglePanel) => togglePanel() }
            options={ {
              detailPanelColumnAlignment: 'right',
              detailPanelType: 'single',
              paging: false,
              search: false,
              sorting: false,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            } }
            icons={ { DetailPanel: ChevronRight } }
            style={ { backgroundColor: listBackgroundColor } }
          />
        </ThemeProvider>
        <ThemeProvider theme={ tableThemer(notStartedYoungPersons) }>
          <MaterialTable
            isLoading={ !notStartedYoungPersons }
            columns={ [
              {
                title: t(translationKey.TitleNotYetStarted),
                field: 'first_name',
                cellStyle: {
                  borderLeft: `20px solid ${ notActiveColor }`,
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
              },
              {
                title: t(translationKey.TitleInvited),
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
              },
              {
                title: t(translationKey.TitleStartedGame),
                field: 'first_play',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
              },
              {
                title: t(translationKey.TitleGameKey),
                field: 'game_key',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
              },
            ] }
            data={ notStartedYoungPersons }
            detailPanel={ RenderDetailFactory(notActiveColor, setter(notStartedYoungPersons, setNotStartedYoungPersons)) }
            onRowClick={ (event, rowData, togglePanel) => togglePanel() }
            options={ {
              detailPanelColumnAlignment: 'right',
              detailPanelType: 'single',
              paging: false,
              search: false,
              sorting: false,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            } }
            icons={ { DetailPanel: ChevronRight } }
            style={ { backgroundColor: listBackgroundColor } }
          />
        </ThemeProvider>
        <ThemeProvider theme={ tableThemer(optedOutYoungPersons) }>
          <MaterialTable
            isLoading={ !optedOutYoungPersons }
            columns={ [
              {
                title: t(translationKey.TitleOptedOut),
                field: 'first_name',
                cellStyle: {
                  borderLeft: `20px solid ${ greyBorderColor }`,
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
              },
              {
                title: t(translationKey.TitleInvited),
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
              },
              {
                title: t(translationKey.TitleStartedGame),
                field: 'first_play',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
              },
              {
                title: t(translationKey.TitleGameKey),
                field: 'game_key',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
              },
            ] }
            data={ optedOutYoungPersons }
            detailPanel={ RenderDetailFactory(greyBorderColor, setter(optedOutYoungPersons, setOptedOutYoungPersons)) }
            onRowClick={ (event, rowData, togglePanel) => togglePanel() }
            options={ {
              detailPanelColumnAlignment: 'right',
              detailPanelType: 'single',
              paging: false,
              search: false,
              sorting: false,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            } }
            icons={ { DetailPanel: ChevronRight } }
            style={ { backgroundColor: listBackgroundColor } }
          />
        </ThemeProvider>
        <Footer />
      </Container>
    );
  };

  return (
    <>
      { allPeople === 0 ? renderZeroState() : renderList(props) }
    </>
  );
}

const sorting = progress => {
  return progress && progress.sort((a, b) => order[a.status] - order[b.status]);
};

export function RenderDetails(props) {
  const {
    rowData,
    setYoungPersonData,
    reloadData,
    borderColour,
  } = props;
  const currentDate = new Date();
  const classes = useStyles();
  const [userData, setUserData] = useState(rowData);
  const graph_data = getChain(userData, 'performance_data');
  const first_graph_data = getChain(graph_data, 'first_graph');
  const recent_graph_data = getChain(graph_data, 'recent_graph');
  const { t } = useTranslation();

  useEffect(() => {
    if (!getChain(userData, 'stats')) {
      axios.get(`/cots/young_persons/${ userData.id }`)
        .then(({ data }) => {
          userData.stats = data;
          setUserData(userData);
          setYoungPersonData(userData);
        })
        .catch(error => {
          genericAlert(t(translationKey.TitleError), getChain(error, 'response', 'data', 'error'));
        });
    }

    let url = `/cots/young_persons/${ userData.id }/chart`;

    if (!graph_data) {
      axios.get(url)
        .then(({ data }) => {
          userData['performance_data'] = data;

          setUserData(userData);
          setYoungPersonData(userData);
        });
    }

  }, [userData, setYoungPersonData, graph_data, t]);

  const { tableData, ...restUserData } = userData;
  const player = userData.first_name;
  const progress = sorting(getChain(userData, 'stats', 'progress'));
  const gameTime = getChain(userData, 'stats', 'game_time');
  const lastUsedDateString = gameTime && gameTime.lastLoggedIn && new Date(gameTime.lastLoggedIn).toLocaleDateString();
  const averageTime = gameTime ? gameTime.timeAverage : '';
  const totalTime = getChain(gameTime, 'timeSpent');
  const totalDuels = getChain(gameTime, 'totalDuels') || 0;
  const totalHours = gameTime ? Math.floor(totalTime / 60) : '';
  const totalMinutes = gameTime ? Math.round(totalTime - (totalHours * 60)) : '';
  const improving = getChain(gameTime, 'improving');
  let startDate = new Date(userData.first_play);
  if (startDate.getDate() >= currentDate.getDate()) {
    startDate.setDate(currentDate.getDate() - 1);
  }

  return (
    <div style={ {
      backgroundColor: listBackgroundColor,
      position: 'relative',
      width: 'calc(100% + 1px)',
      borderLeft: `20px solid ${ borderColour }`,
      borderRight: `1px solid ${ greyBorderColor }`,
      boxSizing: 'border-box',
      overflowY: 'auto',
      overflowX: 'hidden',
      marginTop: '-18px',
    } }
         key={ userData.id }
    >
      <Grid container style={ { width: 'inherit', margin: 0, paddingLeft: '30px', paddingTop: '30px' } }
            spacing={ 3 }>
        { userData.first_play && <>
          <Grid item xs={ 12 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid item xs={ 6 }><Typography color="textPrimary">{t(translationKey.TitlePlayerGameTime, { player })}</Typography></Grid>
                  <Grid item xs={ 6 }><Typography color="textSecondary"
                                                  align="right">{(t(translationKey.TitleLastUsedDate, { lastUsedDateString : lastUsedDateString|| '' }))}</Typography></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={ 3 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelTotalDuels)}</Typography>
                <Grid container>
                  <Grid item xs={ 10 }><Typography
                    style={ { fontSize: '30px', color: numberTextColor } }>{ totalDuels }</Typography></Grid>
                  <Grid item xs={ 2 }><GameAsset style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={ 3 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelAverageSessionTime)}</Typography>
                <Grid container>
                  <Grid item xs={ 10 }>
                    <TypographyAverageTime average={averageTime} />
                  </Grid>
                  <Grid item xs={ 2 }><Timer style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={ 3 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelTotalTimePlaying)}</Typography>
                <Grid container>
                  <Grid item xs={ 10 }>
                    <TypographyHoursAndMinutes totalHours={totalHours} totalMinutes={totalMinutes} />
                  </Grid>
                  <Grid item xs={ 2 }><Timer style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={ 3 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelPlayerPerformance)}</Typography>
                <Grid container>
                  <Grid item xs={ 10 }>
                      <Typography style={ { fontSize: '30px', color: numberTextColor } }>
                      {(() => {
                      switch (improving) {
                        case 1: return t(translationKey.LabelImproving);
                        case 0: return t(translationKey.LabelNotImproving);
                        default: return t(translationKey.LabelInsufficientData);
                      }
                    })()}</Typography>


                    </Grid>
                  <Grid item xs={ 2 }><Heart style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <UsageGraph classes={classes} playerName={player} userData={userData} setUserData={(userData) => {
            setUserData(userData);
            setYoungPersonData(userData);
          } } chartUrl={`/cots/young_persons/${ userData.id }/usage_chart`} />
          <Grid item xs={ 12 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid item xs={ 12 }>
                    <Typography align='left' color="textPrimary" style={{marginRight: '5px'}}>{t(translationKey.LabelPerformanceOverFirst20)}</Typography>
                  </Grid>
                </Grid>
                <CotsYoungPersonPerformanceChart data={ first_graph_data }/>
                <Grid item xs={ 12 }>
                    <Typography align='left' color="textPrimary" style={{marginRight: '5px'}}>{t(translationKey.LabelPerformanceOverLast20)}</Typography>
                  </Grid>
                <CotsYoungPersonPerformanceChart data={ recent_graph_data }/>
              </CardContent>
            </Card>
          </Grid>
          { progress && progress.length > 0 && <Grid item xs={ 12 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textSecondary">{t(translationKey.TitlePlayerGameProgress, { player })}</Typography>
                <GameProgress progress={ progress } />
              </CardContent>
            </Card>
          </Grid> }
        </> }
        <Grid item xs={ 12 }>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Grid container>
                <Grid item xs={ 10 }>
                  <Typography color="textSecondary">{t(translationKey.LabelContact)}</Typography>
                </Grid>
                <Grid item xs={ 2 }>
                  <Grid container>
                    <Grid item xs={ 6 } style={ { marginLeft: 'auto' } }>
                      <Button
                        onClick={ () => {
                          const { dob, ethnicity, first_name, gender, telephone, has_disability, id, last_name, post_code, service_user_reference, country } = userData;
                          props.history.push(`/cots/young_person/${ id }/edit`, {
                            youngPerson: {
                              dob,
                              ethnicity,
                              first_name,
                              gender,
                              telephone,
                              has_disability,
                              id,
                              last_name,
                              post_code,
                              country,
                              service_user_reference
                            },
                          });
                        } }
                      >
                        <Edit htmlColor={ greyBorderColor } />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ContactDetails { ...userData } />
            </CardContent>
          </Card>
        </Grid>

        <Grid item className={ classes.deactivateButton }
              style={ { display: 'flex', justifyContent: 'space-between', width: '100%' } }>
          <Grid item xs={ 3 }>
            <ThemeProvider theme={ style }>
              <Button fullWidth variant="contained" color="primary"
                      onClick={ () => {
                        props.history.push({
                          pathname: `/cots/young_person/${ userData.id }/report`,
                          state: {userData: restUserData}
                        });
                      } }>
                {t(translationKey.ButtonDownloadReport)}
              </Button>
            </ThemeProvider>
          </Grid>
          { userData.deleted_at ?
            <RemovalButton classes={ classes } title={t(translationKey.TitleDeleteYoungPerson)} action={ () => {
              genericAlert({
                title: t(translationKey.AlertTitleConfirmDeleteYoungPerson),
                message: t(translationKey.AlertBodyConfirmDeleteYoungPerson),
                negativeTitle: t(translationKey.LabelCancel),
                positiveTitle: t(translationKey.LabelDelete),
                positiveCallback: () => {
                  axios
                    .delete(`/cots/young_persons/${ userData.id }/anonymise`)
                    .then(reloadData);
                },
              });
            } } />
            : <RemovalButton classes={ classes } title={t(translationKey.TitleDeactivateGameKey)} action={ () => {
              axios.get('/cots/young_persons/deactivation_reason')
                .then(({ data }) => {
                  let reason;
                  const setReason = (r) => {
                    reason = r;
                  };
                  genericAlert({
                    title: t(translationKey.AlertTitleDeactivationReason),
                    component: () => <ReasonSelection data={ data } setReason={ setReason } />,
                    negativeTitle: t(translationKey.ButtonCancel),
                    positiveTitle: t(translationKey.ButtonSubmit),
                    positiveCallback: () => {
                      const { id } = userData;
                      if (id) {
                        axios.delete(`/cots/young_persons/${ id }`, {
                          data: {
                            reason,
                          },
                        })
                          .then(reloadData)
                          .catch(identity);
                      }
                    },
                  });
                });
            } }
            />
          }
        </Grid>
      </Grid>
    </div>
  );
}

const RemovalButton = ({ title, action, classes }) =>
  <Button className={ classes.deactivateButton } onClick={ action }>
    { title }
  </Button>;

RemovalButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

RenderDetails.propTypes = {
  borderColour: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
  reloadData: PropTypes.func.isRequired,
  setYoungPersonData: PropTypes.func.isRequired,
};

const ReasonSelection = ({ data, setReason }) => {
  const [internalReason, setInternalReason] = useState();
  const { t } = useTranslation();
  return (
    <form>
      <FormControl component="fieldset">
        <FormLabel component="legend">{t(translationKey.TitleReason)}</FormLabel>
        <RadioGroup aria-label={t(translationKey.LabelReason)} name='reason'
                    onChange={ event => {
                      let value = parseInt(event.target.value);
                      setReason(value);
                      setInternalReason(value);
                    } }>
          {
            data.map(each => <FormControlLabel key={ each.id } value={ each.id } control={ <Radio /> }
                                               label={ each.value } checked={ each.id === internalReason } />)
          }
        </RadioGroup>
      </FormControl>
    </form>
  );
};

ReasonSelection.propTypes = {
  data: PropTypes.array.isRequired,
  setReason: PropTypes.func.isRequired,
};
