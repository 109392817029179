import React from 'react';
import YoungPersonForm from './YoungPersonForm';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';

const EditYoungPersonPage = (props) => {
  const { t } = useTranslation();
  return (
    <YoungPersonForm
      title={ t(translationKey.LabelEditYoungProfile) }
      path={ `/cots/young_persons/${ props.location.state.youngPerson.id }` }
      youngPerson={ props.location.state.youngPerson }
      { ...props }
    />
  );
};

export default EditYoungPersonPage;
