import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Wc from '@material-ui/icons/Wc';
import Event from '@material-ui/icons/Event';
import PersonOutline from '@material-ui/icons/PersonOutline';
import People from '@material-ui/icons/People';
import ContactPhone from '@material-ui/icons/ContactPhone';
import ContactMail from '@material-ui/icons/ContactMail';
import AssignmentIcon from '@material-ui/icons/Assignment';
import style from '../../../../Styles';
import Button from '@material-ui/core/Button';
import { axios } from '../../../../services/networkRequest';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../../utilities/localisation/translationKeys';

const contactFontColor = '#4C4C71';
const iconColor = '#C2C3C4';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
}));

const ContactDetails = (props) => {
  const guardian = props.guardians[0] || {};
  const classes = useStyles();
  const dateString = new Date(props.dob).toLocaleDateString();
  const { t } = useTranslation();
  return (
    <div className={ classes.root }>
      <Grid container spacing={ 1 } style={ { marginTop: '8px' } }>
        <Grid item xs={ 4 } style={ { borderRight: '1px solid #aaa' } }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 3 }><Event htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{ `${ props.first_name } D.O.B.` }</Typography>
              <Typography style={ { color: contactFontColor } }>{ dateString }</Typography>
            </Grid>
            <Grid item xs={ 3 }><Wc htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{t(translationKey.LabelGender)}</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.gender }</Typography>
            </Grid>
            <Grid item xs={ 3 }><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{t(translationKey.LabelServiceUserReference)}</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.service_user_reference }</Typography>
            </Grid>
            {
              props.isSelfSignUpDist && props.custom_entry_one_query && (
                <>
                  <Grid item xs={ 3 }><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
                  <Grid item xs={ 9 }>
                    <Typography color='textSecondary'>{props.custom_entry_one_query}</Typography>
                    <Typography style={ { color: contactFontColor } }>{ props.custom_entry_one_response }</Typography>
                  </Grid>
                </>
              )
            }
            {
              props.isSelfSignUpDist && props.custom_entry_two_query && (
                <>
                  <Grid item xs={ 3 }><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
                  <Grid item xs={ 9 }>
                    <Typography color='textSecondary'>{props.custom_entry_two_query}</Typography>
                    <Typography style={ { color: contactFontColor } }>{ props.custom_entry_two_response }</Typography>
                  </Grid>
                </>
              )
            }
            {
              props.isSelfSignUpDist && props.custom_entry_three_query && (
                <>
                  <Grid item xs={ 3 }><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
                  <Grid item xs={ 9 }>
                    <Typography color='textSecondary'>{props.custom_entry_three_query}</Typography>
                    <Typography style={ { color: contactFontColor } }>{ props.custom_entry_three_response }</Typography>
                  </Grid>
                </>
              )
            }
          </Grid>

        </Grid>
        <Grid item xs={ 3 }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 3 }><PersonOutline htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{t(translationKey.TitleGuardianName)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ (guardian.first_name || guardian.last_name) ? `${ guardian.first_name } ${ guardian.last_name }` : t(translationKey.ErrorMissingName) }</Typography>
            </Grid>
            <Grid item xs={ 3 }><People htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{t(translationKey.TitleGuardianRelationship)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ guardian.relationship || t(translationKey.ErrorMissingRelationship) }</Typography>
            </Grid>
            {
              props.isSelfSignUpDist && guardian.lumi_nova_visibility_response && (
                <>
                  <Grid item xs={ 3 }><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
                  <Grid item xs={ 9 }>
                    <Typography color='textSecondary'>{t(translationKey.LabelMarketingVisibility)}</Typography>
                    <Typography
                      style={ { color: contactFontColor } }>{ guardian.lumi_nova_visibility_response }</Typography>
                  </Grid>
                </>
              )
            }
          </Grid>
        </Grid>
        <Grid item xs={ 3 }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 3 }><ContactPhone htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{t(translationKey.LabelMobile)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ guardian.telephone || t(translationKey.ErrorMissingPhoneNumber) }</Typography>
            </Grid>
            <Grid item xs={ 3 }><ContactMail htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid item xs={ 9 }>
              <Typography color='textSecondary'>{t(translationKey.TitleEmail)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ guardian.email || t(translationKey.ErrorMissingEmail) }</Typography>
            </Grid>
          </Grid>
        </Grid>
        {!props.started &&
        <Grid item xs={ 2 }>
          <Grid container spacing={ 1 }>
            <ThemeProvider theme={ style }>
              <Button materi variant="contained" color="primary"
                      onClick={ () => {

                        confirmAlert({
                          title: t(translationKey.AlertTitleResendGuardianInvite, {firstname : guardian.first_name, lastname : guardian.last_name}),
                          message: t(translationKey.AlertBodyResendGuardianInvite),
                          buttons: [
                            {
                              label: t(translationKey.ChoiceLabelYes),
                              onClick: () => {
                                axios.post(`/ln/resend_invite/${guardian.id}/${props.id}`);
                              },
                            },
                            {
                              label: t(translationKey.ButtonCancel),
                              onClick: () => {
                              },
                            },
                          ],
                        });
                      } }>
                {t(translationKey.ButtonResendInvite)}
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
        }
      </Grid>
    </div>
  );
};

export default ContactDetails;
