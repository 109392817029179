import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../themes';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Face from '@material-ui/icons/FaceOutlined';
import Email from '@material-ui/icons/EmailOutlined';
import GameAsset from '@material-ui/icons/VideogameAssetOutlined';
import Timer from '@material-ui/icons/Timer';
import Cup from '@material-ui/icons/EmojiEventsOutlined';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTable from 'material-table';
import { activeColor, dropdownTextColor, greyBorderColor, listBackgroundColor, numberTextColor } from '../Styles';
import { axios, CancelToken } from '../services/networkRequest';
import { getChain, identity, subtractMonth } from '../services/helpers';
import { months } from '../constants';
import DailyYoungPeople from './DailyYoungPeople';
import MonthlyYoungPeople from './MonthlyYoungPeople';
import { RenderDetails, setter } from './Child/LN/YoungPeople';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles({
  noWrapText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  select: {
    color: dropdownTextColor,
    border: `1px solid ${ dropdownTextColor }`,
    fontSize: '18px',
    padding: '2px 10px',
    minWidth: '200px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: `1px solid ${ dropdownTextColor }`,
      outlineStyle: 'none',
    },
  },
  centre: {
    margin: '40px auto',
  },
});

const DashboardReport = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  // Date is stringified because the Select component only tests reference equality on objects.
  const [timePeriod, setTimePeriod] = useState(JSON.stringify({ month, year }));
  const [report, setReport] = useState();
  const [youngPersons, setYoungPersons] = useState(undefined);
  const [dataState, setDataState] = useState(false);

  const reloadData = () => {
    setDataState(!dataState);
    setYoungPersons(undefined);
  };

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });

    let url = '/ln/report';
    if (timePeriod) {
      const timePeriodObj = JSON.parse(timePeriod);
      if (timePeriodObj.month) {
        url += `?month=${timePeriodObj.month}`;
        if (timePeriodObj.year) {
          url += `&year=${timePeriodObj.year}`;
        }
      } else if (timePeriodObj.year) {
        url += `?year=${timePeriodObj.year}`;
      }
    }

    axios.get(url, {
      cancelToken,
    }).then(({ data }) => {
      setReport(data);
      setYoungPersons(data.worried_young_persons);
    }).catch(identity);

    return () => {
      cancel();
    };
  }, [timePeriod, dataState]);

  if (!report) {
    return <CircularProgress className={ classes.centre } />;
  }
  // Service start date
  const startDate = new Date(2019, 11, 1);
  const usageIntervals = [];
  let usageIndex = 0;
  for (let date = currentDate; date >= startDate; subtractMonth(date)) {
    const itemYear = date.getFullYear();
    const itemMonth = date.getMonth() + 1;
    if (itemYear === year - 1 && itemMonth === 12 && month === 1) {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
      usageIntervals[usageIndex] = { year: itemYear };
      usageIndex++;
    } else if (itemYear < year) {
      if (usageIndex > 0 && usageIntervals[usageIndex - 1].year !== itemYear) {
        usageIntervals[usageIndex] = { year: itemYear };
        usageIndex++;
      }
    } else {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
    }
  }
  const usageIsMonthly = !JSON.parse(timePeriod).month;

  const RenderDetailFactory = (borderColour, setter) => (rowData) => <RenderDetails
    setYoungPersonData={ setter }
    borderColour={ borderColour }
    reloadData={ reloadData }
    rowData={ rowData } { ...props } />;

  return (
    <div style={{ marginTop: "50px" }}>
      <Grid container>
        <Grid item xs={ 10 } align="left">
          <Typography color="textPrimary" component="span"
                      style={ { fontSize: '20px', fontWeight: 600, marginRight: '10px' } }>{t(translationKey.TitleOverviewReportForYoungPeople)}</Typography>
          <Select
            value={ timePeriod }
            onChange={ event => {
              setTimePeriod(event.target.value);
              setReport(null);
            } }
            variant='outlined'
            classes={ { root: classes.select } }
          >
            { usageIntervals.map((item, index) => {
              let itemString;
              if (index === 0) {
                itemString = t(translationKey.LabelCurrentMonth, { month: months[item.month - 1] });
              } else if (index === 1) {
                itemString = t(translationKey.LabelPreviousMonth, { month: months[item.month - 1] });
              } else if (item.month) {
                itemString = months[item.month - 1];
              } else {
                itemString = item.year.toString();
              }
              return (<MenuItem key={ index } value={ JSON.stringify({
                month: item.month,
                year: item.year,
              }) }>{ itemString }</MenuItem>);
            }) }
          </Select>
        </Grid>
        <Grid item xs={ 2 } align="right">
          <Button variant="contained" color="primary" onClick={ () => window.print() }>
            {t(translationKey.ButtonDownloadReport)}
          </Button>
        </Grid>
      </Grid>
      <div
        style={ { display: 'flex', justifyContent: 'space-between', width: 'inherit', margin: 0, paddingTop: '30px' } }>
        <div style={ { flex: 1, maxWidth: '19%' } }>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={ { root: classes.noWrapText } }>{t(translationKey.LabelYoungPeopleWhoPlayed)}</Typography>
              <Grid container style={ { marginTop: '16px' } }>
                <Grid item xs={ 10 }><Typography
                  style={ { fontSize: '30px', color: numberTextColor } }
                  align="left">{ report.young_people_played }</Typography></Grid>
                <Grid item xs={ 2 }><Face style={ { fontSize: '40px' } } /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "19%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.LabelInvitesYouHaveSent)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10}><Typography
                  style={{ fontSize: '30px', color: numberTextColor }} align="left">{report.invites_sent}</Typography></Grid>
                <Grid item xs={2}><Email style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "19%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.TitleTotalGameSessions)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10}><Typography
                  style={{ fontSize: '30px', color: numberTextColor }} align="left">{report.total_game_sessions}</Typography></Grid>
                <Grid item xs={2}><GameAsset style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "19%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.LabelAverageSessionTime)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10} align="left">
                  <Typography style={{ fontSize: '30px', color: numberTextColor, marginRight: '2px' }}
                    component='span' align="left">{Math.round(report.average_time_per_session / 60)}</Typography>
                  <Typography color="textPrimary" component='span' align="left">{t(translationKey.LabelMins)}</Typography>
                </Grid>
                <Grid item xs={2}><Timer style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "19%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.LabelChallengesCompleted)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10}><Typography
                  style={{ fontSize: '30px', color: numberTextColor }} align="left">{report.challenges_completed}</Typography></Grid>
                <Grid item xs={2}><Cup style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
      <Card style={{ border: `1px solid ${greyBorderColor}`, marginTop: "30px" }}>
        <CardContent>
          <Typography color="textPrimary" align="left">{t(translationKey.LabelNumberOfYoungPeoplePlaying)}</Typography>
          {usageIsMonthly ? <MonthlyYoungPeople data={report.graph}/> : <DailyYoungPeople data={report.graph}/>}
        </CardContent>
      </Card>
      <Card style={{ border: `1px solid ${greyBorderColor}`, marginTop: "30px" }}>
      <CardContent>
      <Typography color="textPrimary" align="left">{t(translationKey.LabelYoungPeopleWorriedDuringChallenges)}</Typography>
      <ThemeProvider theme={ tableThemer(youngPersons) }>
        <MaterialTable
          isLoading={ !youngPersons }
          style={{textTransform: 'uppercase', backgroundColor: listBackgroundColor}}
          columns={ [
            {
              title: t(translationKey.TitlePlayingTheGame),
              field: 'first_name',
              cellStyle: {
                borderLeft: `20px solid ${ activeColor }`,
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
            },
            {
              title: t(translationKey.TitleInvited),
              field: 'created_at',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
            },
            {
              title: t(translationKey.TitleStartedGame),
              field: 'first_play',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
            },
            {
              title: t(translationKey.TitleChallenges),
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ getChain(rowData, 'counts', 'challenges') || 0 }</Typography>,
            },
            {
              title: t(translationKey.TitleGoals),
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ getChain(rowData, 'counts', 'goals') || 0 }</Typography>,
            },
            {
              title: t(translationKey.TitleGameKey),
              field: 'game_key',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
            },
            {
              title: t(translationKey.TitleServiceUserReference),
              field: 'service_user_reference',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography color="textSecondary">{rowData.service_user_reference}</Typography>,
            }
          ] }
          data={ youngPersons }
          detailPanel={ RenderDetailFactory(activeColor, setter(youngPersons, setYoungPersons)) }
          onRowClick={ (event, rowData, togglePanel) => togglePanel() }
          options={ {
            detailPanelColumnAlignment: 'right',
            detailPanelType: 'single',
            paging: false,
            search: false,
            sorting: false,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          icons={ { DetailPanel: ChevronRight } }
        />
      </ThemeProvider>
      </CardContent>
      </Card>
    </div>
  );
}

export default withTranslation()(DashboardReport);
