import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  headerBackground: {
    borderBottom: '0.3rem solid #063494',
    background: '#fff',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    height: '6rem',
    display: 'grid',
    placeItems: 'center start',
  },
  logo: {
    height: '5rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: '0.5rem',
  },
}));

export default function SelfSignUpHeader({ guid }) {
  const classes = useStyles();

  return (
    <Grid container justify="flex-start" className={ classes.headerBackground }>
      <Grid item xs={ 12 } style={ { display: 'grid', placeItems: 'start' } }>
        {
          guid &&
          <img src={ `${ process.env.REACT_APP_BASE_URL }/entity/logo/${ guid }` }
               alt="Entity Logo" className={ classes.logo } />
        }
      </Grid>
    </Grid>
  )
}