import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Face from '@material-ui/icons/FaceOutlined';
import GameAsset from '@material-ui/icons/VideogameAssetOutlined';
import Timer from '@material-ui/icons/Timer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { dropdownTextColor, greyBorderColor, numberTextColor } from '../Styles';
import { axios, CancelToken } from '../services/networkRequest';
import { identity } from '../services/helpers';
import CotsPerformanceChart from './CotsPerformanceChart';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';

const useStyles = makeStyles({
  noWrapText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  select: {
    color: dropdownTextColor,
    border: `1px solid ${ dropdownTextColor }`,
    fontSize: '18px',
    padding: '2px 10px',
    minWidth: '200px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: `1px solid ${ dropdownTextColor }`,
      outlineStyle: 'none',
    },
  },
  centre: {
    margin: '40px auto',
  },
});

const DashboardReport = (props) => {
  const classes = useStyles();
  const [report, setReport] = useState();
  const [dataState] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });

    let url = '/cots/report';

    axios.get(url, {
      cancelToken,
    }).then(({ data }) => {
      setReport(data);
    }).catch(identity);

    return () => {
      cancel();
    };
  }, [dataState]);

  if (!report) {
    return <CircularProgress className={ classes.centre } />;
  }

  return (
    <div style={{ marginTop: "50px" }}>
      <Grid container>
        <Grid item xs={ 10 } align="left">
          <Typography color="textPrimary" component="span"
                      style={ { fontSize: '20px', fontWeight: 600, marginRight: '10px' } }>{t(translationKey.TitleOverviewReportForYoungPeople)}</Typography>

        </Grid>
        <Grid item xs={ 2 } align="right">
          <Button variant="contained" color="primary" onClick={ () => window.print() }>
            {t(translationKey.ButtonDownloadReport)}
          </Button>
        </Grid>
      </Grid>
      <div
        style={ { display: 'flex', justifyContent: 'space-between', width: 'inherit', margin: 0, paddingTop: '30px' } }>
        <div style={ { flex: 1, maxWidth: '32%' } }>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={ { root: classes.noWrapText } }>{t(translationKey.LabelYoungPeopleWhoPlayed)}</Typography>
              <Grid container style={ { marginTop: '16px' } }>
                <Grid item xs={ 10 }><Typography
                  style={ { fontSize: '30px', color: numberTextColor } }
                  align="left">{ report.young_people_played }</Typography></Grid>
                <Grid item xs={ 2 }><Face style={ { fontSize: '40px' } } /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "32%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.LabelTotalNumberOfDuels)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10}><Typography
                  style={{ fontSize: '30px', color: numberTextColor }} align="left">{report.total_game_sessions}</Typography></Grid>
                <Grid item xs={2}><GameAsset style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "32%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.LabelAverageSessionTime)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10} align="left">
                  <Typography style={{ fontSize: '30px', color: numberTextColor, marginRight: '2px' }}
                    component='span' align="left">{Math.round(report.average_time_per_session / 60)}</Typography>
                  <Typography color="textPrimary" component='span' align="left">{t(translationKey.LabelMins)}</Typography>
                </Grid>
                <Grid item xs={2}><Timer style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
      <Card style={{ border: `1px solid ${greyBorderColor}`, marginTop: "30px" }}>
        <CardContent>
          <Typography color="textPrimary" align="left">{t(translationKey.LabelPerformanceOfAllYoungPeopleOverLast20)}</Typography>
          <CotsPerformanceChart data={report.recent_graph}/>
        </CardContent>
      </Card>
        <Card style={{ border: `1px solid ${greyBorderColor}`, marginTop: "30px" }}>
        <CardContent>
          <Typography color="textPrimary" align="left">{t(translationKey.LabelPerformanceOfAllYoungPeopleOverFirst20)}</Typography>
          <CotsPerformanceChart data={report.first_graph}/>
        </CardContent>
      </Card>
    </div>
  );
}

export default DashboardReport;
