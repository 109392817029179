import React from 'react';
import { Grid, Card, Container, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getChain } from '../../../services/helpers';
import { useViewport, Width } from '../../../utilities/viewport';
import { BorderContainer } from '../../Organisations/InfoDisplay/OrganisationAndPointPersonDetails';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';

const useStyles = makeStyles(() => ({
  detailSection: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  subGrid: {
    boxShadow: 'box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  subTitle: {
    color: '#2D9BF0',
    textAlign: 'left',
  },
  content: {
    textAlign: 'left',
  },
  subDetailSection: {
    fontSize: '1rem',
    fontWeight: 'bolder',
    fontStyle: 'italic',
  },
}));

export function EntityDetails({ entity }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const ln_keys = getChain(entity, 'ln_game_keys') || 0;
  const cots_keys = getChain(entity, 'cots_game_keys') || 0;

  const { width } = useViewport();
  const mobileView = width <= Width.Phone;

  const url = getChain(entity, "settings", 'self_sign_up_settings', 'url');
  const signupLink = url && `${process.env.REACT_APP_SIGNUP_URL}${url}`;
  return (
    <Grid container>
      <Card classes={ classes.subGrid } style={ { width: '100%' } }>
        <Container style={ { margin: '1rem 0', maxWidth: 'none' } }>
          <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
            {t(translationKey.TitleEntity)}
          </Typography>
          <Grid container style={ { margin: '1rem 0' } } spacing={ 1 }>

            <Grid item xs={ mobileView ? 12 : 6 }>
              <Item title={t(translationKey.TitleName)}>{ getChain(entity, 'name') }</Item>
              <Item title={t(translationKey.TitleAddress)}>{ getChain(entity, 'address') }</Item>
              <Item title={t(translationKey.TitlePointPersonName)}>{ getChain(entity, 'point_person_name') }</Item>
              <Item title={t(translationKey.TitlePointPersonEmail)}>{ getChain(entity, 'point_person_email') }</Item>
              <Item title={t(translationKey.TitlePointPersonPhone)}>{ getChain(entity, 'point_person_phone') }</Item>
            </Grid>

            <Grid item xs={ mobileView ? 12 : 6 }>
              {
                ln_keys > 0 &&
                <BorderContainer>
                  <Typography className={ classes.subDetailSection } component="h3" align="left" variant="body1">
                    {t(translationKey.LabelLumiNova)}
                  </Typography>
                  <Item title={t(translationKey.LabelLicencesAllocated)}>{ ln_keys }</Item>
                  {
                    signupLink && <Item title="Signup URL"><Link target="_blank" rel="noopener" href={signupLink}>{ signupLink }</Link></Item>
                  }
                </BorderContainer>
              }
              <div style={ { margin: '1rem' } } />
              {
                cots_keys > 0 &&
                <BorderContainer>
                  <Typography className={ classes.subDetailSection } component="h3" align="left" variant="body1">
                    {t(translationKey.LabelChampionsOfTheShengha)}
                  </Typography>
                  <Item title={t(translationKey.LabelLicencesAllocated)}>{ cots_keys }</Item>
                </BorderContainer>
              }
            </Grid>
          </Grid>
        </Container>
      </Card>
    </Grid>
  )
}

function Item({ title, children }) {
  const classes = useStyles();

  return (
    <Grid item xs={ 12 } style={ { marginTop: '0.5rem' } }>
      <Typography className={ classes.subTitle }><strong>{ title }</strong></Typography>
      <Typography className={ classes.content }>{ children }</Typography>
    </Grid>
  )
}
