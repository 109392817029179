import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../../themes';
import MaterialTable from 'material-table';
import { Card, CardContent, Container, Grid, Typography } from '@material-ui/core';
import { axios } from '../../../services/networkRequest';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GameAsset from '@material-ui/icons/VideogameAssetOutlined';
import Timer from '@material-ui/icons/Timer';
import MonthlyUsage from '../MonthlyUsage';
import DailyUsage from '../DailyUsage';
import GBOResults from '../../Distributors/GBOResults';
import GuardianAnxietySurveyResults from '../../Distributors/GuardianAnxietySurveyResults';
import { dropdownTextColor, greyBorderColor, listBackgroundColor, numberTextColor } from '../../../Styles';
import { getChain, subtractMonth } from '../../../services/helpers';
import { months } from '../../../constants';
import ExitButton from '../../ExitButton';
import { GBOInformation, CORSInformationCard } from '../../Distributors/GraphInformationCards';
import CORSSurveyResults from '../../Distributors/CORSSurveyResults';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { TypographyAverageTime, TypographyHoursAndMinutes } from '../../Typography/TypographyNumberSpan';

const order = new Proxy({
  'complete': 1,
  'started': 2,
  'incomplete': 3,
}, {
  get: (target, key) => target[key] || 4,
});

const sorting = progress => {
  return progress && progress.sort((a, b) => order[a.status] - order[b.status]);
};

const useStyles = makeStyles(() => ({
  gboContainer: {
    paddingBottom: '60px',
  },
  heading: {
    marginBottom: '12px',
  },
  italic: {
    fontStyle: 'italic',
  },
  listItem: {
    width: '100%',
  },
  question: {
    paddingRight: '25px',
  },
  underline: {
    textDecoration: 'underline',
  },
  noUnderline: {
    textDecoration: 'none',
  },
  goalSpace: {
    paddingTop: '45px',
  },
  scoreSpace: {
    paddingTop: '25px',
  },
  surveyText: {
    paddingLeft: '26px',
  },
  goalTable: {
    width: '100%',
  },
  tableCell: {
    border: '1px solid grey',
  },
  button: {
    marginTop: '30px',
    backgroundColor: '#4F9AE4',
    color: '#EEFFFF',
  },
  select: {
    color: dropdownTextColor,
    border: `1px solid ${dropdownTextColor}`,
    fontSize: '14px',
    padding: '2px 10px',
    minWidth: '160px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: `1px solid ${dropdownTextColor}`,
      outlineStyle: 'none'
    }
  },
  changeData: {
    fontWeight:'bold',
    display: 'inline-block',
    marginTop: '6px',
    marginBottom: '6px'
  },
  showHideButton: {
    display: 'inline-block',
    textAlign: 'right',
    float: 'right',
    marginBottom: '16px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: '#0079C6',
    },
    '&:active': {
      backgroundColor: 'white',
      borderColor: 'white',
      color: '#0079C6',
    },
  },
}));

export default function YoungPersonReport(props) {
  const rowData = props.location.state;
  const youngPersons = [rowData];
  const young_person_id = props.match.params.id;
  const [goalResults, setGoalResults] = useState(undefined);
  const [gboResponses, setGBOResponses] = useState(undefined);
  const [corsSurveys, setCorsSurveys] = useState(undefined);
  const [name, setName] = useState(undefined);
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const classes = useStyles();
  const [userData, setUserData] = useState(rowData);
  const [timePeriod, setTimePeriod] = useState(JSON.stringify({ month, year }));
  const [showResults, setShowResults] = useState(true);
  const { t } = useTranslation();

  const usage = getChain(userData, 'usage_data', timePeriod);

  useEffect(() => {
    axios.get(`/ln/young_persons/anxiety_results/${ young_person_id }`).then(({ data }) => {
      setGoalResults(data.goals);
      setGBOResponses(data.gbos);
      setCorsSurveys(data.cors_surveys);
      setName(`${ data.first_name } ${ data.last_name }`);
    });
  }, [young_person_id]);

  useEffect(() => {
    if (!usage) {
      let url = `/ln/young_persons/${ userData.id }/chart`;
      if (timePeriod) {
        const timePeriodObj = JSON.parse(timePeriod);
        if (timePeriodObj.month) {
          url += `?month=${timePeriodObj.month}`;
          if (timePeriodObj.year) {
            url += `&year=${timePeriodObj.year}`;
          }
        } else if (timePeriodObj.year) {
          url += `?year=${timePeriodObj.year}`;
        }
      }

      axios.get(url)
        .then(({ data }) => {
          userData['usage_data'] = {
            ...userData['usage_data'],
            [timePeriod]: data,
          };
          const newUserData = { ...userData };
          setUserData(newUserData);
        });
    }
  }, [userData, timePeriod, usage]);

  useEffect(() => {
    setTimeout(window.print, 2000);
  }, []);

  const player = userData.first_name;
  const progress = sorting(getChain(userData, 'stats', 'progress'));
  const gameTime = getChain(userData, 'stats', 'game_time');
  const lastUsedDateString = gameTime && gameTime.lastLoggedIn && new Date(gameTime.lastLoggedIn).toLocaleDateString();
  const averageTime = gameTime ? gameTime.timeAverage : '';
  const totalTime = gameTime && gameTime.timeSpent;
  const totalSessions = gameTime && averageTime > 0 ? Math.round(totalTime / averageTime) : '';
  const totalHours = gameTime ? Math.floor(totalTime / 60) : '';
  const totalMinutes = gameTime ? Math.round(totalTime - (totalHours * 60)) : '';
  let startDate = new Date(userData.first_play);
  if (startDate.getDate() >= currentDate.getDate()) {
    startDate.setDate(currentDate.getDate() - 1);
  }
  const usageIntervals = [];
  let usageIndex = 0;
  for (let date = currentDate; date >= startDate; subtractMonth(date)) {
    const itemYear = date.getFullYear();
    const itemMonth = date.getMonth() + 1;
    if (itemYear === year - 1 && itemMonth === 12 && month === 1) {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
      usageIntervals[usageIndex] = { year: itemYear };
      usageIndex++;
    } else if (itemYear < year) {
      if (usageIndex > 0 && usageIntervals[usageIndex - 1].year !== itemYear) {
        usageIntervals[usageIndex] = { year: itemYear };
        usageIndex++;
      }
    } else {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
    }
  }
  const usageIsMonthly = !JSON.parse(timePeriod).month;


  if (!(goalResults && gboResponses)) {
    return null;
  }

  return (
    <Container id="unzoom" component="main" maxWidth="md"
               style={ { backgroundColor: 'white', paddingBottom: '40px', paddingLeft: '0px', paddingRight: '0px' } }>
      <Grid container direction='column' alignItems='flex-start' justify='center'
            className={ classes.gboContainer }>
        <Grid item container xs={ 12 }>
          <Grid item xs={ 11 }>
            <Typography variant="h5" align="left" style={ { marginBottom: '10px' } }>
              {t(translationKey.TitleLumiNovaYoungReport)}
            </Typography>
          </Grid>
          {rowData.service_user_reference &&
          <Grid item xs={ 11 }>
            <Typography variant="h6" align="left" style={ { marginBottom: '30px' } }>
              {t(translationKey.LabelServiceUserRefDetailed, { ref: rowData.service_user_reference})}
            </Typography>
          </Grid>
          }
          <ExitButton history={ props.history } />
        </Grid>
        <ThemeProvider theme={ tableThemer(youngPersons) }>
          <MaterialTable
            isLoading={ !youngPersons }
            columns={ [
              {
                title: t(translationKey.TitlePlayingTheGame),
                field: 'first_name',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textPrimary">{`${rowData.first_name} ${rowData.last_name}`}</Typography>,
              },
              {
                title: t(translationKey.TitleInvited),
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{rowData.created_at && new Date(rowData.created_at).toDateString()}</Typography>,
              },
              {
                title: t(translationKey.TitleStartedGame),
                field: 'first_play',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{rowData.first_play && new Date(rowData.first_play).toDateString()}</Typography>,
              },
              {
                title: t(translationKey.TitleChallenges),
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{getChain(rowData, 'counts', 'challenges') || 0}</Typography>,
              },
              {
                title: t(translationKey.TitleGoals),
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography
                  color="textSecondary">{getChain(rowData, 'counts', 'goals') || 0}</Typography>,
              },
              {
                title: t(translationKey.TitleGameKey),
                field: 'game_key',
                cellStyle: {
                  borderRight: 'none',
                },
                render: rowData => <Typography color="textSecondary">{rowData.game_key}</Typography>,
              },
            ]}
            data={youngPersons}
            options={{
              paging: false,
              search: false,
              sorting: false,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            }}
            style={{ backgroundColor: listBackgroundColor }}
          />
        </ThemeProvider>

        <Grid container style={{ width: 'inherit', margin: 0, paddingTop: '30px', textAlign: 'left' }}
          spacing={1}>
          {userData.first_play && <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}><Typography color="textPrimary">{t(translationKey.TitlePlayerGameTime, { player })}</Typography></Grid>
                <Grid item xs={6}><Typography color="textSecondary"
                  align="right">{(t(translationKey.TitleLastUsedDate, { lastUsedDateString : lastUsedDateString|| '' }))}</Typography></Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Card style={{ border: `1px solid ${greyBorderColor}` }}>
                <CardContent>
                  <Typography color="textPrimary">{t(translationKey.TitleTotalGameSessions)}</Typography>
                  <Grid container>
                    <Grid item xs={10}><Typography
                      style={{ fontSize: '30px', color: numberTextColor }}>{totalSessions}</Typography></Grid>
                    <Grid item xs={2}><GameAsset style={{ fontSize: '40px' }} /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card style={{ border: `1px solid ${greyBorderColor}` }}>
                <CardContent>
                  <Typography color="textPrimary">{t(translationKey.LabelAverageSessionTime)}</Typography>
                  <Grid container>
                    <Grid item xs={10}>
                      <TypographyAverageTime average={averageTime} />
                    </Grid>
                    <Grid item xs={2}><Timer style={{ fontSize: '40px' }} /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card style={{ border: `1px solid ${greyBorderColor}` }}>
                <CardContent>
                  <Typography color="textPrimary">{t(translationKey.LabelTotalTimePlaying)}</Typography>
                  <Grid container>
                    <Grid item xs={10}>
                      <TypographyHoursAndMinutes totalMinutes={totalMinutes} totalHours={totalHours} />
                    </Grid>
                    <Grid item xs={2}><Timer style={{ fontSize: '40px' }} /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ border: `1px solid ${greyBorderColor}` }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography color="textPrimary">{t(translationKey.LabelPlayersGameUsage, { player: player, timeframe: (usageIsMonthly ? 'monthly' : 'daily')})}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography align='right' color="textPrimary" style={{ marginRight: '5px' }}>{t(translationKey.LabelFilterBy)}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        value={timePeriod}
                        onChange={event => {
                          setTimePeriod(event.target.value);
                          setUserData({ ...userData, usage_data: null });
                        }}
                        variant='outlined'
                        classes={{ root: classes.select }}
                      >
                        {usageIntervals.map((item, index) => {
                          let itemString;
                          if (index === 0) {
                            itemString = `Current Month (${months[item.month - 1]})`;
                          } else if (index === 1) {
                            itemString = `Previous Month (${months[item.month - 1]})`;
                          } else if (item.month) {
                            itemString = months[item.month - 1];
                          } else {
                            itemString = item.year.toString();
                          }
                          return (<MenuItem value={JSON.stringify({ month: item.month, year: item.year })}>{itemString}</MenuItem>)
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                  {usageIsMonthly ? <MonthlyUsage usage={usage} /> : <DailyUsage usage={usage} />}
                </CardContent>
              </Card>
            </Grid>

            {progress && progress.length > 0 && <Grid item xs={12}>
            <Typography color="textPrimary">{t(translationKey.TitlePlayerGameProgress, { player })}</Typography>
            <ol>
            {progress.map(goal => {
              let i = 0;
              const exposures = goal.inGame.concat(goal.outOfGame);
              return (<>
                <li>{goal.title}</li>
                { exposures.length > 0 &&
                <div>
                <Table size="small" aria-label={t(translationKey.AccessibilityLabelADenseTable)}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={ classes.tableCell }><strong>#</strong></TableCell>
                      <TableCell align="left" className={ classes.tableCell }><strong>{t(translationKey.LabelDate)}</strong></TableCell>
                      <TableCell align="left" className={ classes.tableCell }><strong>{t(translationKey.LabelChallenge)}</strong></TableCell>
                      <TableCell align="left" className={ classes.tableCell }><strong>{t(translationKey.LabelBeforeChallenge)}</strong></TableCell>
                      <TableCell align="left" className={ classes.tableCell }><strong>{t(translationKey.LabelDuringChallenge)}</strong></TableCell>
                      <TableCell align="left" className={ classes.tableCell }><strong>{t(translationKey.LabelIfAskedToDoItAgain)}</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {exposures.map(exposure => {
                  return exposure.reflections && exposure.reflections.map(reflection => {
                  i++;
                  const date = new Date(reflection.date);
                  const dateString = date.toLocaleDateString();
                  return (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row" className={classes.tableCell}>
                        {i + 1}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>{dateString}</TableCell>
                      <TableCell align="left" className={classes.tableCell}>{exposure.name}</TableCell>
                      <TableCell align="left" className={classes.tableCell}>{reflection.results[0].score}</TableCell>
                      <TableCell align="left" className={classes.tableCell}>{reflection.results[1].score}</TableCell>
                      <TableCell align="left" className={classes.tableCell}>{reflection.results[2].score}</TableCell>
                    </TableRow>
                  );
                  });
                })}
                </TableBody>

              </Table>
              {goal.change_date && <Typography className={classes.changeData}>{t(translationKey.LabelGoalProgressEndedOn)}</Typography>} {goal.change_date && new Date(goal.change_date).toLocaleDateString()}
              <br></br>
              {goal.change_reason && <Typography className={classes.changeData}>{t(translationKey.LabelReasonColon)}</Typography> } {goal.change_reason }
              </div>
            }
                </>);
            })}
            </ol>

            </Grid>}
          </>}
        </Grid>

        <Grid container direction="row" justify="space-between" alignItems="flex-start"
          className={classes.bottomBorder}>
          <Grid item xs={12} className={classes.heading}>
            <Typography variant="h6" align="left">
              {t(translationKey.TitleGoalBasedOutcomes, { name })}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.heading}>
          <Typography variant="span" align="left" color='textPrimary'>
            {t(translationKey.TitleClosenessToGoal)}
              </Typography>
        </Grid>
        <Grid item xs={12} className={classes.question}>
          <Typography align="left" color='textPrimary'>
            {t(translationKey.ScaleZeroToTen)}
              </Typography>
        </Grid>
        <Grid item xs={12} className={classes.italic}>
          <Typography align="left" color='textSecondary'>
            {t(translationKey.LabelScaleZeroExplanation)}
              </Typography>
          <Typography align="left" color='textSecondary'>
            {t(translationKey.LabelScaleTenExplanation)}
              </Typography>
        </Grid>
        <Grid item xs={12}><GBOResults responses={gboResponses} /></Grid>
      </Grid>

      <GBOInformation/>

      {!userData['cors'] &&
      <Grid container direction='column' alignItems='flex-start' justify='center'>
        <Grid item xs={12} className={classes.heading}>
          <Typography variant="h6" align="left">
            {t(translationKey.TitleGuardianAnxietySurveyResults, { name })}
              </Typography>
        </Grid>
        <Grid item xs={12}><GuardianAnxietySurveyResults results={goalResults} /></Grid>
        <Table size="small" aria-label={t(translationKey.AccessibilityLabelADenseTable)}>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableCell}><strong>#</strong></TableCell>
              <TableCell align="left" className={classes.tableCell}><strong>{t(translationKey.LabelSurveyName)}</strong></TableCell>
              <TableCell align="left" className={classes.tableCell}><strong>{t(translationKey.LabelDate)}</strong></TableCell>
              <TableCell align="left" className={classes.tableCell}><strong>{t(translationKey.LabelScore)}</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {goalResults.map((goal, i) => {
              const date = new Date(goal.date);
              const dateString = date.toDateString();
              const parts = dateString.split(' ');
              const goalDate = [parts[2], parts[1], parts[3]].join(' ');
              return (
                <TableRow key={i}>
                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    {i + 1}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>{goal.goal_name}</TableCell>
                  <TableCell align="left" className={classes.tableCell}>{goalDate}</TableCell>
                  <TableCell align="left" className={classes.tableCell}>{goal.final_score}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>}

      {
        userData['cors'] && corsSurveys && corsSurveys.length > 0 &&
        <Grid container direction='column' alignItems='flex-start' justify='center'>
        <Grid item xs={12} className={classes.heading}>
          <Typography variant="h6" align="left">
            {t(translationKey.TitleCorsResult, { name })}
          </Typography>
          <Typography align="left" style={{color: 'grey'}}>
            {t(translationKey.TitleCompletedSurveysColon)}
          </Typography>
        </Grid>
        <Grid item xs={12}><CORSSurveyResults results={corsSurveys} /></Grid>
        <Table size="small" aria-label={t(translationKey.AccessibilityLabelADenseTable)}>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableCell}><strong>#</strong></TableCell>
              <TableCell align="left" className={classes.tableCell}><strong>{t(translationKey.LabelChildCorsCompletedOn)}</strong></TableCell>
              <TableCell align="left" className={classes.tableCell}><strong>{t(translationKey.LabelScore)}</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {corsSurveys.map((survey, i) => {
              const date = new Date(survey.survey_date);
              const dateString = date.toDateString();
              const parts = dateString.split(' ');
              const goalDate = (i === 0 ) ? t(translationKey.LabelBaseline) + [parts[2], parts[1], parts[3]].join(' ') : [parts[2], parts[1], parts[3]].join(' ');
              return (
                <TableRow key={i}>
                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    {i + 1}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>{goalDate}</TableCell>
                  <TableCell align="left" className={classes.tableCell}>{survey.total}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Button variant="contained" color="primary"
                        className={ classes.showHideButton }
                        onClick={ () => {
                          setShowResults(!showResults)
                        } } style={{textTransform: 'uppercase'}}>
                  {showResults ? t(translationKey.ButtonHide) : t(translationKey.ButtonShow)} {t(translationKey.ButtonCompletedSurveys)}
        </Button>

        {showResults &&
          corsSurveys.map((survey, i) => {
            const date = new Date(survey.survey_date);
            const dateString = date.toDateString();
            const goalDate = (i === 0 )
            ? t(translationKey.LabelCorsBaselineCompletedOn, { date: dateString})
            : t(translationKey.LabelCorsCompletedOn, { date: dateString})
            return (
              <div>
                <Typography align="left" style={{ fontWeight: 600, width: '500px' }}> {i + 1}. {goalDate} </Typography>
                <Table>
                  <TableBody>
                  {
                    Object.entries(survey.answers).map(([key, value]) =>
                      <TableRow>
                        <TableCell style={{border: 'none', padding: 0, paddingLeft: '25px'}} align="left" className={classes.tableCell}>{key.trim()}</TableCell>
                        <TableCell style={{border: 'none', padding: 0}} align="left" className={classes.tableCell}>{value.trim()}</TableCell>
                      </TableRow>
                      )
                  }
                  <Typography align="left">{t(translationKey.LabelTotalSurveyScore, { total: survey.total })}</Typography>
                  </TableBody>
                </Table>
              </div>
            );
          })
        }

        <CORSInformationCard/>
      </Grid>
      }


    </Container>
  );
}
