import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { CircularProgress } from '@material-ui/core';
import { barColor } from '../../Styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 250,
  },
  centre: {
    margin: 'auto',
  },
}));

const DailyUsage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!props.usage) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  // Axis label hackery from first item date to avoid keeping lookup tables
  const date = new Date(props.usage[0].date);
  const dateString = date.toDateString();
  const parts = dateString.split(' ');
  const yAxisLabel = parts[1] + ' ' + parts[3];
  // Assumes data comes from server in the correct order (it does currently!)
  // Save a lot of time on parsing the dates and sorting
  const chartData = props.usage.map((item, index) => {
    return {
      day: index + 1,
      usage: Math.round(item.time / 60),
    };
  });
  const dayValues = chartData.map(item => item.day);
  const usageValues = chartData.map(item => item.usage);
  const maxValue = Math.max(...usageValues);
  let tickValues = [0, 20, 40, 60];
  if (maxValue > 60) {
    const maxAxis = (Math.floor(maxValue / 20) + 1) * 20;
    tickValues = [];
    for(let i=0; i<=maxAxis; i+=20) {
      tickValues.push(i);
    }
  }
  return (
    <div className={ classes.root }>
      <VictoryChart
        height={ 270 }
        width={ props.size.width }
        domainPadding={ 16 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ dayValues }
                    //  tickFormat={ monthStrings }
                     label={yAxisLabel}
                     style={ { axisLabel: { padding: 30 }, grid: { stroke: 'none' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={tickValues}
                     fixLabelOverlap={maxValue > 200}
                     label={t(translationKey.LabelGameSessionTimeInMins)}
                     style={ { axisLabel: { padding: 35 } } }
        />
        <VictoryBar
          tickValues={ dayValues }
          cornerRadius={ { top: 2 } }
          style={ { data: { fill: barColor, width: props.size.width / 40 } } }
          data={ chartData }
          x={t(translationKey.LabelDay)}
          y={t(translationKey.LabelUsage)}
        />
      </VictoryChart>
    </div>
  );
};

export default withSize()(DailyUsage);
