import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Container, Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { axios } from '../../services/networkRequest';
import * as PropTypes from 'prop-types';
import { getChain } from '../../services/helpers';
import Footer from '../content/Footer';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';


const useStyles = makeStyles(() => ({
  card: {
    minWidth: 275,
    marginBottom: '16px',
  },
  title: {
    padding: '20px',
    paddingTop: '50px',
  },
  heading: {
    marginBottom: '12px',
  },
  listItem: {
    width: '100%',
  },
}));

function ResourceItem(props) {
  const classes = useStyles();

  const handleClick = () => {
    console.log('Click', props.filename);
    axios.getFile(`/${props.prefix}/training/${ props.filename }`, props.filename);
  };

  return (
    <Card className={ classes.card }>
      <CardContent>
        <Grid container direction='column' alignItems='flex-start' justify='center'>
          <Grid item xs={ 12 } className={ classes.listItem }>
            <ListItem button onClick={ handleClick } className={ classes.listItem }>
              <ListItemText
                primary={ props.filename }
                primaryTypographyProps={ { style: { fontWeight: 800 } } }
              />
              <CloudDownload />
            </ListItem>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

ResourceItem.propTypes = {
  filename: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
};

export default function Resources({ prefix }) {
  const classes = useStyles();
  const [files, setFiles] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    axios.get(`/${prefix}/training`)
      .then(({ data }) => {
        setFiles(getChain(data, 'files'));
      });
    }, [prefix]);

  return (
    <Container component="main" maxWidth="md">
      <Card className={ classes.card }>
        <CardContent>
          <Grid container direction='column' alignItems='flex-start' justify='center'>
            <Grid item xs={ 12 } className={ classes.heading }>
              <Typography variant="h4" align="left">
                {t(translationKey.TitleResources)}
              </Typography>
            </Grid>
            <Grid item xs={ 12 }>
              <Typography component="h1" align="left" variant="body1">
                {t(translationKey.LabelClickToDownloadResources)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      { files && files.map((entry, index) => (
        <Grid container direction="column" key={ index }>
          <ResourceItem filename={ entry.file } prefix={prefix} />
        </Grid>
      )) }
      <Footer />
    </Container>
  );
}

Resources.propTypes = {
  prefix: PropTypes.string.isRequired,
}
