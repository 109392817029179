import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryLine, VictoryLabel, VictoryLegend, VictoryChart } from 'victory';
import { withSize } from 'react-sizeme';
import { CircularProgress } from '@material-ui/core';
import { numberTextColor as blue, darkgreen as green } from '../Styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';
import { tickSteps } from '../services/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 350,
  },
  centre: {
    margin: 'auto',
  },
  axis: {
    color: 'red'
  }
}));

const CotsYoungPersonPerformanceChart = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!props.data) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  const reducedPerformanceValues = props.data.reduce((acc, item) => {
    if(item.performance_mean > 0) {
      acc.push(item.performance_mean);
    }
    if(item.performance_median > 0) {
      acc.push(item.performance_median);
    }
    return acc;
  }, []);
  const xAxisValues = props.data.map(item => item.game);
  const tickValuesPerformance = tickSteps(reducedPerformanceValues, 0, num => parseFloat(num.toFixed(2)));
  return (
    <div className={ classes.root }>
      <VictoryChart
        height={ 340 }
        width={ 1100 }
        domain={{y:[tickValuesPerformance[0], tickValuesPerformance[tickValuesPerformance.length - 1]]}}
        >
          <VictoryAxis
            label={t(translationKey.LabelDuels)}
            tickValues={ xAxisValues }
            style={{
              grid: { stroke: "#818e99", strokeWidth: 0.5 },
            }}
          />

          {/* Dependent axis for line graphs. */}
          <VictoryAxis dependentAxis
            label={t(translationKey.LabelHrvInMs)}
            axisLabelComponent = {
              <VictoryLabel dy={-10}/>
            }
            tickValues={ tickValuesPerformance }
            orientation='left'
             style={{
            grid: { stroke: "#818e99", strokeWidth: 0.5 },
            }}
          />

          <VictoryLine
            data={ props.data }
            x='game'
            y='performance_median'
            style={ { data: { stroke: blue } } }
          />
          <VictoryLine
            data={ props.data }
            x='game'
            y='performance_mean'
            style={ { data: { stroke: green } } }
          />

          <VictoryLegend x={430} y={320}
            gutter={20}
            orientation="horizontal"
            data={[
              { name: t(translationKey.LabelMean), symbol: { fill: green } },
              { name: t(translationKey.LabelMedian), symbol: { fill: blue } },
            ]}
          />
        </VictoryChart>
    </div>
  );
};

export default withSize()(CotsYoungPersonPerformanceChart);
