import { createMuiTheme } from '@material-ui/core/styles';

const greyBorderColor = '#aaa';
const tableRowBackground = '#fff';

const baseTheme = ({ tableBody }) => ({
  overrides: {
    MuiTable: {
      root: {
        borderCollapse: 'separate',
        borderSpacing: '0 16px',
      },
    },
    MuiTableCell: {
      body: tableBody,
    },
    MuiTableRow: {
      root: {
        backgroundColor: tableRowBackground,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: 'unset',
      },
      elevation2: {
        boxShadow: 'unset',
      },
    },
  },
});

export const tableTheme = createMuiTheme(baseTheme({
  tableBody: {
    borderTop: `1px solid ${ greyBorderColor }`,
    borderRight: `1px solid ${ greyBorderColor }`,
    borderBottom: `1px solid ${ greyBorderColor }`,
  },
}));

export const emptyTableTheme = createMuiTheme(baseTheme({
  tableBody: {
    border: `1px solid ${ greyBorderColor }`,
  },
}));

export const tableThemer = (array) => {
  return array && array.length > 0 ? tableTheme : emptyTableTheme;
};
