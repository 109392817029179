import React, { useState } from 'react';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { axios } from '../../services/networkRequest';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteAlert, deleteDistributorAlert, genericAlert, getChain, restoreAlert } from '../../services/helpers';
import { Delete, Settings } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { tableThemer } from '../../themes';
import ChevronRight from '@material-ui/core/SvgIcon/SvgIcon';
import DistributorList from '../Distributors/DistributorList';
import { activeColor, listBackgroundColor } from '../../Styles';
import fileDownload from 'js-file-download';
import { OrganisationAndPointPersonDetails } from './InfoDisplay/OrganisationAndPointPersonDetails';
import { withTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

function ButtonState({ children }) {
  const [file, setFile] = useState(undefined);

  return children(file, setFile);
}

const useStyles = () => ({
  subGrid: {
    boxShadow: 'box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
});

class OrganisationsDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organisation: getChain(this.props.location, 'state', 'organisation'),
      file: null,
      invitees: [],
      msg: null,
      distributors: [],
    };
    this.editOrganisation = this.editOrganisation.bind(this);
    this.deleteOrganisation = this.deleteOrganisation.bind(this);
    this.organisationSettings = this.organisationSettings.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.handleInvite = this.handleInvite.bind(this);
    this.populateOrganisation = this.populateOrganisation.bind(this);
    this.selectEntity = this.selectEntity.bind(this);
    this.deleteEntity = this.deleteEntity.bind(this);
    this.deleteDistributor = this.deleteDistributor.bind(this);
    this.restoreDistributor = this.restoreDistributor.bind(this);
    this.deleteInvitee = this.deleteInvitee.bind(this);
  }

  selectEntity({ id }) {
    axios.get(`/entity/${ id }`)
      .then(({ data }) => {
        this.props.history.push(`/organisation/${ this.props.match.params.org_id }/entity/${ id }`,
          { entity: data, organisation: this.state.organisation });
      });
  }

  populateOrganisation() {
    axios.get(`/organisation/${ this.props.match.params.org_id }/distributors`)
      .then(({ data }) => {
        this.setState((state) => {
          return ({
            ...state,
            organisation: {
              ...state.organisation,
              distributors: data.distributors || [],
              invitees: data.invitees || [],
            },
            distributors: data.distributors || []
          });
        });
      });

    axios.get(`/organisation/${ this.props.match.params.org_id }/entities`)
      .then(({ data }) => {
        this.setState((state) => {
          return ({
            ...state,
            organisation: {
              ...state.organisation,
              entities: data || [],
            }
          });
        });
      });
  }

  componentDidMount() {
    if (!this.state.organisation) {
      const org_id = getChain(this.props, 'match', 'params', 'org_id');
      if (!org_id) {
        this.props.history.replace('/');
      }
      axios.get(`/organisation/${ org_id }`)
        .then(({ data }) => {
          this.setState((state) => ({
            ...state,
            organisation: {
              ...data,
              distributors: getChain(state, 'organisation', 'distributors'),
              invitees: getChain(state, 'organisation', 'invitees'),
            }
          }));
        })
        .then(this.populateOrganisation);
    }

    this.populateOrganisation();
  }

  deleteOrganisation() {
    const { name, id } = this.state.organisation;
    deleteAlert(name, `/organisation/${ id }`, () => {
      this.props.history.push('/organisations');
    });
  }

  organisationSettings() {
    const id  = this.state.organisation.id;
    this.props.history.push(`/organisation/${ id }/settings`, { organisation: this.state.organisation });
  }

  deleteEntity({ id, name }) {
    const { t } = this.props;
    axios.get(`/entity/${ id }/delete_check`)
      .then(({ data }) => {
        const { distributors, active_users } = data;
        let message;
        if (active_users) {
          message = t(translationKey.MessageConfirmDeleteEntity);
        } else if (distributors) {
          message = t(translationKey.MessageDistributorsWillAlsoBeRemoved, { list: distributors });
        }

        deleteAlert(name, `/entity/${ id }`, () => {
          this.populateOrganisation();
        }, message);
      });
  }

  deleteInvitee({ first_name, last_name, id }) {
    if (typeof (id) === 'string') {
      id = id.startsWith('i') ? id.substring(1) : id;
    }

    deleteAlert(`${ first_name } ${ last_name }`, `/invite/distributor/${ id }`, this.populateOrganisation);
  }

  deleteDistributor({ id, name, young_persons }) {
    if (typeof (id) === 'string') {
      id = id.startsWith('d') ? id.substring(1) : id;
    }

    const sorted = young_persons.sorted;
    const activeYps = sorted.active.length;
    const notStartedYps = sorted.not_yet_active.length;
    const inactiveYps = sorted.inactive.length;
    deleteDistributorAlert(id, name, `/distributor/${ id }`, this.populateOrganisation, activeYps, notStartedYps, inactiveYps);
  }

  restoreDistributor({ id, name }) {
    id = id.startsWith('d') ? id.substring(1) : id;

    restoreAlert(name, `/distributor/restore/${ id }`, this.populateOrganisation);
  }

  editOrganisation() {
    this.props.history.push(`/organisation/${ this.state.organisation.id }/edit`, { organisation: this.state.organisation });
  }

  handleInvite(event) {
    const { t } = this.props;
    event.preventDefault();

    axios.post(`/organisation/${ this.state.organisation.id }/email_distributors`)
      .then(() => {

        confirmAlert({
          title: t(translationKey.AlertTitleInvitesSent),
          message: t(translationKey.AlertBodyInvitesSent),
          buttons: [
            {
              label: t(translationKey.LabelThanks),
            },
          ],
        });
      });
  }

  handleSubmit(event) {
    const { t } = this.props;
    event.preventDefault();
    const uploading = this.state.uploading;
    if (!uploading) {
      this.setState({ uploading: true });
      let formData = new FormData();
      let csvFile = document.querySelector('#csv');
      if (csvFile.files[0]) {
        formData.append('distributors', csvFile.files[0]);
        axios.put(`/organisation/${ this.state.organisation.id }/distributors`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(() => {
            genericAlert(t(translationKey.AlertTitleDistributorsUploaded), t(translationKey.AlertBodyDistributorsUploaded, { file: csvFile.files[0].name }));
          })
          .catch(error => {
            let errorMessage = getChain(error, 'response', 'data', 'error') || t(translationKey.ErrorUnknown);
            genericAlert(t(translationKey.AlertTitleDistributorUploadFail), t(translationKey.AlertBodyDistributorUploadFail, { error: errorMessage }));
          })
          .finally(() => {
            this.setState({ uploading: false });
          });
      } else {
        alert(t(translationKey.AlertNoFile));
      }
    }
  }

  downloadCSV() {
    axios.get(`/organisation/${ this.state.organisation.id }/distributors`, {
      headers: {
        'Accept': 'text/csv',
        responseType: 'arraybuffer',
      },
    })
      .then(res => {
        fileDownload(res.data, 'distributors.csv', res.headers['content-type']);
      });
  }

  render() {
    const { organisation } = this.state;
    const { entities, distributors, invitees } = organisation || { entities: [], distributors: [], invitees: [] };
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        <Grid container style={ { margin: '1rem 0' } }>
          <Grid item xs={ 12 }>
            <Card classes={ classes.subGrid }>
              <Button onClick={ this.editOrganisation }>
                <EditIcon />
              </Button>
              <Button onClick={ this.deleteOrganisation }>
                <DeleteIcon />
              </Button>
              <Button onClick={ this.organisationSettings }>
                <Settings />
              </Button>
            </Card>
          </Grid>
        </Grid>
        <OrganisationAndPointPersonDetails organisation={ organisation } />
        <br />
        <br />
        <div>
          <br />
          { entities && entities.length > 0 && <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Button disabled={ !getChain(invitees, 'length') } variant="contained" onClick={ this.handleInvite }>{t(translationKey.ButtonSendInvites)}</Button>
            <br /><br /><br />
            <Typography component="h1" variant="h5">
              {t(translationKey.LabelUploadDistributors)}
            </Typography>
            <form>
              <ButtonState>
                {
                  (file, setFile) => <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      type="file"
                      fullWidth
                      name="csv"
                      id="csv"
                      onChange={ event => setFile(event.target.value) }
                      required />
                    <Button
                      disabled={ !file || this.state.uploading }
                      fullWidth
                      variant="contained"
                      color="primary"
                      type='submit'
                      onClick={ this.handleSubmit }>
                      {t(translationKey.ButtonSubmit)}
                    </Button>
                  </>
                }
              </ButtonState>
            </form>
            <br />
            <Typography component="h1" variant="h5">
              {t(translationKey.LabelDownloadDistributors)}
            </Typography>
            <Button fullWidth
                    variant="contained"
                    color="primary" onClick={ this.downloadCSV }>{t(translationKey.ButtonDownloadFile)}</Button>
          </Container> }

          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={ () => this.props.history.push(`/organisation/${ this.state.organisation.id }/entity/create`,
              { organisation: organisation }) }>
            {t(translationKey.ButtonCreateEntity)}
          </Button>
          <br /><br />

          <ThemeProvider theme={ tableThemer(entities) }>
            <MaterialTable
              isLoading={ !entities }
              columns={ [
                {
                  title: '#',
                  field: 'id',
                  cellStyle: {
                    borderLeft: `20px solid ${ activeColor }`,
                    borderRight: 'none',
                  },
                },
                {
                  title: t(translationKey.LabelEntityName),
                  field: 'name',
                  cellStyle: {
                    borderRight: 'none',
                  },
                },
                {
                  title: t(translationKey.LabelEntityAddress),
                  field: 'address',
                  cellStyle: {
                    borderRight: 'none',
                  },
                },
                {
                  title: t(translationKey.TitleCreatedAt),
                  field: 'created_at',
                  cellStyle: {
                    borderRight: 'none',
                  },
                },
              ] }
              actions={ [
                {
                  icon: () => <Delete />,
                  onClick: (event, rowData) => this.deleteEntity(rowData),
                },
              ] }
              data={ entities }
              onRowClick={ (event, rowData) => this.selectEntity(rowData) }
              options={ {
                actionsColumnIndex: 4,
                detailPanelColumnAlignment: 'right',
                detailPanelType: 'single',
                paging: false,
                search: false,
                sorting: false,
                showTitle: false,
                toolbar: false,
                headerStyle: {
                  backgroundColor: listBackgroundColor,
                },
              } }
              icons={ { DetailPanel: ChevronRight } }
              style={ { backgroundColor: listBackgroundColor } }
            />
          </ThemeProvider>
          <DistributorList
            distributors={ distributors || [] }
            invitees={ invitees || [] }
            deleteDistributor={ this.deleteDistributor }
            restoreDistributor={ this.restoreDistributor }
            deleteInvitee={ this.deleteInvitee }
            clickDistributor={ (event, rowData) => this.props.history.push(`/distributor/${ rowData.id.substring(1) }`, {
              distributor: {
                ...rowData,
                id: rowData.id.substring(1),
              },
              allDistributors: distributors
            }) }
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(useStyles)(OrganisationsDetailsPage));
