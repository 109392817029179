import React, { useEffect, useState } from 'react';
import { Button, Container } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../themes';
import { axios } from '../../services/networkRequest';
import { genericAlert, getChain } from '../../services/helpers';
import { activeColor, listBackgroundColor } from '../../Styles';

import 'react-confirm-alert/src/react-confirm-alert.css';
import MaterialTable from 'material-table';
import ChevronRight from '@material-ui/core/SvgIcon/SvgIcon';
import { CloudDownload } from '@material-ui/icons';
import { Games } from '../../constants';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import fileDownload from 'js-file-download';


function AggregatedData(props) {
  const [files, setFiles] = useState();
  const { t } = useTranslation();
  const url = props.game === Games.LN.shortName ? '/historic_data' : '/cots/historic_data';
  const postUrl = props.game === Games.LN.shortName ? '/aggregate_data' : '/cots/aggregate_data';
  const hubUsageUrl = '/aggregate_data/hub_usage';

  function downloadXLSX(filename) {
    axios.getFile(`${url}/${ filename }`, filename);
  }

  useEffect(() => {
    axios.get(url)
      .then(({ data }) => {
        setFiles(getChain(data, 'files'));
      });
  }, [url]);

  const downloadhubUsageCSV = () => {
    // This function downloads Hub Usage Data in CSV.
    axios.get(hubUsageUrl, {
      headers: {
        'Accept': 'text/csv',
        responseType: 'arraybuffer',
      },
    })
      .then(res => {
        let now = new Date()
  
        fileDownload(res.data, `${ now.getDate() }_${ now.getMonth() + 1 }_${ now.getFullYear() }_hub_usage_data.csv`, res.headers['content-type']);
      })
  }

  return (
    <Container component="main" maxWidth="lg">
      <Button mb={ 10 } align="right" variant="contained" color="primary"
              onClick={ () => genericAlert( t(translationKey.AlertTitleStartProcessing),
                t(translationKey.AlertBodyStartProcessing),
                () => {
                  axios.post(postUrl);
                })
              }>
        {t(translationKey.ButtonGetUpdatedData)}
      </Button>
      {
        props.game === Games.LN.shortName && 
        <Button mb={ 10 } align="right" variant="contained" color="primary"
          onClick={ () => {downloadhubUsageCSV()}} style={{marginLeft: '20px'}}>
          {t(translationKey.ButtonGetHubUsageData)}
        </Button>
      }
      <br />
      <br />
      <ThemeProvider theme={ tableThemer(files) }>
        <MaterialTable
          isLoading={ !files }
          columns={ [
            {
              title: t(translationKey.TitleFilename),
              field: 'file',
              cellStyle: {
                borderLeft: `20px solid ${ activeColor }`,
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleLastModified),
              field: 'last_modified',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleFileSize),
              field: 'size',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          data={ files }
          options={ {
            actionsColumnIndex: 3,
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }

          actions={ [
            {
              icon: () => <CloudDownload />,
              onClick: (event, rowData) => downloadXLSX(rowData.file),
            },
          ] }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
        />
      </ThemeProvider>
    </Container>
  );
}

export default AggregatedData;
