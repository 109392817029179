import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

function HigherOrderComponent(props) {
  const { classes } = props;
  return <Button className={ classes.root }>Higher-order component</Button>;
}

HigherOrderComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const activeColor = '#55a301';
export const invitedColor = '#585F66';
export const deactivatedColor = 'red';
export const listBackgroundColor = '#fafafa';
export const notActiveColor = '#A30200';
export const greyBorderColor = '#aaa';
export const tableItemBorderColor = '#cacaca';
export const notYetActivatedColor = '#878586';
export const white = '#ffffff';
export const red = '#D20012';
export const orange = '#F53D08';
export const yellow = '#F28109';
export const lightgreen = '#7FC80F';
export const darkgreen = '#19B505';
export const barColor = '#CC218D';
export const lineColor = '#273419';
export const numberTextColor = '#043494';
export const challengeReflectionsColorScale = ['#FFA8DF', '#CC128D', '#64003D'];
export const dropdownTextColor = '#359DE8';
export const lightBlueCardColor = '#E3ECF5';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#2D9BF0',
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        color: white,
        backgroundColor: '#043494',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: white,
        '&:hover': {
          backgroundColor: '#0079C6',
        },
        '&:active': {
          backgroundColor: 'white',
          color: '#0079C6',
        },
      },
    },
  },
});
// export default withStyles(styles)(HigherOrderComponent);
