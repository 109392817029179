import React from 'react';
import { Card, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getChain } from '../../../services/helpers';
import { useViewport, Width } from '../../../utilities/viewport';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';

const useStyles = makeStyles(() => ({
  detailSection: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  subDetailSection: {
    fontSize: '1rem',
    fontWeight: 'bolder',
    fontStyle: 'italic',
  },
  subGrid: {
    boxShadow: 'box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  subTitle: {
    color: '#2D9BF0',
    textAlign: 'left',
  },
  content: {
    textAlign: 'left',
  }
}));

export function OrganisationAndPointPersonDetails({ organisation }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const lnGameKeys = getChain(organisation, 'ln_game_keys');
  const lnExpirationDate = getChain(organisation, 'ln_expiration_date');
  const cotsGameKeys = getChain(organisation, 'cots_game_keys');
  const cotsGameExpirationDate = getChain(organisation, 'cots_expiration_date');

  const { width } = useViewport();
  const tabletView = width <= Width.SmallTablet;
  const mobileView = width <= Width.Phone;

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ tabletView ? 12 : 8 }>
        <Card classes={ classes.subGrid }>
          <Container style={ { margin: '1rem 0' } }>
            <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
              {t(translationKey.TitleOrganisation)}
            </Typography>
            <Grid container style={ { margin: '1rem 0' } } spacing={ 1 }>

              <Grid item xs={ mobileView ? 12 : 6 }>
                <Item title={t(translationKey.LabelName)}>{ getChain(organisation, 'name') }</Item>
                <Item title={t(translationKey.LabelPostcode)}>{ getChain(organisation, 'postcode') }</Item>
                <Item title={t(translationKey.LabelBuyerName)}>{ getChain(organisation, 'buyer', 'name') }</Item>
                <Item title={t(translationKey.LabelPhone)}>{ getChain(organisation, 'phone') }</Item>
              </Grid>

              <Grid item xs={ mobileView ? 12 : 6 }>
                {
                  lnGameKeys > 0 &&
                  <BorderContainer>
                    <Typography className={ classes.subDetailSection } component="h3" align="left" variant="body1">
                      {t(translationKey.TitleLumiNova)}
                    </Typography>
                    <>
                      <Item title={t(translationKey.LabelAssignedKeys)}>{ lnGameKeys }</Item>
                      <Item title={t(translationKey.LabelLicenseExpiryDate)}>{ lnExpirationDate }</Item>
                    </>
                  </BorderContainer>
                }
                {
                  cotsGameKeys > 0 &&
                  <BorderContainer>
                    <Typography className={ classes.subDetailSection } component="h3" align="left" variant="body1">
                      {t(translationKey.TitleCotsGame)}
                    </Typography>
                    <>
                      <Item title={t(translationKey.LabelAssignedKeys)}>{ cotsGameKeys }</Item>
                      <Item title={t(translationKey.LabelLicenseExpiryDate)}>{ cotsGameExpirationDate }</Item>
                    </>
                  </BorderContainer>
                }
              </Grid>
            </Grid>

          </Container>
        </Card>
      </Grid>
      <Grid item xs={ tabletView ? 12 : 4 } classes={ classes.subGrid }>
        <Card classes={ classes.subGrid } style={ { height: '100%' } }>
          <Container style={ { margin: '1rem 0' } }>
            <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
              {t(translationKey.TitlePointPerson)}
            </Typography>
            <Grid container style={ { margin: '1rem 0' } }>
              <Item title={t(translationKey.LabelName)}>{ getChain(organisation, 'point_person', 'name') }</Item>
              <Item title={t(translationKey.LabelEmail)}>{ getChain(organisation, 'point_person', 'email') }</Item>
              <Item title={t(translationKey.LabelBuyerName)}>{ getChain(organisation, 'point_person', 'phone') }</Item>
            </Grid>
          </Container>
        </Card>
      </Grid>
    </Grid>
  )
}

function Item({ title, children }) {
  const classes = useStyles();

  return (
    <Grid item xs={ 12 } style={ { marginTop: '0.5rem' } }>
      <Typography className={ classes.subTitle }><strong>{ title }</strong></Typography>
      <Typography className={ classes.content }>{ children }</Typography>
    </Grid>
  )
}

export function BorderContainer({ children }) {

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } style={ { margin: '1rem 0' } }>
        { children }
      </Grid>
    </Grid>
  )
}
