import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../themes';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Face from '@material-ui/icons/FaceOutlined';
import Email from '@material-ui/icons/EmailOutlined';
import GameAsset from '@material-ui/icons/VideogameAssetOutlined';
import Timer from '@material-ui/icons/Timer';
import Cup from '@material-ui/icons/EmojiEventsOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTable from 'material-table';
import { activeColor, dropdownTextColor, greyBorderColor, listBackgroundColor, numberTextColor } from '../../Styles';
import { axios, CancelToken } from '../../services/networkRequest';
import { identity, subtractMonth } from '../../services/helpers';
import { months } from '../../constants';
import DailyYoungPeople from '../DailyYoungPeople';
import MonthlyYoungPeople from '../MonthlyYoungPeople';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const useStyles = makeStyles({
  noWrapText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  select: {
    color: dropdownTextColor,
    border: `1px solid ${ dropdownTextColor }`,
    fontSize: '18px',
    padding: '2px 10px',
    minWidth: '200px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: `1px solid ${ dropdownTextColor }`,
      outlineStyle: 'none',
    },
  },
  centre: {
    margin: '40px auto',
  },
});

function PPDashboardReportLN() {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  // Date is stringified because the Select component only tests reference equality on objects.
  const [timePeriod, setTimePeriod] = useState(JSON.stringify({ month, year }));
  const [report, setReport] = useState();
  const [entities, setEntities] = useState(undefined);
  const [distributors, setDistributors] = useState(undefined);
  const [testAccounts, setTestAccounts] = useState(undefined);

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });

    let url = '/ln/ppreport';
    if (timePeriod) {
      const timePeriodObj = JSON.parse(timePeriod);
      if (timePeriodObj.month) {
        url += `?month=${timePeriodObj.month}`;
        if (timePeriodObj.year) {
          url += `&year=${timePeriodObj.year}`;
        }
      } else if (timePeriodObj.year) {
        url += `?year=${timePeriodObj.year}`;
      }
    }

    axios.get(url, {
      cancelToken,
    }).then(({ data }) => {
      setReport(data);
      setEntities(data.entities.filter(e => e.deleted_at === null));
      setDistributors(data.distributors.filter(d => d.deleted_at === null));
      setTestAccounts(data.test_accounts);
    }).catch(identity);

    return () => {
      cancel();
    };
  }, [timePeriod]);

  if (!report) {
    return <CircularProgress className={ classes.centre } />;
  }
  // Service start date
  const startDate = new Date(2019, 11, 1);
  const usageIntervals = [];
  let usageIndex = 0;
  for (let date = currentDate; date >= startDate; subtractMonth(date)) {
    const itemYear = date.getFullYear();
    const itemMonth = date.getMonth() + 1;
    if (itemYear === year - 1 && itemMonth === 12 && month === 1) {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
      usageIntervals[usageIndex] = { year: itemYear };
      usageIndex++;
    } else if (itemYear < year) {
      if (usageIndex > 0 && usageIntervals[usageIndex - 1].year !== itemYear) {
        usageIntervals[usageIndex] = { year: itemYear };
        usageIndex++;
      }
    } else {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
    }
  }
  const usageIsMonthly = !JSON.parse(timePeriod).month;

  return (
    <div style={{ marginTop: "50px" }}>
      <Grid container>
        <Grid item xs={ 10 } align="left">
          <Typography color="textPrimary" component="span"
                      style={ { fontSize: '20px', fontWeight: 600, marginRight: '10px' } }>{t(translationKey.TitleOverviewForYoungPeople)}</Typography>
          <Select
            value={ timePeriod }
            onChange={ event => {
              setTimePeriod(event.target.value);
              setReport(null);
            } }
            variant='outlined'
            classes={ { root: classes.select } }
          >
            { usageIntervals.map((item, index) => {
              let itemString;
              if (index === 0) {
                itemString = t(translationKey.LabelCurrentMonth, { month: months[item.month - 1] });
              } else if (index === 1) {
                itemString = t(translationKey.LabelPreviousMonth, { month: months[item.month - 1] });
              } else if (item.month) {
                itemString = months[item.month - 1];
              } else {
                itemString = item.year.toString();
              }
              return (<MenuItem key={ index } value={ JSON.stringify({
                month: item.month,
                year: item.year,
              }) }>{ itemString }</MenuItem>);
            }) }
          </Select>
        </Grid>
        <Grid item xs={ 2 } align="right">
          <Button variant="contained" color="primary" onClick={ () => window.print() }>
            {t(translationKey.ButtonDownloadReport)}
          </Button>
        </Grid>
      </Grid>
      <div
        style={ { display: 'flex', justifyContent: 'space-between', width: 'inherit', margin: 0, paddingTop: '30px' } }>
        <div style={ { flex: 1, maxWidth: '19%' } }>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={ { root: classes.noWrapText } }>{t(translationKey.LabelYoungPeopleWhoPlayed)}</Typography>
              <Grid container style={ { marginTop: '16px' } }>
                <Grid item xs={ 10 }><Typography
                  style={ { fontSize: '30px', color: numberTextColor } }
                  align="left">{ report.young_people_played }</Typography></Grid>
                <Grid item xs={ 2 }><Face style={ { fontSize: '40px' } } /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "19%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.LabelInvitesSent)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10}><Typography
                  style={{ fontSize: '30px', color: numberTextColor }} align="left">{report.invites_sent}</Typography></Grid>
                <Grid item xs={2}><Email style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "19%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.TitleTotalGameSessions)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10}><Typography
                  style={{ fontSize: '30px', color: numberTextColor }} align="left">{report.total_game_sessions}</Typography></Grid>
                <Grid item xs={2}><GameAsset style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "19%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.LabelAverageSessionTime)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10} align="left">
                  <Typography style={{ fontSize: '30px', color: numberTextColor, marginRight: '2px' }}
                    component='span' align="left">{Math.round(report.average_time_per_session / 60)}</Typography>
                  <Typography color="textPrimary" component='span' align="left">mins</Typography>
                </Grid>
                <Grid item xs={2}><Timer style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, maxWidth: "19%" }}>
          <Card style={{ border: `1px solid ${greyBorderColor}` }}>
            <CardContent>
              <Typography color="textPrimary" align="left" classes={{ root: classes.noWrapText }}>{t(translationKey.LabelChallengesCompleted)}</Typography>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item xs={10}><Typography
                  style={{ fontSize: '30px', color: numberTextColor }} align="left">{report.challenges_completed}</Typography></Grid>
                <Grid item xs={2}><Cup style={{ fontSize: '40px' }} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
      <Card style={{ border: `1px solid ${greyBorderColor}`, marginTop: "30px" }}>
        <CardContent>
          <Typography color="textPrimary" align="left">{t(translationKey.LabelNumberOfYoungPeoplePlaying)}</Typography>
          {usageIsMonthly ? <MonthlyYoungPeople data={report.graph}/> : <DailyYoungPeople data={report.graph}/>}
        </CardContent>
      </Card>
      <Card style={{ border: `1px solid ${greyBorderColor}`, marginTop: "30px" }}>
      <CardContent>
      <Typography color="textPrimary" align="left" style={ { fontSize: '20px', fontWeight: 600} }>{t(translationKey.TitleBreakdownByEntity)}</Typography>
      <ThemeProvider theme={ tableThemer(entities) }>
        <MaterialTable
          isLoading={ !entities }
          columns={ [
            {
              title: t(translationKey.TitleTableEntity),
              field: 'entity_name',
              cellStyle: {
                borderLeft: `1px solid ${ greyBorderColor }`,
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textPrimary">{ rowData.name }</Typography>,
            },
            {
              title: t(translationKey.TitleTableLicenses),
              field: 'licenses',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.ln_assigned_licenses }</Typography>,
            },
            {
              title: t(translationKey.TitleTableDistributors),
              field: 'distributors',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.distributors }</Typography>,
            },
            {
              title: t(translationKey.TitleTableInvitesSent),
              field: 'invites',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.invites_sent }</Typography>,
            },
            {
              title: t(translationKey.TitleTableStartedPlaying),
              field: 'playing',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.playing }</Typography>,
            },
            {
              title: t(translationKey.TitleTableDeactivated),
              field: 'deactivated',
              cellStyle: {
                borderRight: `1px solid ${ greyBorderColor }`,
              },
              render: rowData => <Typography color="textSecondary">{ rowData.deactivated }</Typography>,
            },
          ] }
          data={ entities }
          options={ {
            paging: false,
            search: false,
            sorting: false,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          style={ { backgroundColor: listBackgroundColor } }
        />
      </ThemeProvider>
      </CardContent>
      </Card>
      <Card style={{ border: `1px solid ${greyBorderColor}`, marginTop: "30px" }}>
      <CardContent>
      <Typography color="textPrimary" align="left" style={ { fontSize: '20px', fontWeight: 600} }>{t(translationKey.TitleBreakdownByDistributor)}</Typography>
      <ThemeProvider theme={ tableThemer(distributors) }>
        <MaterialTable
          isLoading={ !distributors }
          columns={ [
            {
              title: t(translationKey.TitleTableDistributors),
              field: 'distributor_name',
              cellStyle: {
                borderLeft: `20px solid ${ activeColor }`,
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textPrimary">{ rowData.distributor_name }</Typography>,
            },
            {
              title: t(translationKey.TitleTableEntity),
              field: 'entity_name',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.entity_name }</Typography>,
            },
            {
              title: t(translationKey.TitleTableLastLoggedIn),
              field: 'last_login',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.last_login && new Date(rowData.last_login).toDateString() }</Typography>,
            },
            {
              title: t(translationKey.TitleTableInvitesSent),
              field: 'invites',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.invites_sent }</Typography>,
            },
            {
              title: t(translationKey.TitleTableStartedPlaying),
              field: 'playing',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.young_people_played }</Typography>,
            },
            {
              title: t(translationKey.TitleTableDeactivated),
              field: 'deactivated',
              cellStyle: {
                borderRight: `1px solid ${ greyBorderColor }`,
              },
              render: rowData => <Typography color="textSecondary">{ rowData.deactivated }</Typography>,
            },
          ] }
          data={ distributors }
          options={ {
            paging: false,
            search: false,
            sorting: false,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          style={ { backgroundColor: listBackgroundColor } }
        />
      </ThemeProvider>
      </CardContent>
      </Card>

      <Card style={{ border: `1px solid ${greyBorderColor}`, marginTop: "30px" }}>
      <CardContent>
      <Typography color="textPrimary" align="left" style={ { fontSize: '20px', fontWeight: 600} }>{t(translationKey.TitleTestAccountsInUse)}</Typography>
      <ThemeProvider theme={ tableThemer(testAccounts) }>
        <MaterialTable
          isLoading={ !testAccounts }
          columns={ [
            {
              title: t(translationKey.TitleTableYoungPersonName),
              field: 'young_person_name',
              cellStyle: {
                borderLeft: `1px solid ${ greyBorderColor }`,
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
            },
            {
              title: t(translationKey.TitleTableDistributors),
              field: 'distributor_name',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.distributor_name }</Typography>,
            },
            {
              title: t(translationKey.TitleTableEntity),
              field: 'entity_name',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.entity_name }</Typography>,
            },
            {
              title: t(translationKey.TitleTableInvitedDate),
              field: 'invited_date',
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.invited_date && new Date(rowData.invited_date).toDateString() }</Typography>,
            },
            {
              title: t(translationKey.TitleTableStartedPlayingDate),
              field: 'first_play',
              cellStyle: {
                borderRight: `1px solid ${ greyBorderColor }`,
              },
              render: rowData => <Typography
                color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
            }
          ] }
          data={ testAccounts }
          options={ {
            paging: false,
            search: false,
            sorting: false,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          style={ { backgroundColor: listBackgroundColor } }
        />
      </ThemeProvider>
      </CardContent>
      </Card>


    </div>
  );
}

export default PPDashboardReportLN;
