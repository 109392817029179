import React from 'react';
import { Button, Card, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteDistributorAlert } from '../../services/helpers';
import { withTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

class DistributorDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      distributor: this.props.location.state.distributor,
      allDistributors: this.props.location.state.allDistributors
    };
    this.editDistributor = this.editDistributor.bind(this);
    this.deleteDistributor = this.deleteDistributor.bind(this);
  }

  deleteDistributor() {
    let { name, id, young_persons } = this.state.distributor;
    if (typeof (id) === 'string') {
      id = id.startsWith('d') ? id.substring(1) : id;
    }

    const sorted = young_persons.sorted;
    const activeYps = sorted.active.length;
    const notStartedYps = sorted.not_yet_active.length;
    const inactiveYps = sorted.inactive.length;

    deleteDistributorAlert(id, name, `/distributor/${ id }`, this.props.history.goBack(), activeYps, notStartedYps, inactiveYps);
  }

  editDistributor() {
    this.props.history.push(`/distributor/${ this.state.distributor.id }/edit`, { distributor: this.state.distributor });
  }

  render() {
    const { distributor } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Button onClick={ () => this.editDistributor() }>
          <EditIcon />
        </Button>
        <Button onClick={ () => this.deleteDistributor() }>
          <DeleteIcon />
        </Button>
        <Card>
          <Typography><strong>{t(translationKey.TitleDistributor)}</strong></Typography>
          <Typography><strong>{t(translationKey.LabelColonName)}</strong> { distributor.name }</Typography>
          <Typography><strong>{t(translationKey.LabelColonAddress)}</strong> { distributor.address }</Typography>
          <Typography><strong>{t(translationKey.LabelColonEmail)}</strong> { distributor.email }</Typography>
          <Typography><strong>{t(translationKey.LabelColonPhone)}</strong> { distributor.phone }</Typography>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(DistributorDetailsPage);
