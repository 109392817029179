import React from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { axios, catchAxios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { FormControl, FormGroup } from '@material-ui/core';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

function DistributorsInvite(props) {
  const { org_id, ent_id } = props.match.params;
  const { t } = useTranslation();

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography component="h1" variant="h5">
          {t(translationKey.TitleInviteDistributor)}
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ { email: '', first_name: '', last_name: '', organisation_id: org_id, entity_id: ent_id } }
          onSubmit={ (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            axios.post('/invite/distributor', values).then(() => {
              props.history.goBack();
            })
              .catch(catchAxios(setFieldError))
              .finally(() => setSubmitting(false));
          } }
        >
          { ({
               errors,
               values,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
             }) => (
            <form onSubmit={ handleSubmit }>
              <FormControl>
                <FormGroup>
                  <Error message={ errors['network'] } />
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    type="email"
                    name="email"
                    id="email"
                    value={ values.email }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    label={t(translationKey.LabelDistributorEmail)}
                    required
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type="first_name"
                    name="first_name"
                    id="first_name"
                    value={ values.first_name }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    label={t(translationKey.LabelDistributorFirstName)}
                    required
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type="last_name"
                    name="last_name"
                    id="last_name"
                    value={ values.last_name }
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    label={t(translationKey.LabelDistributorLastName)}
                    required
                  />
                </FormGroup>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={ isSubmitting }
                  type='submit'>
                  {t(translationKey.ButtonSubmit)}
                </Button>
              </FormControl>
            </form>
          ) }
        </Formik>
      </Container>
    );
}

export default DistributorsInvite;
