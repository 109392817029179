import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryLine, VictoryLabel, VictoryLegend, VictoryChart } from 'victory';
import { withSize } from 'react-sizeme';
import { CircularProgress } from '@material-ui/core';
import { orange, numberTextColor as blue, red, darkgreen as green } from '../Styles';
import { getChain, tickSteps } from '../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 350,
  },
  centre: {
    margin: 'auto',
  },
  axis: {
    color: 'red'
  }
}));

const CotsPerformanceChart = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!props.data) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  const adder = (acc, item, property) => {
    let value = getChain(item, property);
    if (value > 0) {
      acc.push(value);
    }
  };
  const reducedPerformanceValues = props.data.reduce((acc, item) => {
    for(let property of ['performance_median', 'performance_median_mean', 'performance_mean', 'performance_mean_median']) {
      adder(acc, item, property);
    }

    return acc;
  }, []);
  const xAxisValues = props.data.map(item => item.game);
  const tickValuesPerformance = tickSteps(reducedPerformanceValues, 0, num => parseFloat(num.toFixed(2)));
  return (
    <div className={ classes.root }>
      <VictoryChart
        height={ 340 }
        width={ 1100 }
        domain={{y:[Math.min(...tickValuesPerformance), Math.max(...tickValuesPerformance)]}}
        >
          <VictoryAxis
            label={t(translationKey.LabelDuels)}
            tickValues={ xAxisValues }
            style={{
              grid: { stroke: "#818e99", strokeWidth: 0.5 },
            }}
          />

          {/* Dependent axis for line graphs. */}
          <VictoryAxis dependentAxis
            label={t(translationKey.LabelHrvInMs)}
            axisLabelComponent = {
              <VictoryLabel dy={-10}/>
            }
            tickValues={ tickValuesPerformance }
            orientation='left'
             style={{
            grid: { stroke: "#818e99", strokeWidth: 0.5 },
            }}
          />

          <VictoryLine
            data={ props.data }
            x='game'
            y='performance_median'
            style={ { data: { stroke: blue } } }
          />
          <VictoryLine
            data={ props.data }
            x='game'
            y='performance_median_mean'
            style={ { data: { stroke: red } } }
          />
          <VictoryLine
            data={ props.data }
            x='game'
            y='performance_mean'
            style={ { data: { stroke: green } } }
          />
          <VictoryLine
            data={ props.data }
            x='game'
            y='performance_mean_median'
            style={ { data: { stroke: orange } } }
          />

          <VictoryLegend x={250} y={320}
            gutter={20}
            orientation="horizontal"
            data={[
              { name: t(translationKey.LabelMedianOfMedian), symbol: { fill: blue } },
              { name: t(translationKey.LabelMedianOfMean), symbol: { fill: red } },
              { name: t(translationKey.LabelMeanOfMean), symbol: { fill: green } },
              { name: t(translationKey.LabelMeanOfMedian), symbol: { fill: orange } },
            ]}
          />
        </VictoryChart>
    </div>
  );
};

export default withSize()(CotsPerformanceChart);
