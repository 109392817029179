import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

export default function () {
  const { t } = useTranslation();
  return (
    <footer className="center">
      <div style={ { display: 'inline-flex' } }>
        <img src="/ce-mark.png" alt="CE Mark" style={ { height: '100%', padding: 3 } } />
        <span>
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://bfb-labs.com/vitamind-hub-privacy-policy">{t(translationKey.LabelPrivacyPolicy)}</a>
          &nbsp;|&nbsp;
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://www.bfb-labs.com/vmh-instructions-for-use">{t(translationKey.LabelInstructionsForUse)}</a>
          &nbsp;|&nbsp;
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://bfb-labs.com">BfB Labs © { new Date().getFullYear() }</a>
        </span>
      </div>
    </footer>
  );
}
