import { useReducer } from 'react';
import constate from 'constate';
import { axios } from '../services/networkRequest'; // State Context Object Creator

function loadUser() {
  const userString = localStorage.getItem('user');
  let user = null;
  if (userString) {
    try {
      user = JSON.parse(userString);
      if (user.token) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + user.token;
      } else {
        user = null;
      }
    } catch (error) {
      user = null;
    }
  }
  return user;
}

function useUser() {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'LOGIN': {
        const { user } = action.payload;
        localStorage.setItem('user', JSON.stringify(user));

        return {
          ...state,
          user,
        };
      }
      case 'LOGOUT': {
        localStorage.removeItem('user');
        axios.defaults.headers.common['Authorization'] = null;

        return {
          ...state,
          user: null,
        };
      }
      case 'RETRIEVE_USER': {
        const user = loadUser();

        return {
          ...state,
          user,
        };
      }
      case 'PICK_GAME': {
        const user = state['user'];
        user.currentGame = action.payload.game;
        localStorage.setItem('user', JSON.stringify(user));

        return {
          ...state,
          user,
        };
      }
      default:
        return state;
    }
  }, { user: loadUser() });

  const login = user => dispatch({
    type: 'LOGIN',
    payload: { user },
  });
  const logout = () => dispatch({
    type: 'LOGOUT',
  });
  const retrieveUser = () => dispatch({
    type: 'RETRIEVE_USER',
  });
  const pickGame = game => dispatch({
    type: 'PICK_GAME',
    payload: { game },
  });

  const user = state?.user;

  return { user, login, logout, retrieveUser, pickGame };
}

// Step 2: Declare your context state object to share the state with other components
export const useUserContext = constate(useUser);
