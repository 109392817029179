import React, { useEffect, useState } from 'react';
import { Delete, RestoreFromTrash } from '@material-ui/icons';
import { Button, Container, Link, Tab, Tabs } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../themes';
import { axios } from '../../services/networkRequest';
import { useUserContext } from '../../store/UserContext';
import { deleteAlert, getChain } from '../../services/helpers';
import { activeColor, deactivatedColor, invitedColor, listBackgroundColor } from '../../Styles';

import 'react-confirm-alert/src/react-confirm-alert.css';
import MaterialTable from 'material-table';
import ChevronRight from '@material-ui/core/SvgIcon/SvgIcon';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

function Admins(props) {
  const { user } = useUserContext();
  const [admins, setAdmins] = useState();
  const [invites, setInvites] = useState();
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  function deleteAdmin({ name, id }) {
    deleteAlert(name, `/admin/${ id }`, getAdmins);
  }

  function restoreAdmin({ name, id }) {
    confirmAlert({
      title: (t(translationKey.ConfirmRestoreName, { name: name })),
      message: t(translationKey.AlertIsUserSure),
      buttons: [
        {
          label: t(translationKey.ChoiceLabelYes),
          onClick: () => {
            axios.post(`/admin/restore/${ id }`)
              .then(getAdmins);
          },
        },
        {
          label: t(translationKey.ChoiceLabelNo),
          onClick: () => {
          },
        },
      ],
    });
  }

  function getAdmins() {
    axios.get('/admins')
      .then(({ data }) => setAdmins(data));
  }

  function getAdminInvites() {
    axios.get('/invites/admin')
      .then(({ data }) => setInvites(data));
  }

  function deleteAdminInvite({ name, id }) {
    deleteAlert(name, `/invites/admin/${ id }`, getAdminInvites);
  }

  useEffect(() => {
    getAdmins();
  }, []);
  useEffect(() => {
    getAdminInvites();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Button mb={ 10 } align="right" variant="contained" color="primary"
              onClick={ () => props.history.push('/admin/invite') }>{t(translationKey.ButtonInviteNewUser)}</Button>
      <br />
      <br />
      <Tabs
        value={ tab }
        onChange={ (event, value) => {
          setTab(value);
        } }>
        <Tab label={t(translationKey.TabActive)} value={ 0 } />
        <Tab label={t(translationKey.TabInvited)} value={ 1 } />
        <Tab label={t(translationKey.TabDeleted)} value={ 2 } />
      </Tabs>
      <ThemeProvider theme={
        tableThemer(((i) => [admins && admins.active, invites, admins && admins.deleted][i])(tab))
      }>
        { tab === 0 &&
        <MaterialTable
          isLoading={ !admins }
          columns={ [
            {
              title: '#',
              field: 'id',
              cellStyle: {
                borderLeft: `20px solid ${ activeColor }`,
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleUserName),
              field: 'name',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleUserEmail),
              field: 'email',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleCreatedAt),
              field: 'created_at',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          actions={ [
            rowData => ({
              icon: () => <Delete />,
              onClick: (event, rowData) => deleteAdmin(rowData),
              hidden: rowData.email === getChain(user, 'email'),
            }),
          ] }
          data={ getChain(admins, 'active') }
          options={ {
            actionsColumnIndex: 4,
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
        />
        }
        { tab === 1 &&
        <MaterialTable
          isLoading={ !invites }
          columns={ [
            {
              title: '#',
              field: 'id',
              cellStyle: {
                borderLeft: `20px solid ${ invitedColor }`,
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleUserEmail),
              field: 'email',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleUserUniqueLink),
              cellStyle: {
                borderRight: 'none',
              },
              render: rowData =>
                <Link href={ rowData.unique_link }>
                  { rowData.unique_link }
                </Link>,
            },
            {
              title: t(translationKey.TitleCreatedAt),
              field: 'created_at',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          actions={ [
            () => ({
              icon: () => <Delete />,
              onClick: (event, rowData) => deleteAdminInvite(rowData),
            }),
          ] }
          data={ invites }
          options={ {
            actionsColumnIndex: 4,
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
        />
        }
        { tab === 2 &&
        <MaterialTable
          isLoading={ !admins }
          columns={ [
            {
              title: '#',
              field: 'id',
              cellStyle: {
                borderLeft: `20px solid ${ deactivatedColor }`,
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleUserName),
              field: 'name',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleUserEmail),
              field: t(translationKey.FieldEmail),
              cellStyle: {
                borderRight: 1,
              },
            },
            {
              title: t(translationKey.TitleDeactivatedOn),
              field: 'deleted_at',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          actions={ [
            () => ({
              icon: () => <RestoreFromTrash />,
              onClick: (event, rowData) => restoreAdmin(rowData),
            }),
          ] }
          data={ getChain(admins, 'deleted') }
          options={ {
            actionsColumnIndex: 4,
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
        />
        }
      </ThemeProvider>
    </Container>
  );
}

export default Admins;
