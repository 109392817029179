import React, { useEffect, useState } from 'react';
import { axios, catchAxios } from '../../services/networkRequest';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Error from '../Error';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useViewport } from '../../utilities/viewport';
import TextField from '@material-ui/core/TextField';
import { identity } from '../../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { ModalBackground } from '../ModalBackground';
import { Modal } from '../Modal';
import { useDaysOfWeek } from '../DaysOfWeek';

function WeeklyReportSettings({ history, setShowDialog, distributorId }) {
  const [day, setDay] = useState();
  const [distributor, setDistributor] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    axios.get(`/distributor/${ distributorId }`).then(({ data }) => {
      setDistributor(data);
      // Default to Monday if the user has not specified a day
      const initialDay = data.weekly_activity_report_day ? data.weekly_activity_report_day : t(translationKey.DayMonday);
      setDay(initialDay);
    }).catch(identity);
    // t function causes infinite loop so omit it on this occasion
    // eslint-disable-next-line
  }, [distributorId]);

  return (
    <ModalBackground>
      <Modal close={() => setShowDialog(false)}>
        { distributor && <Container component="main" style={ { padding: '20px', width: '50%' } }>
          <CssBaseline />
          <h2>{ t(translationKey.Settings) } </h2>
          <Formik
            initialValues={ {
              // Default to 10am if the user has not specified a time
              time: (distributor && distributor.weekly_activity_report_time) ? distributor.weekly_activity_report_time : '10:00',
              enableReport: distributor ? distributor.enable_weekly_activity_report : false,
            } }
            onSubmit={ (values, { setSubmitting, setFieldError }) => {
              setSubmitting(true);
              const submitFunction = () => {
                axios['put'](`/distributor/${ distributorId }/report_settings`, { ...values, day })
                  .then(() => {
                    history.go(0);
                  })
                  .catch(err => {
                    catchAxios(setFieldError)(err);
                    setSubmitting(false);
                  });
              };

              submitFunction();
            } }
          >
            { ({
                 errors,
                 values,
                 handleChange,
                 handleSubmit,
               }) => (
              <form onSubmit={ handleSubmit }>
                <Error message={ errors['network'] } />

                <CheckboxFieldWithTitle title={ t(translationKey.DistributorWeeklyEmailSettingsEnableEmail) }
                                        formLabelValue="enableReport"
                                        name="enableReport"
                                        value={ values.enableReport }
                                        checked={ values.enableReport }
                                        onChange={ handleChange }
                />

                { values.enableReport && <div>
                  <DayOfWeekPicker day={ day } setDay={ setDay } />
                  <Typography>{ t(translationKey.DistributorWeeklyEmailSettingsReportTime) }</Typography>
                  <TextField
                    id="time"
                    type="time"
                    value={ values.time }
                    onChange={ handleChange }
                    style={ { marginBottom: '1em' } }
                  />
                </div>
                }

                <Button
                  style={ {
                    marginRight: '1em',
                  } }
                  color="primary"
                  type="submit"
                  variant="contained">{ t(translationKey.ButtonSubmit) }</Button>

                <Button
                  style={ {
                    marginLeft: '1em',
                  } }
                  variant="contained"
                  color="lightgrey"
                  onClick={ () => {
                    setShowDialog(false);
                  }
                  }>{ t(translationKey.ButtonClose) }</Button>
              </form>) }
          </Formik>
        </Container> }
      </Modal>
    </ModalBackground>
  );
}

function CheckboxFieldWithTitle({ leftColumnWidth, rightColumnWidth, title, formLabelValue, ...props }) {
  return (
    <Row>
      <Grid item xs={ leftColumnWidth }>
        <Typography component="h2" align="left" variant="body1">
          { title }
        </Typography>
      </Grid>
      <Grid item xs={ rightColumnWidth }>
        <FormControlLabel
          value={ formLabelValue }
          control={
            <Checkbox
              color="primary"
              inputProps={ { 'aria-label': 'secondary checkbox' } }
              { ...props }
            />
          }
          label="" />
      </Grid>
    </Row>
  );
}

function Row({ children }) {
  const { views: { mobile } } = useViewport();

  return (
    <Grid container direction={ mobile ? 'column' : 'row' }
          justify="space-between" alignItems={ mobile ? '' : 'center' } style={ { margin: '0.5rem 0' } }>
      { children }
    </Grid>
  );
}

function DayOfWeekPicker({ day, setDay }) {
  const { t } = useTranslation();
  const days = useDaysOfWeek();


  return (
    <Grid style={ { marginBottom: '1em' } }>
      <Typography>{ t(translationKey.DistributorWeeklyEmailSettingsReportDay) }</Typography>
      {
        days.map((i) => {
            return (
              <Button
                style={ {
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 60,
                  height: 60,
                  backgroundColor: (day && day === i.day) ? '#2D9BF0' : 'lightgrey',
                  borderRadius: 50,
                  outlineColor: 'red',
                  outlineWidth: 1,
                  marginRight: '0.5em',
                } }
                onClick={ () => {
                  setDay(i.day);
                } }
              >
                <Typography>{ i.label }</Typography>
              </Button>
            );
          },
        )
      }

    </Grid>
  );
}

export default WeeklyReportSettings;
