import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const useStyles = makeStyles(() => ({
  footerBackground: {
    background: '#063494',
    marginTop: '2rem',
    padding: '1rem',
  },
  logo: {
    width: '100%',
    maxWidth: '6rem',
    margin: '1rem 0',
  },
  information: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
  },
}));

export default function SelfSignUpFooter() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container justify="center" className={ classes.footerBackground }>
      <Grid item xs={ 12 }>
        <img src="../../BfB_logo_white_powered_by.png" alt="BfB Logo" className={ classes.logo } />
      </Grid>
      <Grid item xs={ 12 }>
        <div className={ classes.information }>
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://www.bfb-labs.com/lumi-nova-privacy-policy ">{t(translationKey.TitlePrivacyPolicy)}</a>
          &nbsp;|&nbsp;
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://bfb-labs.com">BfB Labs © { new Date().getFullYear() }</a>
        </div>
      </Grid>
    </Grid>
  )
}
