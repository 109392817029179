import React from 'react';
import EntityForm from './EntityForm';
import { getChain } from '../../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';


function EntityEditPage(props){
  const { t } = useTranslation();
  return (
  <EntityForm
    { ...props }
    path={ `/entity/${ getChain(props.location.state.entity, 'id') }` }
    entity={ props.location.state.entity }
    title={t(translationKey.TitleEditEntity, { name: getChain(props.location.state.entity, 'name')})}
    organisation={ props.location.state.organisation }
  />);
}
export default EntityEditPage;
