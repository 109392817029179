import React from 'react';
import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { axios } from '../../services/networkRequest';
import ChevronRight from '@material-ui/core/SvgIcon/SvgIcon';
import MaterialTable from 'material-table';
import { ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../themes';
import { Delete } from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import { deleteAlert, getChain, printDateString } from '../../services/helpers';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { activeColor, listBackgroundColor } from '../../Styles';
import { withTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

class Organisations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organisations: [],
    };
    this.deleteOrganisation = this.deleteOrganisation.bind(this);
    this.handleOrganisationReq = this.handleOrganisationReq.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    axios.get('organisations')
      .then(orgs => this.setState({ organisations: orgs.data }));
  }

  componentDidMount() {
    this.loadData();
  }

  handleOrganisationReq({ id }) {
    axios.get(`/organisation/${ id }`)
      .then(res => {
        this.props.history.push(`/organisation/${ id }`, { organisation: res.data });
      });
  }

  deleteOrganisation(org) {
    deleteAlert(org.name, `/organisation/${ org.id }`, () => {
      this.props.history.push('/organisations');
    });
  }

  render() {
    const { t } = this.props;
    const { organisations } = this.state;
    return (
      <Container component="main" maxWidth="lg">
        <Button mb={ 10 } align="right" variant="contained" color="primary"
                onClick={ () => this.props.history.push('/organisation/create') }>{t(translationKey.ButtonCreateOrganisation)}</Button>
        <br />
        <br />
        <ThemeProvider theme={ tableThemer(organisations) }>
          <MaterialTable
            isLoading={ !organisations }
            columns={ [
              {
                title: '#',
                field: 'id',
                cellStyle: {
                  borderLeft: `20px solid ${ activeColor }`,
                  borderRight: 'none',
                },
              },
              {
                title: t(translationKey.LabelOrganisationName),
                field: 'name',
                cellStyle: {
                  borderRight: 'none',
                },
              },
              {
                title: t(translationKey.TitlePointPersonName),
                field: 'point_person.name',
                cellStyle: {
                  borderRight: 'none',
                },
              },
              {
                title: t(translationKey.TitlePointPersonEmail),
                field: 'point_person.email',
                cellStyle: {
                  borderRight: 'none',
                },
                render: item => <Link
                  href={ `mailto://${ getChain(item, 'point_person', 'email') }` }>{ getChain(item, 'point_person', 'email') }</Link>,
              },
              {
                title: t(translationKey.TitleCreatedAt),
                field: 'created_at',
                cellStyle: {
                  borderRight: 'none',
                },
              },
              {
                title: t(translationKey.TitleValidUntil),
                field: 'expiration_date',
                cellStyle: {
                  borderRight: 'none',
                },
                render: item => printDateString(getChain(item, 'expiration_date')),
              },
            ] }
            actions={ [
              {
                icon: () => <Delete />,
                onClick: (event, rowData) => this.deleteOrganisation(rowData),
              },
            ] }
            data={ organisations }
            onRowClick={ (event, rowData) => this.handleOrganisationReq(rowData) }
            options={ {
              actionsColumnIndex: 6,
              detailPanelColumnAlignment: 'right',
              detailPanelType: 'single',
              paging: false,
              search: true,
              draggable: false,
              sorting: true,
              showTitle: false,
              toolbar: false,
              headerStyle: {
                backgroundColor: listBackgroundColor,
              },
            } }
            icons={ { DetailPanel: ChevronRight } }
            style={ { backgroundColor: listBackgroundColor } }
          />
        </ThemeProvider>
      </Container>
    );
  }
}

export default withTranslation()(Organisations);
