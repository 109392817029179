import { useState, useEffect } from 'react';
import { defaultActivityState } from './ActivityContext';

export default function useActivityContextState() {

  const [activityState, setActivityState] = useState(defaultActivityState);

  useEffect(() => {
    setActivityState(a => ({
      ...a,
      startActivity: (activity) => {
        setActivityState(a2 => ({
            ...a2,
            activities: { [activity]: true, ...a2.activities },
          }),
        );
      },
      completeActivity: (activity) => {
        setActivityState(a2 => {
            delete a2.activities[activity];

            return {
              ...a2,
            };
          },
        );
      },
    }));
  }, []);

  return activityState;
}