import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';

export const Modal = ({ children, close }) => (
  <div style={ {
    position: 'absolute',
    width: '60%',
    height: '60%',
    left: '20%',
    right: '20%',
    zIndex: '100',
    bottom: '20%',
    top: '20%',
  } }>
    <Card>
      <Grid item xs={ 1 }>
        <IconButton align="right" aria-label="close" onClick={ close }>
          <CloseIcon align="right" />
        </IconButton>
      </Grid>
      { children }
    </Card>
  </div>

);
