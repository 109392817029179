import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryLine, VictoryScatter, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { Button, CircularProgress, Input, Typography } from '@material-ui/core';
import { barColor } from '../../Styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { axios } from '../../services/networkRequest';
import { genericAlert } from '../../services/helpers';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import { confirmAlert } from 'react-confirm-alert';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 230,
    width: 600,
    paddingBottom: 200,
    marginBottom: 20
  },
  centre: {
    margin: 'auto',
  },
  key: {
    marginTop: '64px',
    border: '1px solid black',
    width: '30%',
    padding: 5
  },
  dot: {
    height: "10px",
    width: "10px",
    backgroundColor: barColor,
    borderRadius: "50%",
    marginLeft: '24px',
    marginTop: '4px'
  }
}));

const CORSSurveyResults = (props) => {
  const classes = useStyles();
  const [isCopied, setIsCopied] = useState(false);

  const { t } = useTranslation();
  if (!props.results) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  const chartData = props.results.map((item, index) => {
  const date = new Date(item.survey_date);
  const dateString = date.toDateString();
  const parts = dateString.split(' ');

  const axisLabel = parts[2] + ' ' + parts[1];
  return {
      day: index + 1,
      axisLabel,
      total: Number(item.total)
    }
  });

  const dayValues = chartData.map(item => item.day);
  const labels = chartData.map(item => item.axisLabel);
  let tickValues = [0, 5, 10, 15, 20, 25, 30, 35, 40];

  const { smsData, youngPersonId, guardianFirstName } = props
  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    return await navigator.clipboard.writeText(text);
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(smsData.survey_link)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className={ classes.root }>
    <Grid style={{ width: '100%' }}>
      <VictoryChart
        height={ 270 }
        width={ props.size.width }
        domainPadding={ 16 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ dayValues }
                     tickFormat={ labels }
                     label={t(translationKey.LabelDate)}
                     style={ { axisLabel: { padding: 35 }, grid: { stroke: 'none' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={tickValues}
                     label={t(translationKey.TitleUnnamedCorsResults)}
                     style={ { axisLabel: { padding: 40 } } }
        />

        <VictoryLine
            y={() => 28}
            style={ { data : {
                strokeDasharray: "5,5",
                stroke: 'limegreen'
            } } }
        />

        <VictoryLine
          tickValues={ dayValues }
          style={ { data: { stroke: barColor } } }
          data={ chartData }
          x={t(translationKey.LabelDay)}
          y={t(translationKey.LabelTotal)}
        />
        <VictoryScatter
          tickValues={ dayValues }
          style={ { data: { fill: barColor } } }
          data={ chartData }
          x={t(translationKey.LabelDay)}
          y={t(translationKey.LabelTotal)}
        />
      </VictoryChart>
      <Grid container >
        <Grid item xs={5}>
          <div className={classes.key}>
            <Typography align="left" style={{fontWeight: 600, fontSize: '12px', marginLeft: '10px', textTransform: 'uppercase'}}>{t(translationKey.TitleKey)}</Typography>
            <Grid container direction={'row'} style={{ marginBottom: '12px' }}>
              <Grid item xs={4}>
                <div style={{ color: 'limegreen', fontWeight: 600 }}>- - -</div>
              </Grid>
              <Grid item xs={8}>
                <Typography align="left" style={{ fontSize: '12px' }}>{t(translationKey.TitleCutOffForCarerCorsReport)}</Typography>
              </Grid>
            </Grid>
            <Grid container direction={'row'}>
              <Grid item xs={4}>
                <div className={classes.dot}></div>
              </Grid>
              <Grid item xs={8}>
                <Typography align="left" style={{ fontSize: '12px', textTransform: 'uppercase' }}>{t(translationKey.TitleCors)}</Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={7} container direction='column' align="right" style={{marginTop: "64px"}}>
          { smsData &&
            <>
              <Typography item align="right">{t(translationKey.LabelSMSDataNextSMS, {next_sms_date: smsData.next_scheduled ? smsData.next_scheduled : t(translationKey.LabelSMSNoneScheduled) })}</Typography>
              <Typography item align="right">{t(translationKey.LabelSMSDataPreviousSMS, {previous_sms_date:  smsData.most_recent ? smsData.most_recent : t(translationKey.LabelSMSNoneSent) })}</Typography>
              { youngPersonId && <Button
                style={ { width: 'fit-content', marginLeft: 'auto', marginTop: '1em', marginBottom: '1em' } }
                item
                align="right"
                variant="contained"
                color="primary"
                className={ classes.showHideButton }
                onClick={ () => {
                  genericAlert({
                    title: t(translationKey.LabelSMSDataResendCORSSMS),
                    message:  t(translationKey.LabelSMSDataResendCORSSMSMessage, {guardian_name: guardianFirstName}),
                    positiveTitle: t(translationKey.Confirm),
                    positiveCallback: () => axios.get(`/ln/resend_cors/${ youngPersonId }`).then(() => {
                      confirmAlert({
                        message: t(translationKey.LabelSMSDataResendCORSSMSConfirmation),
                        buttons: [
                          {
                            label: t(translationKey.LabelOk)
                          },
                        ],
                      });
                    }),
                    negativeTitle: t(translationKey.LabelCancel),
                  });
                } }
              >
                {t(translationKey.LabelSMSDataResendCORSSMS)}
              </Button> }
              <Grid item>
                <Typography style={{display: 'inline', fontWeight: 'bold'}}>{t(translationKey.LabelSMSDataCORSURL)}</Typography>
                {/* Read only input to hold the young persons CORS survey URL */}
                <Input
                  style={{padding: '.5em',
                    backgroundColor: '#E6E6E6',
                    borderRadius: '.5em',
                    fontSize: '15px',
                    marginRight: '.5em'
                  }}
                  type="text"
                  value={smsData.survey_link}
                  readOnly />
                <Button
                  onClick={handleCopyClick}
                  style={{
                    backgroundColor: '#E7EBF2',
                    borderRadius:'.5em',
                    width: '8em',
                    height: '2.5em',
                    padding: '1.75em'
                  }}
                >
                  {!isCopied && <ContentCopyIcon style={{verticalAlign: 'middle', marginRight: '.5em'}} /> }
                  <span style={{fontSize: '15px', verticalAlign: 'middle'}}>{isCopied ? t(translationKey.LabelSMSDataURLCopied) : t(translationKey.LabelSMSDataURLCopy)}</span>
                </Button>
              </Grid>
            </>
          }
        </Grid>
      </Grid>

      </Grid>

    </div>
  );
};

export default withSize()(CORSSurveyResults);
