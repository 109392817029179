import React from 'react';
import OrganisationForm from './OrganisationForm';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';


function OrganisationsCreatePage(props) {
  const { t } = useTranslation();
  return <OrganisationForm
    { ...props }
    path='/organisation'
    title={ t(translationKey.TitleCreateAnOrganisation) }
  />;
}

export default OrganisationsCreatePage;
