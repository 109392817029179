import React from 'react';
import OrganisationForm from './OrganisationForm';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useTranslation } from 'react-i18next';

const OrganisationEditPage = props => {
    const { t } = useTranslation();
    return (<OrganisationForm
        { ...props }
        path={ `/organisation/${ props.location.state.organisation.id }` }
        organisation={ props.location.state.organisation }
        title={ t(translationKey.TitleEditOrganisationName, { name: props.location.state.organisation.name }) }
      />
    );
};
export default OrganisationEditPage;
