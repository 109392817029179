import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loadingSpinnerContainer: {
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    height: '100vh',
    background: '#fff',
  },
}));

export function LoadingSpinnerFullScreen() {
  const classes = useStyles();

  return (
    <Grid className={ classes.loadingSpinnerContainer }>
      <CircularProgress />
    </Grid>
  )
}