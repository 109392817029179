import * as React from "react";
import {Typography} from "@material-ui/core";
import {
    numberTextColor,
} from '../../Styles';
import {TypographyProps} from "@material-ui/core/Typography/Typography";
import {Trans} from "react-i18next";
import { translationKey } from '../../utilities/localisation/translationKeys';

export const TypographyNumberSpan : React.ComponentType<TypographyProps> = ({ children, style, ...props }) =>
    <Typography {...props} style={{ ...style, fontSize: '30px', color: numberTextColor }} component='span'>
        {children}
    </Typography>;

export const TypographyAverageTime = ({average}) =>
    <Trans
        i18nKey={translationKey.ComponentGameTimeAverage}
        components={[
            <TypographyNumberSpan style={{marginRight: '2px'}} />,
            <Typography color="textPrimary" component='span' />
        ]}
        values={{minutes: average}}
    />;

export const TypographyHoursAndMinutes = ({totalHours, totalMinutes}) =>
    <Trans
        i18nKey={translationKey.HoursAndMinutesComponent}
        components={[
            <TypographyNumberSpan style={{marginRight: '2px'}} />,
            <Typography color="textPrimary" style={{marginRight: '8px'}} component='span' />
        ]}
        values={{hours: totalHours, minutes: totalMinutes}}
    />
