import React from 'react';
import YoungPersonForm from './YoungPersonForm';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';


const CreateYoungPersonPage = (props) =>{
  const { t } = useTranslation();
  return(
    (<YoungPersonForm
    title={t(translationKey.LabelCreateYoungProfile)}
    path='/cots/invite/young_person'
    { ...props }
  />)
)
}


export default CreateYoungPersonPage;
