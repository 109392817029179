import React from 'react';
import { Container } from '@material-ui/core';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Organisations from '../Organisations/Organisations';
import OrganisationsDetailsPage from '../Organisations/Details';
import OrganisationsCreatePage from '../Organisations/Create';
import LoginPage from '../Auth/Login';
import ForgotPassword from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import AdminsInvite from '../Admins/Invite';
import DistributorsInvite from '../Distributors/Invite';
import MenuAppBar from './Navbar';
import InvitationCreationPage from '../Invitation/Create';
import LnYoungPeoplePage from '../Child/LN/YoungPeople';
import LnEditYoungPerson from '../Child/LN/EditYoungPerson';
import LnCreateYoungPerson from '../Child/LN/CreateYoungPerson';
import LnSelfSignUpYoungPerson from '../Child/LN/SelfSignUp';
import LnConfirmationScreen from '../Child/LN/ConfirmationScreen';
import CotsConfirmationScreen from '../Child/COTS/ConfirmationScreen';
import CotsYoungPeoplePage from '../Child/COTS/YoungPeople';
import CotsCreateYoungPerson from '../Child/COTS/CreateYoungPerson';
import CotsEditYoungPerson from '../Child/COTS/EditYoungPerson';
import OrganisationEditPage from '../Organisations/Edit';
import { useUserContext } from '../../store/UserContext';
import { getChain, ROLES } from '../../services/helpers';
import Admins from '../Admins/Admins';
import EntityDetailsPage from '../Entities/Details';
import EntityCreatePage from '../Entities/Create';
import DistributorDetailsPage from '../Distributors/Details';
import DistributorEditPage from '../Distributors/Edit';
import DistributorsCreatePage from '../Distributors/Create';
import EntityEditPage from '../Entities/Edit';
import EntitySettingsPage from '../Entities/Settings';
import FAQs from '../FAQs/FAQs';
import CotsFAQs from '../FAQs/CotsFAQs';
import GuardianSurvey from '../Distributors/GuardianSurvey';
import { loginPath, maintenancePath } from '../../services/networkRequest';
import AggregatedData from '../AggregatedData/AggregatedData';
import Resources from '../Resources/Resources';
import Dashboard from '../Dashboard';
import CotsDashboard from '../CotsDashboard';
import MaintenancePage from '../MaintenancePage';
import YoungPersonReport from '../Child/LN/YoungPersonReport';
import GameChoice from '../Distributors/GameChoice';
import PPDashboardLN from '../PointPerson/PPDashboardLN';
import MonthlyReports from '../PointPerson/MonthlyReports';
import { CotsYoungPersonReport } from '../Child/COTS/YoungPersonReport';
import { Games } from '../../constants';
import SuccessPage from '../SuccessPage';
import OrganisationSettings from '../Organisations/OrganisationSettings';
import MHSDSAggregatedData from '../MHSDSAggregatedData';
import PostCodeValidation from '../PostcodeValidation';

const LoginRedirect = () => <Redirect to={ loginPath } />;

function PrivateRoute({ component: Component, ...props }) {
  const { user } = useUserContext();

  const active = props.role && user && props.role === user.role;
  return (<Route { ...props } component={ active ? Component : LoginRedirect } />);
}

function Menu({ user }) {
  return (user && <MenuAppBar name={ user.name } role={ user.role } version={ user.version } games={ user.games } currentGame={ user.currentGame } />) || null;
}

function HomeRoute({ components, user, ...props }) {
  const role = (user && user.role);
  const Component = components[role] || props.default;

  return (<Route { ...props } component={ Component } />);
}

function GamePicker({ components, user, ...props }) {
  const game = (user && user.currentGame);
  const Component = components[game] || components.default;

  return (<Route { ...props } currentGame={ user.currentGame } component={ Component } />);
}

function DistributorRedirector(props) {
    return <GamePicker {...props} components={ {
      ln: LnYoungPeoplePage,
      cots: CotsYoungPeoplePage,
      default: GameChoice,
    } } />
}


export default () => {
  const { user } = useUserContext();

  const matchLNReport = useRouteMatch('/ln/young_person/:id/report');
  const matchCotsReport = useRouteMatch('/cots/young_person/:id/report')
  const matchGameChoice = useRouteMatch('/game_choice');
  const selfSignUp = useRouteMatch('/signup');

  return (
    <Container maxWidth="xl">
      { (!getChain(matchLNReport, 'isExact') && !getChain(matchGameChoice, 'isExact') && !getChain(matchCotsReport, 'isExact')) && !selfSignUp && <Menu user={user} /> }
      <Switch>
        <PrivateRoute role={ ROLES.admin } exact path="/organisations" component={ Organisations } />
        <HomeRoute role={ ROLES.admin } exact path="/"
                   components={ {
                     admin: Organisations, point_person: PPDashboardLN, distributor: () => <DistributorRedirector user={user} />,
                   } }
                   user={user}
                   default={ LoginRedirect } />
        <PrivateRoute role={ ROLES.admin } exact path="/organisation/create" component={ OrganisationsCreatePage } />
        <PrivateRoute role={ ROLES.admin } exact path="/organisation/:org_id" component={ OrganisationsDetailsPage } />
        <PrivateRoute role={ ROLES.admin } exact path="/organisation/:org_id/edit" component={ OrganisationEditPage } />
        <PrivateRoute role={ROLES.admin} exact path="/organisation/:org_id/settings" component={OrganisationSettings} />
        <PrivateRoute role={ ROLES.admin } exact path="/organisation/:org_id/entity/create"
                      component={ EntityCreatePage } />
        <PrivateRoute role={ ROLES.admin } exact path="/organisation/:org_id/entity/:ent_id"
                      component={ EntityDetailsPage } />
        <PrivateRoute role={ ROLES.admin } exact path="/organisation/:org_id/entity/:ent_id/edit"
                      component={ EntityEditPage } />
        <PrivateRoute role={ ROLES.admin } exact path="/organisation/:org_id/entity/:ent_id/settings"
                      component={ EntitySettingsPage } />
        <PrivateRoute role={ ROLES.admin } exact path="/organisation/:org_id/entity/:ent_id/distributor/invite"
                      component={ DistributorsInvite } />
        <PrivateRoute role={ ROLES.admin } exact path="/distributor/create" component={ DistributorsCreatePage } />
        <PrivateRoute role={ ROLES.admin } exact path="/distributor/invite" component={ DistributorsInvite } />
        <PrivateRoute role={ ROLES.admin } exact path="/distributor/:id" component={ DistributorDetailsPage } />
        <PrivateRoute role={ ROLES.admin } exact path="/distributor/:id/edit" component={ DistributorEditPage } />
        <PrivateRoute role={ ROLES.admin } exact path="/admin/invite" component={ AdminsInvite } />
        <PrivateRoute role={ ROLES.admin } exact path="/admins" component={ Admins } />
        <PrivateRoute role={ ROLES.admin } exact path="/aggregated_data" component={ (props) => <AggregatedData {...props} game={Games.LN.shortName} /> } />
        <PrivateRoute role={ ROLES.admin } exact path="/cots/aggregated_data" component={ (props) => <AggregatedData {...props} game={Games.COTS.shortName} /> } />
        <PrivateRoute role={ ROLES.admin } exact path="/mhsds_data" component={ (props) => <MHSDSAggregatedData {...props} role={ROLES.admin}/> } />

        
        <PrivateRoute role={ ROLES.distributor } exact path="/game_choice" component={ GameChoice } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln" component={ LnYoungPeoplePage } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln/dashboard" component={ Dashboard } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln/young_persons" component={ LnYoungPeoplePage } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln/young_person/create"
                      component={ LnCreateYoungPerson } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln/young_person/confirm"
                      component={ LnConfirmationScreen } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln/young_person/:id/edit"
                      component={ LnEditYoungPerson } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln/young_person/:id/report"
                      component={ YoungPersonReport } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln/faqs" component={ FAQs } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln/resources" component={ (props) => <Resources {...props} prefix={'ln'} />  } />
        <PrivateRoute role={ ROLES.distributor } exact path="/ln/survey_results/:id" component={ GuardianSurvey } />

        <PrivateRoute role={ ROLES.distributor } exact path="/cots" component={ CotsYoungPeoplePage } />
        <PrivateRoute role={ ROLES.distributor } exact path="/cots/dashboard" component={ CotsDashboard } />
        <PrivateRoute role={ ROLES.distributor } exact path="/cots/young_persons" component={ CotsYoungPeoplePage } />
        <PrivateRoute role={ ROLES.distributor } exact path="/cots/young_person/create"
                      component={ CotsCreateYoungPerson } />
        <PrivateRoute role={ ROLES.distributor } exact path="/cots/young_person/confirm"
                      component={ CotsConfirmationScreen } />
        <PrivateRoute role={ ROLES.distributor } exact path="/cots/young_person/:id/edit"
                      component={ CotsEditYoungPerson } />
        <PrivateRoute role={ ROLES.distributor } exact path="/cots/young_person/:id/report"
                      component={ CotsYoungPersonReport } />
        <PrivateRoute role={ ROLES.distributor } exact path="/cots/faqs" component={ CotsFAQs } />
        <PrivateRoute role={ ROLES.distributor } exact path="/cots/resources" component={ (props) => <Resources {...props} prefix={'cots'} /> } />

        <PrivateRoute role={ ROLES.point_person } exact path="/point_person/dashboard" component={ PPDashboardLN } />
        <PrivateRoute role={ ROLES.point_person } exact path="/point_person/reports" component={ MonthlyReports } />
        <PrivateRoute role={ ROLES.point_person } exact path="/point_person/mhsds_data" component={ (props) => <MHSDSAggregatedData {...props} role={ROLES.point_person} /> } />

        {/* Sign up routes */}
        <Route exact path="/signup/:keycode" component={ PostCodeValidation }/>
        <Route exact path="/signup/:keycode/create_account" component={ LnSelfSignUpYoungPerson } />

        <Route exact path="/signup/:keycode/success" component={ SuccessPage } />

        <Route exact path="/create_account" component={ InvitationCreationPage } />
        
        <Route exact path="/login" component={ (props) => <LoginPage {...props} isPointPersonLogin={false} /> }/>
        <Route exact path="/reports" component={ (props) => <LoginPage {...props} isPointPersonLogin={true} /> }/>

        <Route path='/reset_password' component={ ResetPassword } />
        
        <Route exact path="/forgot_password" component={ (props) => <ForgotPassword {...props} url={'/forgotten_password'}/> } />
        <Route exact path="/point_person/forgot_password" component={ (props) => <ForgotPassword {...props} url={'/point_person/forgotten_password'}/> } />

        {/*  Replace with 404 page  */ }
        <Route path={ maintenancePath } component={ MaintenancePage } />
        <Route path="*" render={ LoginRedirect } />
      </Switch>
    </Container>
  );
}
