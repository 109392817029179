import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Container, Grid, Typography } from '@material-ui/core';
import ExitButton from '../../ExitButton';
import Footer from '../../content/Footer';
import { axios } from '../../../services/networkRequest';
import {
  genericAlert,
  getChain,
} from '../../../services/helpers';
import Error from '../../Error';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';

const useStyles = makeStyles(() => ({
  bottomBorder: {
    borderBottom: '1px solid #ccc',
  },
  topBorder: {
    borderTop: '1px solid #ccc',
    marginTop: '75px',
    paddingTop: '20px',
  },
  card: {
    minWidth: 275,
  },
  text: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingTop: '75px',
  },
  textSpace: {
    paddingTop: '20px',
  },
  backButton: {
    backgroundColor: '#818181',
    color: 'white',
    '&:hover': {
      backgroundColor: '#676767  '
    }
  }
}));

export default function ConfirmationScreen(props) {
  const classes = useStyles();
  const values = props.location.state;
  const [error, setError] = useState();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="lg">
      <Card className={ classes.card }>
        <CardContent>
          <Grid container direction="row" justify="space-between" alignItems="center"
                className={ classes.bottomBorder }>
            <Grid item xs={ 3 }>
              <Typography component="h6" variant="body1" align="left">{t(translationKey.LabelCreateYoungProfile)}</Typography>
            </Grid>
            <ExitButton history={ props.history } />
          </Grid>
          <Grid container className={ classes.text } direction='column' alignItems='center' justify='center'>
            <Grid item xs={ 6 }>
              <Typography component="h1" align="center" variant="body1">
                {t(translationKey.TitleConfirmYoungProfile)}
              </Typography>
            </Grid>
            <Grid item xs={ 6 }>
              <Typography component="h1" align="center" variant="body1" className={ classes.textSpace }>
                {t(translationKey.LabelSmsToGuardian, { number : values.guardian.telephone })}
              </Typography>
            </Grid>
            <Grid item xs={ 7 }>
              <Typography component="h1" align="center" variant="body1" className={ classes.textSpace }>
                {t(translationKey.LabelConfirmSendSms)}
              </Typography>
            </Grid>

            <Error style={ { marginTop: '32px' } } message={error}></Error>

          </Grid>


          <Grid container spacing={ 2 } className={ classes.topBorder } justify='center'>
            <Grid item xs={ 2 }>
              <Button fullWidth variant="contained" color="primary" onClick={ () => {
                axios.post('/ln/invite/guardian', values)
                    .then(() => {
                      props.history.replace('/ln/young_persons', {
                        newYoungPerson: true
                      });
                    })
                    .catch(err => {
                    if (getChain(err, 'response', 'status') === 402) {
                      const { title, body: message } = getChain(err, 'response', 'data');
                      genericAlert({
                        title,
                        message,
                        negativeTitle: t(translationKey.TitleBack),
                      });
                    } else {
                      setError(`Error: ${ err.response.data['error'] }`);
                    }
                  });
              } }>
                {t(translationKey.ButtonTitleSendInvite)}
              </Button>
            </Grid>

            <Grid item xs={ 2 }>
              <Button fullWidth variant="contained" className={ classes.backButton } onClick={ () => {
                props.history.replace('/ln/young_person/create', values);
              } }>
                { t(translationKey.ButtonTitleBack).toUpperCase()}
              </Button>
            </Grid>

          </Grid>
        </CardContent>
      </Card>
      <Footer />
    </Container>
  );
}
