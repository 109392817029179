import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Container, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { countryList, helperTextColor } from '../../constants';
import { useViewport } from '../../utilities/viewport';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { axios, catchAxios } from '../../services/networkRequest';
import Error from '../Error';
import { genericAlert, getChain } from '../../services/helpers';
import ExitButton from '../ExitButton';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import MUIEditor, { MUIEditorState, toolbarControlTypes } from 'react-mui-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
    marginTop: '16px',
  },
  selectText: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
  detailSection: {
    marginTop: '45px',
    fontWeight: 'normal',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  bottomBorder: {
    borderBottom: '1px solid #ccc',
  },
  topBorder: {
    borderTop: '1px solid #ccc',
    marginTop: '75px',
    paddingTop: '20px',
  },
  disabilitySpace: {
    marginTop: '10px',
  },
  card: {
    minWidth: 275,
  },
  helperText: {
    color: helperTextColor,
    fontSize: '12px',
    paddingLeft: '14px',
    paddingTop: '4px',
  },
  image: {
    height: '5rem',
    objectFit: 'cover',
    borderRadius: '4px',
    marginLeft: '0.5rem',
    padding: '0.5rem',
  },
}));


async function updateEntitySettings(axios, values, entityId) {
  return axios.post(`/entity/${ entityId }/settings`, values)
}

async function updateEntitySettingsLogo(axios, logo) {
  const formData = new FormData();

  formData.append('logo', logo);

  return axios.post(`/entity/settings/logo`, formData, {
    headers: {
      ContentType: 'multipart/form-data',
    },
  });
}

function noContentInitialValues(entityName) {

  return ({
    self_sign_up_settings: {
      enable: false,
      logo: undefined,
      url: '',
      distributor_id: undefined,
      guid: undefined,
    },
    webhook_settings: {
      enable: false,
      url: '',
    },
    custom_support_info: {
      enable: false,
      entity_name: entityName || '',
      email: '',
      phone: '',
      website: '',
    },
    service_user_reference_field: {
      show: false,
      custom_title: '',
    },
    custom_entry_one: {
      show: false,
      custom_entry_one_name: '',
    },
    custom_entry_two: {
      show: false,
      custom_entry_two_name: '',
    },
    custom_entry_three: {
      show: false,
      custom_entry_three_name: '',
    },
    lumi_nova_visibility: {
      show: false,
    },
    restrict_postcode_settings: {
      enable: false,
      country: '',
      patterns: undefined,
      availability_text: '',
      custom_support_text: '',
      enable_custom_error_message: false,
      custom_error_message: '',
      custom_error_cta_message: '',
      custom_error_cta_url: ''
    },
  });
}

export default function EntitySettingsPage({ history, location, match }) {

  const [entity, setEntity] = useState(getChain(location.state, 'entity'));
  const [distributors, setDistributors] = useState(getChain(entity, 'distributors') || [])

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current?.offsetWidth || 0);
  }, [inputLabel]);

  const { views: { mobile } } = useViewport();
  const leftColumnWidth = mobile ? 12 : 5;
  const rightColumnWidth = mobile ? 12 : 7;

  const [initialValues, setInitialValues] = useState(noContentInitialValues(getChain(entity, "name")));
  const [logoPromise, setLogoPromise] = useState(Promise.resolve());
  const [logoComplete, setLogoComplete] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if(entity) {
      axios.get(`/entity/${ entity.id }/settings`)
        .then(({ data }) => {
          const {postcode_restriction_settings, ...rest} = data;
          const {postcode_patterns, ...rest2} = postcode_restriction_settings;
          const sanitizedData = {
            ...rest,
            restrict_postcode_settings:{
              ...rest2,
              patterns: postcode_patterns.join(','),
            }
          }
          setInitialValues(sanitizedData);
          const guid = getChain(sanitizedData, "self_sign_up_settings", "guid");
          setLogoPromise(Promise.resolve(guid));
        });
    } else {
      axios.get(`/entity/${ getChain(match, 'params', 'ent_id') }`)
        .then(({data}) => {
          setEntity(data);
          setDistributors(data.distributors);
        })
    }
  }, [entity, match, setLogoComplete]);

  useEffect(() =>{
    logoPromise.then(guid => {
      setLogoComplete(!!guid);
    });
  }, [logoPromise, setLogoComplete]);

  return (
    <Card>
      <ExitButton style={ { float: 'right', padding: '20px' } } history={ history } path={`/organisation/${getChain(match, 'params', 'org_id')}/entity/${getChain(match, 'params', 'ent_id')}`} confirmation={ callback => {
        genericAlert(t(translationKey.AlertTitleCancelEntityForm), t(translationKey.AlertBodyCancelEntityForm), callback);
      } } />

      <Container component="main" maxWidth="md">
        <Typography component="h1" variant="h5">
          {t(translationKey.TitleEntitySettings, { entityName: (getChain(entity,'name') || "Entity" )})}
        </Typography>
        <Formik
          enableReinitialize={ true }
          initialValues={ initialValues }
          validate={ (values) => {
            const errors = {};
            const { self_sign_up_settings, webhook_settings } = values;

            if(self_sign_up_settings.enable && !self_sign_up_settings.url) {
              errors.url = t(translationKey.ErrorUrlIncomplete);
            }

            // If self sign up is enabled, form can be submitted if there is an existing guid, or if not a logo
            // file must be provided
            if(self_sign_up_settings.enable && !logoComplete) {
              errors.logo = t(translationKey.ErrorLogoRequired);
            }

            if(self_sign_up_settings.enable && !self_sign_up_settings.distributor_id) {
              errors.distributor_id = t(translationKey.ErrorDistributorMustBeSelected);
            }

            if(webhook_settings.enable && !webhook_settings.url) {
              errors.webhook_url = t(translationKey.ErrorUrlIncomplete);
            }

            return errors;
          } }
          onSubmit={ (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            const {restrict_postcode_settings, ...rest} = values;
            const sanitized_restrict_postcode_settings = {
              ...restrict_postcode_settings,
              patterns: restrict_postcode_settings.patterns?.trim().split(',')||[],
            }
            const sanitized_values = {
              ...rest,
              restrict_postcode_settings:sanitized_restrict_postcode_settings
            }
            logoPromise.then(guid => updateEntitySettings(axios, {
              ...sanitized_values,
              self_sign_up_settings: { ...sanitized_values.self_sign_up_settings, guid },
            }, entity.id))
              .then(() => {
                history.replace(`/organisation/${getChain(match, 'params', 'org_id')}/entity/${ entity.id }`);
              })
              .catch(err => {
                catchAxios(setFieldError)(err);
              })
              .finally(() => {
                setSubmitting(false);
              });
          } }
        >
          {
            ({
               values,
               handleChange,
               handleBlur,
               handleSubmit,
               errors,
               isValid,
               isSubmitting
             }) =>
              <form onSubmit={ handleSubmit }>

                <FormFieldSelfSignUp values={ values }
                                     handleBlur={ handleBlur }
                                     handleChange={ handleChange }
                                     inputLabel={ inputLabel }
                                     labelWidth={ labelWidth }
                                     leftColumnWidth={ leftColumnWidth }
                                     rightColumnWidth={ rightColumnWidth }
                                     distributors={ distributors }
                                     setLogoPromise={ setLogoPromise }
                                     errors={ errors }
                />

                <FormFieldWebhook values={ values }
                                  handleBlur={ handleBlur }
                                  handleChange={ handleChange }
                                  leftColumnWidth={ leftColumnWidth }
                                  rightColumnWidth={ rightColumnWidth }
                                  errors={ errors }
                />

                <FormFieldCustomSupportInfo values={ values }
                                            handleBlur={ handleBlur }
                                            handleChange={ handleChange }
                                            leftColumnWidth={ leftColumnWidth }
                                            rightColumnWidth={ rightColumnWidth } />

                <FormFieldServiceUserReference values={ values }
                                               handleBlur={ handleBlur }
                                               handleChange={ handleChange }
                                               leftColumnWidth={ leftColumnWidth }
                                               rightColumnWidth={ rightColumnWidth } />

                <FormFieldCustomEntry value={values.custom_entry_one.custom_entry_one_name}
                                      checkboxName="custom_entry_one.show"
                                      textFieldName="custom_entry_one.custom_entry_one_name"
                                      enabled={values.custom_entry_one.show}
                                      checkboxLabelValue="CustomEntryFieldOneShow"
                                      checkboxTitle={t(translationKey.LabelShowCustomEntryOne)}
                                      rowLabel={t(translationKey.LabelCustomEntryOne)}
                                      handleBlur={ handleBlur }
                                      handleChange={ handleChange }
                                      leftColumnWidth={ leftColumnWidth }
                                      rightColumnWidth={ rightColumnWidth } />

                <FormFieldCustomEntry value={values.custom_entry_two.custom_entry_two_name}
                                      checkboxName="custom_entry_two.show"
                                      textFieldName="custom_entry_two.custom_entry_two_name"
                                      enabled={values.custom_entry_two.show}
                                      checkboxLabelValue="CustomEntryFieldTwoShow"
                                      checkboxTitle={t(translationKey.LabelShowCustomEntryTwo)}
                                      rowLabel={t(translationKey.LabelCustomEntryTwo)}
                                      handleBlur={ handleBlur }
                                      handleChange={ handleChange }
                                      leftColumnWidth={ leftColumnWidth }
                                      rightColumnWidth={ rightColumnWidth } />

                <FormFieldCustomEntry value={values.custom_entry_three.custom_entry_three_name}
                                      checkboxName="custom_entry_three.show"
                                      textFieldName="custom_entry_three.custom_entry_three_name"
                                      enabled={values.custom_entry_three.show}
                                      checkboxLabelValue="CustomEntryFieldThreeShow"
                                      checkboxTitle={t(translationKey.LabelShowCustomEntryThree)}
                                      rowLabel={t(translationKey.LabelCustomEntryThree)}
                                      handleBlur={ handleBlur }
                                      handleChange={ handleChange }
                                      leftColumnWidth={ leftColumnWidth }
                                      rightColumnWidth={ rightColumnWidth } />

                <FormFieldMarketingVisibility leftColumnWidth={leftColumnWidth}
                                              rightColumnWidth={rightColumnWidth}
                                              enabled={values.lumi_nova_visibility.show}
                                              checkboxLabelValue="LumiNovaVisibilityShow"
                                              checkboxTitle={t(translationKey.LabelMarketingVisibilityCheckboxTitle)}
                                              rowLabel={t(translationKey.LabelMarketingVisibilityRow)}
                                              checkboxName="lumi_nova_visibility.show"
                                              handleChange={handleChange}
                />

                <FormFieldRestrictions values={values}
                                       handleBlur={handleBlur}
                                       handleChange={handleChange}
                                       leftColumnWidth={leftColumnWidth}
                                       rightColumnWidth={rightColumnWidth}/>

                <FormFieldCustomErrors values={values}
                                       handleBlur={handleBlur}
                                       handleChange={handleChange}
                                       leftColumnWidth={leftColumnWidth}
                                       rightColumnWidth={rightColumnWidth}/>
                <br/>
                <Error message={ errors['network'] } />
                <br/>

                <Button
                  disabled={ !isValid || isSubmitting }
                  type="submit"
                  variant="contained"
                  color="primary">{t(translationKey.ButtonSubmit)}
                </Button>
              </form>
          }
        </Formik>

        <div style={ { marginBottom: '3rem' } } />
      </Container>
    </Card>
  )
}

function FormFieldSelfSignUp({ values: { self_sign_up_settings }, errors, setLogoPromise, handleChange, handleBlur, leftColumnWidth, rightColumnWidth, inputLabel, labelWidth, distributors }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const guid = getChain(self_sign_up_settings, "guid");
  const [displayImage, setDisplayImage] = useState();

  useEffect(() => {
    setDisplayImage(guid && `${ process.env.REACT_APP_BASE_URL }/entity/logo/${ guid }`);
  }, [guid]);

  const updateLogo = (event) => {
    const logo = event.target.files[0];
    setDisplayImage(URL.createObjectURL(logo));

    const promise = updateEntitySettingsLogo(axios, logo)
      .then(({ data }) => data.guid);

    setLogoPromise(promise);
  }

  const enabled = self_sign_up_settings.enable;

  return (
    <>
      <Row>
        <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
          {t(translationKey.LabelSelfSignUpSettings)}
        </Typography>
      </Row>
      <CheckboxFieldWithTitle title={t(translationKey.LabelEnableSelfSignUp)}
                              formLabelValue="EnableSelfSignUpPage"
                              name="self_sign_up_settings.enable"
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <Row>
        <Grid item xs={ leftColumnWidth }>
          <Typography className={ classes.label } component="h2" align="left" variant="body1">
            {t(translationKey.LabelEntityLogo)}
          </Typography>
        </Grid>
        <Grid item xs={ rightColumnWidth } style={ { display: 'flex', alignItems: 'center' } }>
          <Button variant="contained" component="label" disabled={!enabled}>
            {t(translationKey.ButtonUploadFile)}
            <input id='contained-button-file' type="file" name="file" accept={ ['image/png', 'image/jpeg', 'image/svg+xml'] }
                   hidden
                   onChange={ e => {
                     updateLogo(e);
                   } }
            />
          </Button>
          {
            displayImage ?
              <img className={ classes.image }
                   src={ displayImage } alt={t(translationKey.AltUserUploadedLogo)} /> :
              <div className={ classes.image } />
          }
          <Error message={ errors.logo && errors.logo } />
        </Grid>
      </Row>
      <TextFieldWithTitleAndBaseUrl title={t(translationKey.LabelCustomizeSignUpPageUrl)}
                                    leftColumnWidth={ leftColumnWidth }
                                    rightColumnWidth={ rightColumnWidth }
                                    name="self_sign_up_settings.url"
                                    onBlur={ handleBlur }
                                    value={ self_sign_up_settings.url }
                                    onChange={ handleChange }
                                    label={t(translationKey.LabelUrl)}
                                    enabled={enabled}
                                    baseUrl={ process.env.REACT_APP_SIGNUP_URL }
                                    helperText={ errors.url && errors.url }
      />
      <Row>
        <Grid item xs={ leftColumnWidth }>
          <Typography className={ classes.label } component="h2" align="left" variant="body1">
            {t(translationKey.LabelAssignSelfSignUpDistributor)}
          </Typography>
        </Grid>
        <Grid item xs={ rightColumnWidth }>
          { self_sign_up_settings.distributor_id &&
          <Typography align={'left'}>
            { getChain(distributors.find(d => d.id === self_sign_up_settings.distributor_id), "name") }
          </Typography>
          }
          <FormControl variant="outlined" className={ classes.formControl }>
            <InputLabel ref={ inputLabel } id="gender-select-outlined-label">
              {t(translationKey.TitleDistributor)}
            </InputLabel>
            <Select
              disabled={ !enabled }
              labelId="gender-select-outlined-label"
              id="gender-select-outlined"
              name="self_sign_up_settings.distributor_id"
              value={ self_sign_up_settings.distributor_id }
              defaultValue={ self_sign_up_settings.distributor_id }
              className={ classes.selectText }
              labelWidth={ labelWidth }
              onChange={ handleChange }
              onBlur={ handleBlur }
            >
              <MenuItem disabled={true}>
                <em>{t(translationKey.LabelSelectEllipses)}</em>
              </MenuItem>
              {
                distributors.map(d =>
                  <MenuItem key={ d.id } value={ d.id }>{ d.name }</MenuItem>
                )
              }
            </Select>
          </FormControl>
          <Error message={ errors.distributor_id && errors.distributor_id } />
        </Grid>
      </Row>
    </>
  )
}

function FormFieldMarketingVisibility({rowLabel, checkboxName, checkboxTitle, handleChange, leftColumnWidth, rightColumnWidth, enabled, checkboxLabelValue}) {
  const classes = useStyles();

  return (
    <>
      <Row>
        <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
          { rowLabel }
        </Typography>
      </Row>

      <CheckboxFieldWithTitle
        title={checkboxTitle}
        formLabelValue={checkboxLabelValue}
        value={enabled}
        rightColumnWidth={rightColumnWidth}
        leftColumnWidth={leftColumnWidth}
        onChange={handleChange}
        name={checkboxName}
        checked={enabled}
      />
    </>
  )
}

function FormFieldWebhook({ values: { webhook_settings }, handleChange, handleBlur, leftColumnWidth, rightColumnWidth, errors }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const enabled = webhook_settings.enable;
  return (
    <>
      <Row>
        <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
          {t(translationKey.LabelWebhookSettings)}
        </Typography>
      </Row>
      <CheckboxFieldWithTitle title={t(translationKey.LabelEnableWebhook)}
                              formLabelValue="WebhookEnable"
                              name="webhook_settings.enable"
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <TextFieldWithTitle title={t(translationKey.LabelWebhookUrl)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="webhook_settings.url"
                          onBlur={ handleBlur }
                          value={ webhook_settings.url }
                          onChange={ handleChange }
                          label={t(translationKey.LabelUrl)}
                          enabled={ enabled }
                          required={ enabled }
                          helperText={ errors.webhook_url && errors.webhook_url }
                          FormHelperTextProps={ { style: { color: helperTextColor } } }
      />
    </>
  )
}

function FormFieldCustomSupportInfo({ values: { custom_support_info }, handleChange, handleBlur, leftColumnWidth, rightColumnWidth }) {

  const classes = useStyles();
  const { t } = useTranslation();

  const enabled = custom_support_info.enable;
  return (
    <>
      <Row>
        <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
          {t(translationKey.LabelCustomSupportInfo)}
        </Typography>
      </Row>
      <CheckboxFieldWithTitle title={t(translationKey.LabelEnableCustomSupportInfo)}
                              formLabelValue="CustomSupportInfoEnable"
                              name="custom_support_info.enable"
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <TextFieldWithTitle title={t(translationKey.LabelEntityName)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="custom_support_info.entity_name"
                          onBlur={ handleBlur }
                          value={ custom_support_info.entity_name }
                          onChange={ handleChange }
                          label={t(translationKey.LabelEntityName)}
                          enabled={ enabled }
      />
      <TextFieldWithTitle title={t(translationKey.TitleEmail)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="custom_support_info.email"
                          onBlur={ handleBlur }
                          value={ custom_support_info.email }
                          onChange={ handleChange }
                          enabled={ enabled }
                          label={t(translationKey.TitleEmail)}
      />
      <TextFieldWithTitle title={t(translationKey.TitlePhone)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="custom_support_info.phone"
                          onBlur={ handleBlur }
                          value={ custom_support_info.phone }
                          onChange={ handleChange }
                          enabled={ enabled }
                          label={t(translationKey.TitlePhone)}
      />
      <TextFieldWithTitle title={t(translationKey.TitleWebsite)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="custom_support_info.website"
                          onBlur={ handleBlur }
                          value={ custom_support_info.website }
                          onChange={ handleChange }
                          enabled={ enabled }
                          label={t(translationKey.TitleWebsite)}
      />
    </>
  )
}

function FormFieldServiceUserReference({ values: { service_user_reference_field }, handleChange, leftColumnWidth, rightColumnWidth, handleBlur }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const enabled = service_user_reference_field.show;
  return (
    <>
      <Row>
        <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
          {t(translationKey.LabelServiceUserReferenceField)}
        </Typography>
      </Row>
      <CheckboxFieldWithTitle title={t(translationKey.LabelShowServiceUserReference)}

                              formLabelValue="ServiceUserReferenceFieldShow"
                              name="service_user_reference_field.show"
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <TextFieldWithTitle title={t(translationKey.LabelCustomTitle)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name="service_user_reference_field.custom_title"
                          onBlur={ handleBlur }
                          value={ service_user_reference_field.custom_title }
                          onChange={ handleChange }
                          label={t(translationKey.LabelCustomTitle)}
                          enabled={enabled}
                          required={enabled}
      />
    </>
  )
}

function FormFieldCustomEntry({ rowLabel, textFieldName, value, enabled, checkboxName, checkboxTitle, checkboxLabelValue, handleChange, leftColumnWidth, rightColumnWidth, handleBlur}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Typography className={ classes.detailSection } component="h1" align="left" variant="body1">
          { rowLabel }
        </Typography>
      </Row>
      <CheckboxFieldWithTitle title={ checkboxTitle }

                              formLabelValue={checkboxLabelValue}
                              name={ checkboxName }
                              value={ enabled }
                              checked={ enabled }
                              onChange={ handleChange }
                              leftColumnWidth={ leftColumnWidth }
                              rightColumnWidth={ rightColumnWidth }
      />
      <TextFieldWithTitle title={t(translationKey.LabelCustomTitle)}
                          leftColumnWidth={ leftColumnWidth }
                          rightColumnWidth={ rightColumnWidth }
                          name={ textFieldName }
                          onBlur={ handleBlur }
                          value={ value }
                          onChange={ handleChange }
                          label={t(translationKey.LabelCustomTitle)}
                          enabled={enabled}
                          required={enabled}
      />
    </>
  )
}

// Basic form field components
function TextFieldWithTitle({leftColumnWidth, rightColumnWidth, title, subtitle, enabled, ...props}) {
  const classes = useStyles();
  return (
    <Row>
      <Grid item xs={leftColumnWidth}>
        <Typography className={classes.label} component="h2" align="left" variant="body1">
          {title}
        </Typography>
        {subtitle && (
          <Typography className={classes.label} component="h5" align="left">
            {subtitle}
          </Typography>
        )}
      </Grid>
      <Grid item xs={rightColumnWidth}>
        <TextField
          className={classes.textField}
          variant="outlined"
          type="text"
          fullWidth
          disabled={!enabled}
          {...props}
        />
      </Grid>
    </Row>
  )
}

function TextFieldWithTitleAndBaseUrl({ leftColumnWidth, rightColumnWidth, title, baseUrl, enabled, ...props }) {
  const classes = useStyles();


  const { views: { mobile } } = useViewport();

  return (
    <Row>
      <Grid item xs={ leftColumnWidth }>
        <Typography className={ classes.label } component="h2" align="left" variant="body1">
          { title }
        </Typography>
      </Grid>
      <Grid item xs={ rightColumnWidth }>
        <Grid container>
          <Grid item xs={ mobile ? 7 : 6 } style={ { display: 'flex', alignItems: 'center' } }>
            <Typography component="h5" align="left" variant="body2">{ baseUrl }</Typography>
          </Grid>
          <Grid item xs={ mobile ? 5 : 6 }>
            <TextField
              disabled={!enabled}
              className={ classes.textField }
              variant="outlined"
              type="text"
              { ...props }
            />
          </Grid>
        </Grid>
      </Grid>
    </Row>
  )
}

function CheckboxFieldWithTitle({ leftColumnWidth, rightColumnWidth, title, formLabelValue, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Row>
      <Grid item xs={ leftColumnWidth }>
        <Typography className={ classes.label } component="h2" align="left" variant="body1">
          { title }
        </Typography>
      </Grid>
      <Grid item xs={ rightColumnWidth }>
        <FormControlLabel
          className={ classes.formControl }
          value={ formLabelValue }
          control={
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': t(translationKey.AccessibilityLabelSecondaryCheckbox) }}
              { ...props }
            />
          }
          label="" />
      </Grid>
    </Row>
  )
}

function TextFieldWithEditor({leftColumnWidth, rightColumnWidth, title, subtitle, enabled, value,  ...props}) {
 const classes = useStyles();
  const config = {
    draftEditor: {
      readOnly: !enabled
    },
    toolbar: {
      controls: [
        toolbarControlTypes.bold,
        toolbarControlTypes.italic,
        toolbarControlTypes.underline,
        toolbarControlTypes.unorderedList,
        toolbarControlTypes.orderedList,
        toolbarControlTypes.strikethrough,
      ],
    },
  }

  const [isFocused, setIsFocus] = useState(false);
  const [editorState, setEditorState] = React.useState(
    ()=> MUIEditorState.createWithContent(config,stateFromHTML(value))
  );

  useEffect(()=>{
    if(isFocused || !value){
      return;
    }
    setEditorState( MUIEditorState.createWithContent(config,stateFromHTML(value)));
    setIsFocus(true);
  },[value, isFocused, config]);

  const onChange = newState => {
    setEditorState(newState);
    const html = stateToHTML(newState.getCurrentContent());
    props.onChange({target:{name:`restrict_postcode_settings.custom_support_text`, value:html}})
  }

  return (
    <>
      {
        <Row>
          <Grid item xs={leftColumnWidth}>
            <Typography className={classes.label} component="h2" align="left" variant="body1">
                {title}
            </Typography>
            {subtitle && (
                <Typography className={classes.label} component="h5" align="left">
                    {subtitle}
                </Typography>
            )}
          </Grid>
          <Grid item xs={rightColumnWidth}>
            <MUIEditor
                editorState={editorState}
                onChange={onChange}
                config={config}
            />
           </Grid>
          </Row>
        }
    </>
    )
}

function FormFieldCustomErrors({values: {restrict_postcode_settings},
                                 handleChange,
                                 handleBlur,
                                 leftColumnWidth,
                                 rightColumnWidth,
                               }) {

  const {t} = useTranslation();
  const enabled = restrict_postcode_settings?.enable_custom_error_message || false;

  return (
    <>
      <CheckboxFieldWithTitle title={t(translationKey.LabelEnableCustomErrorMessage)}
                              formLabelValue="CustomErrorEnable"
                              name="restrict_postcode_settings.enable_custom_error_message"
                              value={enabled}
                              checked={enabled}
                              onChange={handleChange}
                              leftColumnWidth={leftColumnWidth}
                              rightColumnWidth={rightColumnWidth}
      />

      <TextFieldWithTitle title={t(translationKey.LabelCustomErrorMessage)}
                          leftColumnWidth={leftColumnWidth}
                          rightColumnWidth={rightColumnWidth}
                          name="restrict_postcode_settings.custom_error_message"
                          onBlur={handleBlur}
                          value={restrict_postcode_settings?.custom_error_message}
                          onChange={handleChange}
                          label={t(translationKey.LabelCustomErrorMessage)}
                          enabled={enabled}
                          required={enabled}
                          multiline
      />

      <TextFieldWithTitle title={t(translationKey.LabelCustomErrorCTAMessage)}
                          leftColumnWidth={leftColumnWidth}
                          rightColumnWidth={rightColumnWidth}
                          name="restrict_postcode_settings.custom_error_cta_message"
                          onBlur={handleBlur}
                          value={restrict_postcode_settings?.custom_error_cta_message}
                          onChange={handleChange}
                          label={t(translationKey.LabelCustomErrorCTAMessage)}
                          enabled={enabled}
                          multiline
      />

      <TextFieldWithTitle title={t(translationKey.LabelCustomErrorCTAURL)}
                          leftColumnWidth={leftColumnWidth}
                          rightColumnWidth={rightColumnWidth}
                          name="restrict_postcode_settings.custom_error_cta_url"
                          onBlur={handleBlur}
                          value={restrict_postcode_settings?.custom_error_cta_url}
                          onChange={handleChange}
                          label={t(translationKey.LabelCustomErrorCTAURL)}
                          enabled={enabled}
                          multiline
      />
    </>
  )
}

function FormFieldRestrictions({values: {restrict_postcode_settings},
                                 handleChange,
                                 handleBlur,
                                 leftColumnWidth,
                                 rightColumnWidth,
                                 inputLabel,
                                 labelWidth,
                               }) {

  const classes = useStyles();

  const {t} = useTranslation();

  const enabled = restrict_postcode_settings?.enable || false;
  const validationRegex = /^[A-Za-z0-9,]*$/;
  let isValid = true;
  if(restrict_postcode_settings?.patterns?.length){
    isValid = validationRegex.test(restrict_postcode_settings.patterns)
  }

  return (
    <>
      <Row>
        <Typography className={classes.detailSection} component="h1" align="left" variant="body1">
          {t(translationKey.TitleRestrictionSettings)}
        </Typography>
      </Row>

      <CheckboxFieldWithTitle title={t(translationKey.LabelRestrictPostCode)}
                              formLabelValue="RestrictPostcodeEnable"
                              name="restrict_postcode_settings.enable"
                              value={enabled}
                              checked={enabled}
                              onChange={handleChange}
                              leftColumnWidth={leftColumnWidth}
                              rightColumnWidth={rightColumnWidth}
      />

      <Row>
        <Grid item xs={leftColumnWidth}>
          <Typography className={classes.label} component="h2" align="left" variant="body1">
            {t(translationKey.LabelRestrictSignupCountryTo)}
          </Typography>
        </Grid>

        <Grid item xs={rightColumnWidth}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="country-select-outlined-label">
              {t(translationKey.LabelRestrictSignupCountryTo)}
            </InputLabel>
            <Select
              disabled={!enabled}
              labelId="country-select-outlined-label"
              id="country-select-outlined"
              name="restrict_postcode_settings.country"
              value={restrict_postcode_settings?.country}
              defaultValue={restrict_postcode_settings?.country}
              className={classes.selectText}
              labelWidth={labelWidth}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <MenuItem disabled={true}>
                <em>{t(translationKey.LabelSelectEllipses)}</em>
              </MenuItem>
              {
                countryList.map(country =>
                  <MenuItem key={country} value={country}>{country}</MenuItem>
                )
              }
            </Select>
          </FormControl>
        </Grid>
      </Row>

      <TextFieldWithTitle title={t(translationKey.LabelAcceptPostCodesStartingWith)}
                          leftColumnWidth={leftColumnWidth}
                          rightColumnWidth={rightColumnWidth}
                          name="restrict_postcode_settings.patterns"
                          onBlur={handleBlur}
                          value={restrict_postcode_settings?.patterns}
                          onChange={handleChange}
                          label={t(translationKey.LabelAcceptPostCodesStartingWith)}
                          enabled={enabled}
                          required={enabled}
                          subtitle={t(translationKey.SubTitleExamplePatternText)}
                          multiline
                          error={!isValid}
                          helperText={!isValid? t(translationKey.InvalidPattern):''}
      />

        <TextFieldWithTitle title={t(translationKey.LabelAvailabilityText)}
                            leftColumnWidth={leftColumnWidth}
                            rightColumnWidth={rightColumnWidth}
                            name="restrict_postcode_settings.availability_text"
                            onBlur={handleBlur}
                            value={restrict_postcode_settings?.availability_text}
                            onChange={handleChange}
                            label={t(translationKey.LabelAvailabilityText)}
                            enabled={enabled}
                            required={enabled}
                            subtitle={t(translationKey.SubTitleAvailabilityText)}
                            multiline
        />

      <TextFieldWithEditor title={t(translationKey.LabelCustomSupportText)}
                           leftColumnWidth={leftColumnWidth}
                           rightColumnWidth={rightColumnWidth}
                           name="restrict_postcode_settings?.custom_support_text"
                           onBlur={handleBlur}
                           value={restrict_postcode_settings?.custom_support_text}
                           onChange={handleChange}
                           label={t(translationKey.LabelCustomSupportText)}
                           enabled={enabled}
                           required={enabled}
                           subtitle={t(translationKey.SubTitleCustomSupportText)}
                           multiline
      />
    </>
  )
}


function Row({ children }) {
  const { views: { mobile } } = useViewport();

  return (
    <Grid container direction={ mobile ? 'column' : 'row' }
          justify="space-between" alignItems={ mobile ? '' : 'center' } style={ { margin: '0.5rem 0' } }>
      { children }
    </Grid>
  )
}
