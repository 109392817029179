import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { CircularProgress } from '@material-ui/core';
import { barColor } from '../../Styles';
import { months } from '../../constants';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 250,
  },
  centre: {
    margin: 'auto',
  },
}));


const monthValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const MonthlyUsage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!props.usage) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }
  // The data comes from the server in the correct order.
  // We have to get the month labels using the month numbers provided,
  // then re-number the months 1-12 to display in order on the axis.
  const monthOrder = props.usage.map(each => new Date(each.date).getMonth());
  const monthStrings = monthOrder.map(each => months[each]);

  const chartData = props.usage.map((item, index) => {
    return {
      month: index + 1,
      usage: item.time / 60 / 60,
    };
  });

  return (
    <div className={ classes.root }>
      <VictoryChart
        height={ 270 }
        width={ props.size.width }
        domainPadding={ 16 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ monthValues }
                     tickFormat={ monthStrings }
                     label={t(translationKey.LabelMonth)}
                     style={ { axisLabel: { padding: 30 }, grid: { stroke: 'none' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     label={t(translationKey.LabelGameSessionTimeInHours)}
                     style={ { axisLabel: { padding: 35 } } }
        />
        <VictoryBar
          tickValues={ monthValues }
          cornerRadius={ { top: 2 } }
          style={ { data: { fill: barColor, width: props.size.width / 20 } } }
          data={ chartData }
          x={t(translationKey.LabelMonth)}
          y={t(translationKey.LabelUsage)}
        />
      </VictoryChart>
    </div>
  );
};

export default withSize()(MonthlyUsage);

