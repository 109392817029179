import React from 'react';
import EntityForm from './EntityForm';
import { getChain } from '../../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';


function EntityCreatePage(props) {
  const { t } = useTranslation();
  return <EntityForm
    { ...props }
    path='/entity'
    title={t(translationKey.TitleCreateEntity)}
    organisation={ getChain(props, 'location', 'state', 'organisation') }
  />;
}

export default EntityCreatePage;
