import axiosBase from 'axios';
import { getChain } from './helpers';
import fileDownload from 'js-file-download';

export const isCancel = axiosBase.isCancel;
export const CancelToken = axiosBase.CancelToken;
export const loginPath = '/login';
export const pointPersonLoginPath = '/reports';
export const maintenancePath = '/maintenance';
export const axios = axiosBase.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axios.getFile = function (url, filename) {
  this.get(url, {
    responseType: 'arraybuffer',
  })
    .then(res => {
      fileDownload(res.data, filename, res.headers['content-type']);
    });
};

axios.interceptors.request.use(config => {
  if(localStorage.getItem("tester")) {
    config.headers['x-test'] = "true";
  }

  return config;
});

axios.interceptors.response.use(response => {
  const updatedToken = getChain(response.data, 'token');
  if (updatedToken) {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + updatedToken;

    const storedUser = localStorage.getItem('user');
    const userObject = storedUser && JSON.parse(storedUser);
    if (userObject) {
      userObject.token = updatedToken;
      localStorage.setItem('user', JSON.stringify(userObject));
    }
  }

  return response;
}, function (error) {
  const status = getChain(error, 'response', 'status');

  if (status === 401 && window.location.pathname !== loginPath && window.location.pathname !== pointPersonLoginPath) {
    localStorage.removeItem('user');

    window.location.assign(loginPath);
  }

  if (status === 423) {
    window.location.assign(maintenancePath);
  }

  return Promise.reject(error);
});

export function catchAxios(setFieldError) {
  return error => setFieldError('network', getChain(error, 'response', 'data', 'error') || error.message);
}
