import React, { useEffect, useState } from 'react';
import PostCodeValidationScreen from './PostCodeFormScreen';
import SelfSignUpFooter from '../SelfSignUp/Footer';
import SelfSignUpHeader from '../SelfSignUp/Header';
import { useHistory, useParams } from 'react-router-dom';
import { axios } from '../../services/networkRequest';
import { getChain } from '../../services/helpers';

export default function PostCodeValidation(){
  const history = useHistory();
  const { keycode } = useParams();
  const [entity, setEntity] = useState(undefined);
  const guid = getChain(entity, 'self_sign_up_settings', 'guid');

  useEffect(() => {
    if(!entity) {
      axios.get(`/entity/${ keycode }`)
        .then(({ data }) => {
          setEntity(data);
          const restrict_postcode = getChain(data, 'postcode_restriction_settings', 'enable')
          if(!restrict_postcode) {
            history.push(`/signup/${ keycode }/create_account`);
          }
        })
    }
    // eslint-disable-next-line
  }, [keycode, entity]);

  return (
    <>

    { entity &&
      <>
        <SelfSignUpHeader guid={ guid } />
        <PostCodeValidationScreen entity={ entity } />
        <SelfSignUpFooter />
      </>
    }
    </>

  )
}