import React from 'react';

export const ModalBackground = ({ children }) => (
  <div
    style={ {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      background: 'rgba(255, 255, 255, 0.8)',
      zIndex: '101',
    } }>
    { children }
  </div>
);

