import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { CircularProgress } from '@material-ui/core';
import { barColor } from '../Styles';
import { tickSteps } from '../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 350,
  },
  centre: {
    margin: 'auto',
  },
}));

const DailyYoungPeople = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!props.data) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  // Axis label hackery from first item date to avoid keeping lookup tables
  const date = new Date(props.data[0].date);
  const dateString = date.toDateString();
  const parts = dateString.split(' ');
  const yAxisLabel = parts[1] + ' ' + parts[3];
  // Assumes data comes from server in the correct order (it does currently!)
  // Save a lot of time on parsing the dates and sorting
  // remove slice hack when the correct limit on the data is set on the server.
  let chartData = props.data.slice(0, 31);
  chartData = chartData.map((item, index) => {
    return {
      day: index + 1,
      users: item.count,
    };
  });
  const dayValues = chartData.map(item => item.day);

  const userValues = chartData.map(item => item.users);
  const tickValues = tickSteps(userValues);

  return (
    <div className={ classes.root }>
      <VictoryChart
        height={ 370 }
        width={ props.size.width }
        domainPadding={ 16 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ dayValues }
                    //  tickFormat={ monthStrings }
                     label={yAxisLabel}
                     style={ { axisLabel: { padding: 30 }, grid: { stroke: 'none' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={tickValues}
                     label={t(translationKey.LabelYoungPeoplePlayingLumiNova)}
                     style={ { axisLabel: { padding: 35 } } }
        />
        <VictoryBar
          tickValues={ dayValues }
          cornerRadius={ { top: 2 } }
          style={ { data: { fill: barColor, width: props.size.width / 40 } } }
          data={ chartData }
          x={t(translationKey.LabelDay)}
          y={t(translationKey.LabelUsers)}
        />
      </VictoryChart>
    </div>
  );
};

export default withSize()(DailyYoungPeople);
