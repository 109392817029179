import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { getChain } from '../services/helpers';
import { Box, Card, Container, Grid, IconButton, Modal, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CustomSupportInfo } from './Error';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    marginBottom: '1em',
  },
  container: {
    margin: '1em 0',
    display: 'grid',
    placeItems: 'start start',
  },
  link: {
    color: '#c9218e',
    fontWeight: 'bold',
  },
  modalContainer: {
    display: 'grid',
    placeItems: 'center',
  },
  modal: {
    background: '#ffffff',
    padding: '0 1rem 2rem 1rem',
  },
  card: {
    borderRadius: '1rem',
  },
  content: {
    fontSize: '1rem',
  },
  gameLogo: {
    width: '100%',
    maxWidth: '10rem',
    margin: '1rem 0',
  },
}));

export default function ErrorModal({ open, handleClose, customSupportInfo, errorResponse }) {
  const classes = useStyles();

  const showCustomSupportInfo = getChain(customSupportInfo, 'enable');

  return (
    <Modal
      open={ open }
      onClose={ handleClose }
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className={ classes.modalContainer }
    >
      <Card className={ classes.card }>
        <Box sx={ { width: '100%', maxWidth: 700 } } className={ classes.modal }>
          <Container component="main" maxWidth="md">
            <Grid container justify="center">
              <Grid item xs={ 12 }>
                <div style={ { display: 'grid', placeItems: 'end', width: '100%' } }>
                  <IconButton aria-label="close"
                              onClick={ handleClose }>
                    <CloseIcon />
                  </IconButton>
                </div>
              </Grid>

              <Grid container justify="center">
                <img src="/ln_logo_transparent_background.png" alt="Lumi Nova logo" className={ classes.gameLogo } />
              </Grid>

              <Grid container style={ { display: 'block', textAlign: 'center' } }>
                <Typography component="h3" variant="h6" className={ classes.title }>
                  { errorResponse.title }
                </Typography>
                <Typography component="p" variant="h6" className={ classes.content }>
                  { errorResponse.body }
                </Typography>
              </Grid>

              { customSupportInfo && showCustomSupportInfo &&
              <CustomSupportInfo customSupportInfo={ customSupportInfo } /> }

            </Grid>
          </Container>
        </Box>
      </Card>
    </Modal>
  );
}