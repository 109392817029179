import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useUserContext } from '../../store/UserContext';
import { redirectFactory } from '../../services/helpers';
import { Games } from '../../constants';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  background: {
    width: '100vw',
    height: '100vh',
  },
  logo: {
    position: 'absolute',
    left: 32,
    width: '100px',
    height: 'auto',
  },
  gameLogo: {
    marginTop: '5vh',
    width: '15vw',
    cursor: 'pointer',
  },
}));

export default function GameChoice(props) {

  const { pickGame, user } = useUserContext();
  const classes = useStyles();
  const { t } = useTranslation();

  const redirect = redirectFactory(props, pickGame);

  if(user.games.length === 1) {
    if(user.games[0] === 'cots') {
      redirect(Games.COTS);
    } else if(user.games[0] === 'ln') {
      redirect(Games.LN);
    }
  }

  return (
    <Container component="main" maxWidth="lg">
      <img src='/BfB_logo_blue.png' alt='logo' className={ classes.logo } />

      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 }><Typography color="textPrimary" align="center" variant='h5' style={ {
          fontWeight: 600,
          marginTop: '100px',
          color: '#043494',
          fontSize: '32px',
        } }>
          {t(translationKey.TitleWelcomePlayer, { player: user.name})}</Typography></Grid>
        <Grid item xs={ 12 }><Typography align="center" variant='h5' style={ { fontWeight: 400 } }>
          {t(translationKey.TitleChooseAnIntervention)}</Typography></Grid>

        <Grid item xs={ 2 } />
        <Grid item xs={ 4 }>
          <img src={ Games.COTS.logo }
               alt={t(translationKey.AltGameLogo, { game: Games.COTS.name})}
               className={ classes.gameLogo }
               onClick={ () => redirect(Games.COTS) } />
        </Grid>
        <Grid item xs={ 4 }>
          <img src={ Games.LN.logo }
               alt={t(translationKey.AltGameLogo, { game: Games.LN.name})}
               className={ classes.gameLogo }
               onClick={ () => redirect(Games.LN) } />
        </Grid>
        <Grid item xs={ 2 } />

        <Grid item xs={ 2 } />
        <Grid item xs={ 4 } onClick={ () => redirect(Games.COTS) }><Typography color="textPrimary" align="center"
                                                                               variant='h5'
                                                                               style={ { cursor: 'pointer' } }>
          {t(translationKey.LabelChampionsOfTheShengha)}</Typography>
          <Typography color="textSecondary" align="center" variant='h5'
                      style={ { fontSize: '18px', cursor: 'pointer' } }>
            {t(translationKey.TitleForMindfulBreathing)}</Typography></Grid>
        <Grid item xs={ 4 } onClick={ () => redirect(Games.LN) }><Typography color="textPrimary" align="center"
                                                                             variant='h5'
                                                                             style={ { cursor: 'pointer' } }>
          {t(translationKey.TitleLumiNovaTalesOfCourage)}</Typography>
          <Typography color="textSecondary" align="center" variant='h5'
                      style={ { fontSize: '18px', cursor: 'pointer' } }>
            {t(translationKey.TitleToHelpWorries)}</Typography></Grid>
        <Grid item xs={ 2 } />
      </Grid>
    </Container>
  );
}
