import React from 'react';
import DistributorForm from './DistributorForm';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';


function DistributorsCreatePage(props) {
  const { t } = useTranslation();
  return <DistributorForm
    { ...props }
    path='/distributor'
    title={t(translationKey.TitleCreateADistributor)}
  />;
}

export default DistributorsCreatePage;
