import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const useStyles = makeStyles(() => ({
    informationCard: {
      backgroundColor: '#EBEBEB',
      paddingLeft:'10px',
      paddingRight:'10px',
      paddingBottom:'10px',
      marginBottom:'16px',
      marginTop: '8px'
    },
    informationCardText: {
      fontStyle: 'italic',
      textAlign: 'left',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.8rem',
    }
  }));

export function GBOInformation() {
  const classes = useStyles();
  const { t } = useTranslation();

    return (
        <Grid className={ classes.informationCard } container spacing="2">
          <Grid item>
            <Typography className={ classes.informationCardText }>{t(translationKey.TitleGboInformation)}</Typography>
          </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                {t(translationKey.TextGboInformationOne)}
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                {t(translationKey.TextGboInformationTwo)}
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText } display="inline">
                {t(translationKey.LabelLearnMore)}
              </Typography>
              <a href="https://www.corc.uk.net/outcome-experience-measures/goal-based-outcomes-gbo/" target='_blank'
              className={ classes.informationCardText } rel="noopener noreferrer">
                https://www.corc.uk.net/outcome-experience-measures/goal-based-outcomes-gbo/
              </a>
            </Grid>
          </Grid>
    )
}

export function CORSInformationCard() {
    const classes = useStyles();
    const { t } = useTranslation();

    return(
        <Grid className={ classes.informationCard } container spacing="2">
          <Grid item>
            <Typography  className={ classes.informationCardText }>
              {t(translationKey.TitleCorsInformation)}
            </Typography>
          </Grid>

            <Grid item>
                <Typography className={ classes.informationCardText }>
                  {t(translationKey.TextCorsInformationOne)}
                </Typography>
            </Grid>

            <Grid item>
                <ol className={ classes.informationCardText }>
                    <li className={ classes.informationCardText }>{t(translationKey.ListCorsMe)}</li>
                    <li className={ classes.informationCardText }>{t(translationKey.ListCorsFamily)}</li>
                    <li className={ classes.informationCardText }>{t(translationKey.ListCorsSchool)}</li>
                    <li className={ classes.informationCardText }>{t(translationKey.ListCorsEverything)}</li>
                </ol>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                {t(translationKey.TextCorsInformationTwo)}
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                {t(translationKey.TextCorsInformationThree)}
              </Typography>
            </Grid>

            <Grid item xs={ 12 }>
              <Typography className={ classes.informationCardText }>{t(translationKey.TitleCutOffScores)}</Typography>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText }>
                {t(translationKey.TextCutOffScoresInformation)}
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={ classes.informationCardText } display="inline">
                {t(translationKey.LabelLearnMore)}
              </Typography>
              <a href="https://www.corc.uk.net/outcome-experience-measures/outcome-rating-scale-ors-child-outcome-rating-scale-cors/ " target='_blank'
               className={ classes.informationCardText } rel="noopener noreferrer">
                https://www.corc.uk.net/outcome-experience-measures/outcome-rating-scale-ors-child-outcome-rating-scale-cors/
              </a>
            </Grid>
          </Grid>
    )
}
