import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';

export const useDaysOfWeek = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        day: t(translationKey.DayMonday),
        label: t(translationKey.DayMondayShort),
      },
      {
        day: t(translationKey.DayTuesday),
        label: t(translationKey.DayTuesdayShort),
      },
      {
        day: t(translationKey.DayWednesday),
        label: t(translationKey.DayWednesdayShort),
      },
      {
        day: t(translationKey.DayThursday),
        label: t(translationKey.DayThursdayShort),
      },
      {
        day: t(translationKey.DayFriday),
        label: t(translationKey.DayFridayShort),
      },
      {
        day: t(translationKey.DaySaturday),
        label: t(translationKey.DaySaturdayShort),
      },
      {
        day: t(translationKey.DaySunday),
        label: t(translationKey.DaySundayShort),
      },
    ];
    // t function causes infinite loop so omit it on this occasion
    // eslint-disable-next-line
  }, [])
}