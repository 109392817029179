import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { axios, catchAxios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import queryString from 'query-string';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { helperTextColor } from '../../constants';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  background: {
    width: '100vw',
  },
  logo: {
    position: 'absolute',
    left: 32,
    width: '100px',
    height: 'auto',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ResetPassword(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={ classes.background } component="main" maxWidth="xs">
      <img src='/BfB_logo_blue.png' alt='logo' className={ classes.logo } />
      <CssBaseline />
      <div className={ classes.paper }>
        <Typography component="h1" variant="h5">
          {t(translationKey.TitleResetPassword)}
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ { password: '', confirmation: '' } }
          validate={ values => {
            const errors = {};
            const min_length = 10;
            if (!values.password) {
              errors.password = t(translationKey.ErrorEnterPassword);
            } else if (values.password.length < min_length) {
              errors.password = (t(translationKey.ErrorMinimumPassword, { min_length: min_length }));
            }
            if (!values.confirmation) {
              errors.confirmation = t(translationKey.ErrorConfirmPassword);
            } else if (values.password !== values.confirmation) {
              errors.confirmation = t(translationKey.ErrorPasswordMatch);
            }
            return errors;
          } }
          onSubmit={ ({ password, confirmation }, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            const values = queryString.parse(props.location.search);
            const secret = values.s;
            axios.post('/reset_password', { password, confirmation, secret }).then(() => {
              confirmAlert({
                title: t(translationKey.AlertTitleSuccess),
                message: t(translationKey.AlertBodyPasswordReset),
                buttons: [
                  {
                    label: t(translationKey.LabelOk),
                    onClick: () => {
                      props.history.push('/');
                    },
                  },
                ],
              });
            })
              .catch(err => {
                catchAxios(setFieldError)(err);
                setSubmitting(false);
              });
          } }
        >
          { ({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
             }) => (
            <form onSubmit={ handleSubmit }>
              <Error message={ errors['network'] } />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t(translationKey.LabelPassword)}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={ handleChange }
                onBlur={ handleBlur }
                value={ values.password }
                helperText={ errors.password && touched.password && errors.password }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmation"
                label={t(translationKey.LabelConfirmPassword)}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={ handleChange }
                onBlur={ handleBlur }
                value={ values.confirmation }
                helperText={ errors.confirmation && touched.confirmation && errors.confirmation }
                FormHelperTextProps={ { style: { color: helperTextColor } } }
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={ classes.submit }
                disabled={ isSubmitting }
              >
                {t(translationKey.ButtonSubmit)}
              </Button>
            </form>
          ) }
        </Formik>
      </div>
    </Container>
  );
}

export default ResetPassword;
