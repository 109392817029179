import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { CircularProgress } from '@material-ui/core';
import { barColor } from '../../Styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 230,
    width: 600,
    paddingBottom: 30
  },
  centre: {
    margin: 'auto',
  },
}));

const GuardianAnxietySurveyResults = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!props.results) {
    return <div className={ classes.root }>
      <CircularProgress className={ classes.centre } />
    </div>;
  }

  const chartData = props.results.map((item, index) => {
    // Axis label hackery from first item date to avoid keeping lookup tables
    const date = new Date(item.date);
    const dateString = date.toDateString();
    const parts = dateString.split(' ');
    const axisLabel = item.goal_name + ' - ' + parts[2] + ' ' + parts[1];
    return {
        day: index + 1,
        axisLabel,
        finalScore: item.final_score
    }
  });

  const dayValues = chartData.map(item => item.day);
  const labels = chartData.map(item => item.axisLabel);
  let tickValues = [0, 5, 10, 15, 20];

  return (
    <div className={ classes.root }>
      <VictoryChart
        height={ 270 }
        width={ props.size.width }
        domainPadding={ 50 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ dayValues }
                     tickFormat={ labels }
                     fixLabelOverlap={chartData.length > 3}
                     style={ { grid: { stroke: 'none' }, tickLabels: { fontSize: '10px' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={tickValues}
                     label={t(translationKey.LabelGuardianSurveyScasScore)}
                     style={ { axisLabel: { padding: 35 } } }
        />
        <VictoryBar
          tickValues={ dayValues }
          style={ { data: { fill: barColor, width: Math.min(80, props.size.width / (chartData.length + 4)) } } }
          data={ chartData }
          x={t(translationKey.LabelDay)}
          y={t(translationKey.LabelFinalScore)}
        />
      </VictoryChart>
    </div>
  );
};

export default withSize()(GuardianAnxietySurveyResults);
