import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../themes';
import { axios, catchAxios } from '../services/networkRequest';
import { genericAlert, getChain, ROLES } from '../services/helpers';
import { activeColor, listBackgroundColor } from '../Styles';
import 'react-confirm-alert/src/react-confirm-alert.css';
import MaterialTable from 'material-table';
import ChevronRight from '@material-ui/core/SvgIcon/SvgIcon';
import { CloudDownload, Settings } from '@material-ui/icons';
import { translationKey } from '../utilities/localisation/translationKeys';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { helperTextColor } from '../constants';
import TextField from '@material-ui/core/TextField';
import Error from './Error';
import { Formik } from 'formik';
import { Modal } from './Modal';

function MHSDSAggregatedData(props) {
  const [files, setFiles] = useState();
  const [odsCode, setOdsCode] = useState();
  const [showMHSDSSettings, setShowMHSDSSettings] = useState(false);
  const url = props.role === ROLES.admin ? '/mhsds_data' : `organisation/mhsds_data`;
  const { t } = useTranslation();

  function downloadXLSX(filename) {
    axios.getFile(`${ url }/${ filename }`, filename);
  }

  useEffect(() => {
    axios.get(url)
      .then(({ data }) => {
        setFiles(getChain(data, 'files'));
        setOdsCode(getChain(data, 'ods_code'));
      });
  }, [url]);


  return (
    <Container component="main" maxWidth="lg">
      { props.role === ROLES.admin &&
        <Grid
          container
          justify="space-between">

          <Grid item />

          <Button mb={ 10 } align="right" variant="contained" color="primary"
                  onClick={ () => genericAlert(t(translationKey.AlertTitleStartProcessing),
                    t(translationKey.AlertBodyStartProcessingMHSDS),
                    () => {
                      axios.post('/mhsds_data/update');
                    })
                  }>
            { t(translationKey.ButtonGetUpdatedData) }
          </Button>

          <Grid
            item
            onClick={ () => setShowMHSDSSettings(true) } >
            <Settings
              style={ {
                cursor: 'pointer',
              } }

            />
          </Grid>
        </Grid>
      }
      <ThemeProvider theme={ tableThemer(files) }>
        <MaterialTable
          isLoading={ !files }
          columns={ [
            {
              title: t(translationKey.TitleFilename),
              field: 'file',
              cellStyle: {
                borderLeft: `20px solid ${ activeColor }`,
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleLastModified),
              field: 'last_modified',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleFileSize),
              field: 'size',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          data={ files }
          options={ {
            actionsColumnIndex: 3,
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }

          actions={ [
            {
              icon: () => <CloudDownload />,
              onClick: (_, rowData) => downloadXLSX(rowData.file),
            },
          ] }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
        />
      </ThemeProvider>

      {
        showMHSDSSettings &&
        <Modal close={ () => setShowMHSDSSettings(false) }>
          <Container component="main" style={ { padding: '20px', width: '50%' } }>
            <CssBaseline />
            <h2>{ t(translationKey.LabelMHSDSSettings) } </h2>

            <Formik
              initialValues={ { odsCode, } }
              onSubmit={ (values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);
                const submitFunction = () => {
                  axios['put']('/admin/ods_code', values)
                    .then(() => {
                      setShowMHSDSSettings(false);
                    })
                    .catch(err => {
                      catchAxios(setFieldError)(err);
                      setSubmitting(false);
                    });
                };

                submitFunction();
              } }
            >
              { ({
                   errors,
                   values,
                   handleChange,
                   handleSubmit,
                 }) => (
                <form onSubmit={ handleSubmit }>
                  <Error message={ errors['network'] } />

                  <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    autoFocus
                    label={ t(translationKey.LabelOrganisationODSCode) }
                    value={ values.odsCode }
                    name="odsCode"
                    onChange={ handleChange }
                    FormHelperTextProps={ { style: { color: helperTextColor } } }
                  />

                  <Button
                    style={ {
                      marginRight: '1em',
                    } }
                    color="primary"
                    type="submit"
                    variant="contained">{ t(translationKey.ButtonSubmit) }</Button>

                </form>) }
            </Formik>
          </Container>
        </Modal>
      }

    </Container>
  );
}

export default MHSDSAggregatedData;
