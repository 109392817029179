import { createContext } from 'react';

export const Activities = {
  SelfSignUpDetails: 'SelfSignUpDetails',
}

export const defaultActivityState = {
  startActivity: () => {},
  completeActivity: () => {},
  activities: {},
}

const ActivityContext = createContext(defaultActivityState);

export default ActivityContext;